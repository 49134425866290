import Modal from "components/Modals/Modal";
import React, { useMemo } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export interface PortsWarningModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const PortsWarningModal: React.FC<PortsWarningModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      title=""
      body={
        <>
          <div className="w-[450px]">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row items-start mt-2 text-amber-500">
                <div className="ml-2">
                  <div className="flex flex-row items-center">
                    <ExclamationTriangleIcon className="h-5 w-5 mr-1" />
                    <h1 className="text-sm font-bold">
                      Are you sure you want to expose this port to the internet?
                    </h1>
                  </div>
                  <p className="text-sm text-gray-300 mt-2">
                    This will make your service publicly accessible. Only
                    proceed if you:
                  </p>
                  <ul className="text-sm text-gray-300 list-disc pl-5 mt-2 space-y-1">
                    <li>Trust all applications running on this port</li>
                    <li>Have configured proper authentication if needed</li>
                    <li>
                      Understand which service will become publicly available
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      confirmLabel={"I understand, expose port"}
      onSuccess={() => onSuccess()}
    />
  );
};
