import NotebookList from "components/NotebookList";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import Workspace from "entities/Workspace.entity";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import OpenEnvironment from "./SSH";
import { Capability } from "contexts/OrgContext";
import Tunnels from "./Tunnels";
import ShareSettings from "./Share";
import ExposeNewPortsSection from "./ExposedPortsSection";
import { Environment } from "components/Environment/utils/types";
interface AceessSettingsProps {
  environment: Environment;
  workspaceCapabilities: Capability[];
}
const AccessSettings: React.FC<AceessSettingsProps> = (props) => {
  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Using Brev CLI (SSH)
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <OpenEnvironment
          environment={props.environment}
          workspaceCapabilities={props.workspaceCapabilities}
        />
      </div>
      <div className="flex flex-row items-end mt-3">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Using Tunnels
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <Tunnels
          environment={props.environment}
          workspaceCapabilities={props.workspaceCapabilities}
        />
      </div>
      <div className="flex flex-row items-end mt-3">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Using Ports
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <ExposeNewPortsSection
          environment={props.environment}
          capabilities={props.workspaceCapabilities}
        />
      </div>
      <div className="flex flex-row items-end mt-3">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Share SSH Access
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <ShareSettings environment={props.environment} />
      </div>
    </>
  );
};

export default AccessSettings;
