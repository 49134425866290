import React, { useState } from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import { CloudflareTunnels } from "./cloudflare-tunnels";
import { FirewallRules } from "./firewall-rules";
import { LaunchableExposePorts } from "components/Environment/Launchables/Create";

export interface FirewallRule {
  id: string;
  port: string;
}

export interface Tunnel {
  id: string;
  name: string;
  port: number;
}

interface ExposePortsProps {
  exposePorts: LaunchableExposePorts;
  onSave: (exposePorts: LaunchableExposePorts) => void;
}

const ConfigPage: React.FC<ExposePortsProps> = ({ exposePorts, onSave }) => {
  const [firewallRules, setFirewallRules] = useState<FirewallRule[]>(
    exposePorts.firewallRules
  );
  const [tunnels, setTunnels] = useState<Tunnel[]>(exposePorts.tunnels);

  const addFirewallRule = (port: string) => {
    const newRule: FirewallRule = {
      id: Date.now().toString(),
      port,
    };
    setFirewallRules([...firewallRules, newRule]);
    onSave({ tunnels, firewallRules: [...firewallRules, newRule] });
  };

  const removeFirewallRule = (id: string) => {
    setFirewallRules(firewallRules.filter((rule) => rule.id !== id));
    onSave({
      tunnels,
      firewallRules: firewallRules.filter((rule) => rule.id !== id),
    });
  };

  const addTunnel = (name: string, port: number) => {
    const newTunnel: Tunnel = {
      id: Date.now().toString(),
      name,
      port,
    };
    setTunnels([...tunnels, newTunnel]);
    onSave({ tunnels: [...tunnels, newTunnel], firewallRules });
  };

  const removeTunnel = (id: string) => {
    setTunnels(tunnels.filter((tunnel) => tunnel.id !== id));
    onSave({
      tunnels: tunnels.filter((tunnel) => tunnel.id !== id),
      firewallRules,
    });
  };

  return (
    <div className="mx-auto py-4">
      <h1 className="text-3xl font-bold mb-6">Expose Ports</h1>
      <Tabs defaultValue="tunnels">
        <div className="flex flex-row justify-between">
          <TabsList className="grid grid-cols-2 h-10" variant="solid">
            <TabsTrigger value="tunnels">Tunnels</TabsTrigger>
            <TabsTrigger value="firewall">Firewall Rules</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="tunnels">
          <CloudflareTunnels
            tunnels={tunnels}
            onAddTunnel={addTunnel}
            onRemoveTunnel={removeTunnel}
          />
        </TabsContent>
        <TabsContent value="firewall">
          <FirewallRules
            rules={firewallRules}
            onAddRule={addFirewallRule}
            onRemoveRule={removeFirewallRule}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ConfigPage;
