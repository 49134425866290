import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { OrgContext } from "contexts/OrgContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { useHistory } from "react-router-dom";
import Modal from "components/Modals/Modal";
import InputModal from "components/Modals/InputModal";
import NewOrgModal from "components/Modals/NewOrgModal";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { AddOrgModal } from "components/Modals/AddOrgModel";

const people = [
  { name: "Wade Cooper", username: "@wadecooper" },
  { name: "Arlene Mccoy", username: "@arlenemccoy" },
  { name: "Devon Webb", username: "@devonwebb" },
  { name: "Tom Cook", username: "@tomcook" },
  { name: "Tanya Fox", username: "@tanyafox" },
  { name: "Hellen Schmidt", username: "@hellenschmidt" },
  { name: "Caroline Schultz", username: "@carolineschultz" },
  { name: "Mason Heaney", username: "@masonheaney" },
  { name: "Claudie Smitham", username: "@claudiesmitham" },
  { name: "Emil Schaefer", username: "@emilschaefer" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OrgDropdown: React.FC = (props) => {
  // const [open, setOpen] = useState(false);
  const [showNewOrg, setShowNewOrg] = useState(false);
  const orgContext = useContext(OrgContext);
  const workspaceContext = useContext(WorkspaceContext);
  const [fromHover, setFromHover] = useState(false);
  const [enteredMenu, setEnteredMenu] = useState(false);
  const history = useHistory();
  const [selected, setSelected] = useState(people[3]);
  const [showModal, setShowModal] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");
  const oneClickDeployContext = useContext(OneClickDeployContext);

  // const handleCreate = async () => {
  //   setLoading(true);
  //   if (name === "") {
  //     setErrorMessage("Name cannot be left blank. Try again!");
  //     return;
  //   }
  //   let res = await orgContext.createOrg(name);
  //   if (res.success && res.data) {
  //     onCreate();
  //     const orgId = res.data?.id;
  //     history.push(`/org/${orgId}/environments`);
  //     setName("");
  //   } else {
  //     console.error(res.message);
  //     setErrorMessage(
  //       res.message || "Unable to create organization. Try again!"
  //     );
  //   }
  //   setLoading(false);
  // };

  // const handleCancel = () => {
  //   setName("");
  //   setErrorMessage("");
  //   onCancel();
  // };

  const goToAppropriateUrl = (orgID: string) => {
    const path = history.location.pathname;
    const orgPathRegex = /^(.*\/org\/)[^/]+(\/.*)?$/;
    if (orgPathRegex.test(path)) {
      // Replace the org ID while keeping the rest of the path intact
      const newPath = path.replace(orgPathRegex, `$1${orgID}$2`);
      history.push(newPath);
    }
    // Do nothing if the path doesn't match the pattern
  };

  return (
    <>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-primaryBg border border-zinc-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-highlight focus:border-highlight sm:text-sm min-w-[200px]">
              <span className="w-full inline-flex truncate">
                <span className="truncate dark:text-secondary">
                  {orgContext.activeOrg?.name}
                </span>
                <span className="ml-2 truncate text-gray-500 dark:text-slate-500">
                  {orgContext.activeOrg?.id}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-primaryBg border border-zinc-800 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {orgContext.orgs.map((org) => {
                  const isSelected = orgContext.activeOrg?.id === org.id;

                  return (
                    <Listbox.Option
                      key={org.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-highlight"
                            : "text-gray-900 dark:text-secondary",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      onClick={() => {
                        oneClickDeployContext.reset();
                        workspaceContext.clear();
                        orgContext.setActiveOrg(org.id);
                        goToAppropriateUrl(org.id);
                      }}
                      value={org.id}
                    >
                      {({ active }) => (
                        <>
                          <div className="flex whitespace-nowrap">
                            <span
                              className={classNames(
                                isSelected ? "font-semibold" : "font-normal",
                                "whitespace-nowrap"
                              )}
                            >
                              {org.name}
                            </span>
                            <span
                              className={classNames(
                                active
                                  ? "text-white"
                                  : "text-gray-500 dark:text-slate-500",
                                "ml-2 whitespace-nowrap	"
                              )}
                            >
                              {org.id}
                            </span>
                          </div>

                          {isSelected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-highlight",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </>
  );
};

export default OrgDropdown;
