import React, { useState, useContext } from "react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import {
  AdjustmentsHorizontalIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import Button from "components/UI-lib/Button";
import { WorkspaceStatus, WorkspaceStartStatus } from "models/Workspace.model";
import {
  Capability,
  stopStartCapability,
  terminateCapability,
} from "contexts/OrgContext";
import Workspace from "entities/Workspace.entity";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { NotificationContext } from "contexts/NotificationContext";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { useHistory } from "react-router-dom";
import { StopModal } from "./DeprecatedSettings/InstanceSettings/StopModal";
import { FailedStartModal } from "./FailedStartModal";
import Modal from "components/Modals/Modal";
import { Environment } from "../utils/types";
import {
  getEnvironmentStatus,
  getWorkspaceStartFailureMessageFromTasks,
  getWorkspaceStartStatusFromTasks,
} from "../utils/environtmentUtils";

interface EnvironmentActionsProps {
  environment: Environment;
  capabilities: Capability[];
  loadingCapabilities: boolean;
  deleteEnvironment: (environmentId: string) => void;
  loadingDelete: boolean;
}

const EnvironmentActions: React.FC<EnvironmentActionsProps> = ({
  environment,
  capabilities,
  loadingCapabilities,
  deleteEnvironment,
  loadingDelete,
}) => {
  const [stopLoading, setStopLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showStartFailedModal, setShowStartFailedModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const wsContext = useContext(WorkspaceContext);
  const notificationContext = useContext(NotificationContext);
  const oneClickDeployContext = useContext(OneClickDeployContext);
  const history = useHistory();

  const stopWorkspace = async () => {
    setShowPopUp(false);
    setStopLoading(true);
    if (environment) {
      if (
        oneClickDeployContext.environment?.workspaceId ===
        environment.workspaceId
      ) {
        oneClickDeployContext.reset();
      }
      const res = await wsContext.stopWorkspace(environment.workspaceId || "");
      if (!res.success) {
        notificationContext.showNotification(
          "Unable to stop instance! Try again.",
          res.message,
          "error"
        );
      }
      setStopLoading(false);
    }
  };

  const startWorkspace = async () => {
    setStartLoading(true);
    if (environment) {
      const res = await wsContext.startWorkspace(environment.workspaceId || "");
      if (!res.success) {
        notificationContext.showNotification(
          "Unable to start instance! Try again.",
          res.message,
          "error"
        );
      }
      setStartLoading(false);
    }
  };

  const stopButtonDisabledMessage = () => {
    if (
      environment.instance?.labels?.workspaceGroupId &&
      !capabilities.includes(stopStartCapability)
    ) {
      return "This instance does not support stopping and starting";
    } else if (getEnvironmentStatus(environment) == WorkspaceStatus.Stopping) {
      return "workspace is stopping";
    } else {
      return "";
    }
  };

  return (
    <div className="flex flex-col">
      <StopModal
        show={showPopUp}
        setShow={setShowPopUp}
        onCancel={() => setShowPopUp(false)}
        onSuccess={stopWorkspace}
      />
      <FailedStartModal
        logFileContent={getWorkspaceStartFailureMessageFromTasks(
          environment?.tasks || []
        )}
        show={showStartFailedModal}
        setShow={setShowStartFailedModal}
        onCancel={() => setShowStartFailedModal(false)}
        onSuccess={() => {
          setShowStartFailedModal(false);
        }}
      />
      <Modal
        setOpen={setShowConfirmationModal}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSuccess={() => {
          if (environment) {
            deleteEnvironment(environment.workspaceId || "");
            setShowConfirmationModal(false);
          }
        }}
        title={`Are you sure you want to delete ${
          environment ? environment.name : ""
        }?`}
        body={
          <p className="text-sm text-gray-500 dark:text-secondary">
            Deleting an instance can't be undone.
          </p>
        }
        confirmLabel="Yes, Delete"
      />
      <div className="flex flex-row">
        {!loadingCapabilities ? (
          <>
            {getEnvironmentStatus(environment) !== WorkspaceStatus.Failure && (
              <div className="xl:mt-0">
                {getEnvironmentStatus(environment) !==
                  WorkspaceStatus.Stopped && (
                  <span className="block">
                    <Tooltip
                      title={stopButtonDisabledMessage()}
                      placement="top"
                    >
                      <span>
                        <Button
                          label="Stop"
                          type="secondary"
                          loading={stopLoading}
                          onClick={() => setShowPopUp(true)}
                          iconLeft={
                            <MoonIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400 dark:text-secondary"
                              aria-hidden="true"
                            />
                          }
                          disabled={
                            !capabilities.includes(stopStartCapability) ||
                            getEnvironmentStatus(environment) ==
                              WorkspaceStatus.Stopping
                          }
                        />
                      </span>
                    </Tooltip>
                  </span>
                )}
                {getEnvironmentStatus(environment) ==
                  WorkspaceStatus.Stopped && (
                  <span className="hidden sm:block">
                    <Button
                      label="Start"
                      type="primary"
                      loading={
                        startLoading ||
                        getWorkspaceStartStatusFromTasks(
                          environment?.tasks || []
                        ) === WorkspaceStartStatus.Starting
                      }
                      onClick={startWorkspace}
                      iconLeft={
                        <SunIcon
                          className="-ml-1 mr-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      }
                    />
                  </span>
                )}
              </div>
            )}
            {capabilities.includes(terminateCapability) && (
              <span className="ml-3">
                <Button
                  label="Delete"
                  type="danger"
                  onClick={() => setShowConfirmationModal(true)}
                  loading={loadingDelete}
                />
              </span>
            )}
          </>
        ) : (
          <div className="flex flex-row font-mono text-sm">
            <AdjustmentsHorizontalIcon className="h-5 w-6 mr-1" />
            Loading Capabilities...
          </div>
        )}
      </div>
      {getWorkspaceStartStatusFromTasks(environment?.tasks || []) ===
        WorkspaceStartStatus.Failure && (
        <div
          className="flex flex-row mt-2 text-xs text-rose-600 cursor-pointer items-center hover:text-rose-400"
          onClick={() => setShowStartFailedModal(true)}
        >
          <ExclamationTriangleIcon className="h-4 w-4 mr-1" />
          <span>Previous Start Attempt Failed</span>
        </div>
      )}
    </div>
  );
};

export default EnvironmentActions;
