import React, { useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import {
  IApplication,
  VerbBuildStatus,
  WorkspaceStatus,
} from "models/Workspace.model";
import {
  buildDefaultVerbYamlConfig,
  getVerbBuildConfigFromWorkspace,
} from "./utils";
import LogDisplay from "components/Verb/LogDisplay";
import Spinner from "components/UI-lib/Spinner";
import FlatCard from "components/UI-lib/FlatCard";
import { WaitingOnBuildStep } from "../Environment/Settings/DeprecatedSettings/InstanceSettings/WaitingOnBuildStep";
import {
  VERB_FINISHED_PHRASE,
  VERB_FAILED_PHRASE,
} from "components/Environment/Settings/utils";
import InputField from "components/UI-lib/InputField";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import DevToggle from "components/DevToggle";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Deployments from "../Environment/Settings/Tabs/Access/Tunnels";
import JupyterNotebookFetcher from "../Environment/Settings/DeprecatedSettings/InstanceSettings/JupyterNotebookFetcher";
import Workspace from "../../entities/Workspace.entity";
import { Capability } from "contexts/OrgContext";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { WorkspaceCreateContext } from "contexts/WorkspaceCreateContext";
import ShareSettings from "../Environment/Settings/Tabs/Access/Share";
import { UserContext } from "contexts/UserContext";
import {
  classNames,
  isCPU_techdebt,
  isSharedWorkspace,
} from "components/utils";
import { Loader1, Loader2 } from "components/FullScreenLoader";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { Environment } from "components/Environment/utils/types";
import {
  getEnvironmentStatus,
  getVerbBuildStatusFromTasks,
} from "components/Environment/utils/environtmentUtils";

interface IVerbBuildComponentProps {
  workspaceId: string;
  setShowButton: (b: boolean) => void;
  environment: Environment | null;
  initialVerbLoad: boolean;
}
export const VerbBuildComponent: React.FC<IVerbBuildComponentProps> = ({
  workspaceId,
  setShowButton,
  environment,
  initialVerbLoad,
}) => {
  // const [jupyterUrl, setJupyterUrl] = useState("");
  const createContext = useContext(WorkspaceCreateContext);
  const [showAddNotebookForm, setShowAddNotebookForm] = useState(false);
  const [canAddNotebooks, setCanAddNotebooks] = useState(false);

  const verbBuild = getVerbBuildConfigFromWorkspace(
    environment?.build?.verb?.config || ""
  );

  useEffect(() => {
    if (environment) {
      if (
        getEnvironmentStatus(environment) === WorkspaceStatus.Running &&
        getVerbBuildStatusFromTasks(environment.tasks || []) ===
          VerbBuildStatus.Completed
      ) {
        setCanAddNotebooks(true);
      } else {
        setCanAddNotebooks(false);
      }
    }
  }, [environment]);

  useEffect(() => {
    if (createContext.logFileContent.includes(VERB_FINISHED_PHRASE)) {
      createContext.setIsVerbBuilding(false);
      // if (!createContext.logFileContent.includes(SHAKA)) {
      //   createContext.setLogFileContent(createContext.logFileContent + SHAKA);
      // }
    }
  }, [createContext.logFileContent]);

  useEffect(() => {
    if (
      environment &&
      !(
        createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
        getVerbBuildStatusFromTasks(environment?.tasks || []) ===
          VerbBuildStatus.CreateFailed
      ) &&
      (createContext.verbYaml === "" ||
        createContext.isVerbBuilding === true ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Deleting ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Stopping ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Stopped)
    ) {
      createContext.setBuildButtonDisabled(true);
    } else {
      createContext.setBuildButtonDisabled(false);
    }
  }, [createContext.verbYaml, createContext.isVerbBuilding, environment]);

  const determineContentToRender = () => {
    // console.log(`
    // -> hitBuild ${createContext.hitBuildButton}
    // -> createContext.logFileContent==="" ${createContext.logFileContent === ""}
    // -> workspace?.status ${workspace?.status}
    // -> workspace?.verbBuildStatus ${workspace?.verbBuildStatus}
    // `);

    if (
      createContext.hitBuildButton &&
      createContext.logFileContent === "" &&
      environment &&
      getVerbBuildStatusFromTasks(environment?.tasks || []) !==
        VerbBuildStatus.CreateFailed &&
      environment?.instance?.labels?.workspaceGroupId !== "akash-brev-wg"
    ) {
      return (
        <>
          <Spinner type="secondary" />
          <WaitingOnBuildStep
            onComplete={() => {
              setShowButton(true);
            }}
          />
        </>
      );
    } else if (
      environment &&
      (getEnvironmentStatus(environment) === WorkspaceStatus.Starting ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Deploying) &&
      getVerbBuildStatusFromTasks(environment?.tasks || []) ===
        VerbBuildStatus.Pending
    ) {
      return (
        <>
          <FlatCard className="mt-2 mb-2" isLoading={false}>
            <p className="text-sm text-gray-700 dark:text-secondary font-mono">
              Logs will appear here when the container starts building.
              <span className="text-gray-700 dark:text-slate-300">
                {" Please wait until the instance is in Running state."}
              </span>
            </p>
          </FlatCard>
        </>
      );
    } else if (
      environment &&
      getEnvironmentStatus(environment) === WorkspaceStatus.Running &&
      (getVerbBuildStatusFromTasks(environment?.tasks || []) ===
        VerbBuildStatus.Building ||
        getVerbBuildStatusFromTasks(environment?.tasks || []) ===
          VerbBuildStatus.Completed ||
        getVerbBuildStatusFromTasks(environment?.tasks || []) ===
          VerbBuildStatus.Pending ||
        getVerbBuildStatusFromTasks(environment?.tasks || []) ===
          VerbBuildStatus.CreateFailed) &&
      createContext.logFileContent !== ""
    ) {
      return (
        <>
          <LogDisplay
            environment={environment}
            logFileContent={createContext.logFileContent}
            retroMode={false}
          />
        </>
      );
    }
  };

  if (
    environment &&
    getEnvironmentStatus(environment) !== WorkspaceStatus.Running
  ) {
    return (
      <div className="flex w-full mt-5">
        <FlatCard isLoading={false}>
          <p className="text-sm text-gray-700 dark:text-secondary font-mono text-center">
            Logs will appear here when the instance is running.
          </p>
        </FlatCard>
      </div>
    );
  }

  const getContainerBuildMessage = () => {
    if (environment?.build?.vm) {
      return "Building VM Mode";
    }

    if (
      !!verbBuild?.base_image ||
      environment?.build?.container?.base_image ||
      environment?.build?.docker_compose
    ) {
      const containerType =
        verbBuild?.base_image ??
        environment?.build?.container?.base_image ??
        (environment?.build?.docker_compose ? "Docker Compose" : "") ??
        "Unknown Container";

      return `Building ${containerType}`;
    }

    return "Python and CUDA will be installed once the container is ready";
  };

  return (
    <div>
      <div className="dark:text-secondary">
        <div className="flex flex-col">
          {environment &&
            ((getEnvironmentStatus(environment) === WorkspaceStatus.Running &&
              getVerbBuildStatusFromTasks(environment?.tasks || []) ===
                VerbBuildStatus.Building) ||
              (environment?.instance?.labels?.workspaceGroupId !==
                "akash-brev-wg" &&
                getEnvironmentStatus(environment) !==
                  WorkspaceStatus.Starting &&
                getEnvironmentStatus(environment) !==
                  WorkspaceStatus.Deploying &&
                getEnvironmentStatus(environment) !== WorkspaceStatus.Stopped &&
                getEnvironmentStatus(environment) !==
                  WorkspaceStatus.Stopping &&
                getVerbBuildStatusFromTasks(environment?.tasks || []) !==
                  VerbBuildStatus.Completed &&
                !createContext.logFileContent.includes(VERB_FINISHED_PHRASE) &&
                !createContext.logFileContent.includes(VERB_FAILED_PHRASE) &&
                getVerbBuildStatusFromTasks(environment?.tasks || []) !==
                  VerbBuildStatus.CreateFailed)) && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono text-center">
                  {getContainerBuildMessage()}
                </p>
              </FlatCard>
            )}

          {environment &&
            environment.instance?.labels?.workspaceGroupId ===
              "akash-brev-wg" &&
            getVerbBuildStatusFromTasks(environment?.tasks || []) ===
              VerbBuildStatus.Completed &&
            getEnvironmentStatus(environment) === WorkspaceStatus.Running && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono">
                  Container running on instance.
                </p>
              </FlatCard>
            )}

          {environment &&
            (createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
              getVerbBuildStatusFromTasks(environment?.tasks || []) ===
                VerbBuildStatus.CreateFailed) &&
            getEnvironmentStatus(environment) === WorkspaceStatus.Running && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono text-center">
                  The container failed to build. Please reach out to support or{" "}
                  {environment.build?.container?.base_image !== ""
                    ? "try setting up the container manually in VM mode"
                    : "rebuild it"}
                </p>
              </FlatCard>
            )}

          {environment &&
            (getEnvironmentStatus(environment) === WorkspaceStatus.Running ||
              getEnvironmentStatus(environment) === WorkspaceStatus.Starting ||
              getEnvironmentStatus(environment) ===
                WorkspaceStatus.Deploying) &&
            initialVerbLoad &&
            createContext.logFileContent === "" &&
            !createContext.hitBuildButton && (
              <FlatCard className="mt-3" isLoading={true}>
                <p className="text-sm text-gray-700 dark:text-secondary font-mono text-center">
                  Checking for Container Logs...
                </p>
              </FlatCard>
            )}
          {environment &&
          (getEnvironmentStatus(environment) === WorkspaceStatus.Deploying ||
            getEnvironmentStatus(environment) === WorkspaceStatus.Starting) &&
          getVerbBuildStatusFromTasks(environment?.tasks || []) !==
            VerbBuildStatus.Completed &&
          getVerbBuildStatusFromTasks(environment?.tasks || []) !==
            VerbBuildStatus.CreateFailed ? (
            <FlatCard className="mt-3" isLoading={false}>
              <p className="text-sm text-amber-700 dark:text-amber-500 font-mono">
                Waiting for your instance to be running before{" "}
                {!!verbBuild?.base_image
                  ? `building ${verbBuild.base_image}`
                  : "installing Python and CUDA"}
                <span>
                  <Loader1 />
                </span>
              </p>
            </FlatCard>
          ) : (
            <>
              <div className="mt-5">{determineContentToRender()}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
