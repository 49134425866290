/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts/UserContext";
import Workspace from "../../../../../../entities/Workspace.entity";
import agent from "server";
import CopyField from "components/UI-lib/CopyField";
import FlatCard from "components/UI-lib/FlatCard";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { useAuth0 } from "@auth0/auth0-react";
import TunnelSection from "./TunnelSection";
import { Capability } from "contexts/OrgContext";
import AddBadgeToReadme from "../SSH/AddBadgeToReadme";
import {
  LinuxCliInstallInstructions,
  MacCliInstallInstructions,
  WindowsCliInstallInstructions,
} from "../SSH/CLIInstallationInstructions";
import { isKasAuthFlow } from "server/kas/utils";
import { Environment } from "components/Environment/utils/types";

const getOS = (): string => {
  if (navigator.userAgent.indexOf("Win") != -1) return "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) return "darwin";
  if (navigator.userAgent.indexOf("Linux") != -1) return "linux";
  if (navigator.userAgent.indexOf("Android") != -1) return "android";
  if (navigator.userAgent.indexOf("like Mac") != -1) return "ios";
  return "unknown";
};
interface DeploymentsProps {
  environment: Environment;
  workspaceCapabilities: Capability[];
}
const enableTokenLogin = true;

const Tunnels: React.FC<DeploymentsProps> = (props) => {
  const userContext = useContext(UserContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState(
    "You'll need to install the Brev CLI to open your instance"
  );
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("warning");
  const [cmdCopied, setCmdCopied] = useState(false);
  const [cmd, setCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login"
  );
  const [visibleCmd, setVisibleCmd] = useState(
    "brew install brevdev/homebrew-brev/brev && brev login --token *****"
  );
  const [revealToken, setRevealToken] = useState(false);
  const { getAccessTokenSilently } = useAuth0?.();
  const os = getOS();
  const toggleTokenVisibility = () => {
    setRevealToken(!revealToken);
  };
  useEffect(() => {
    if (!isKasAuthFlow) {
      getAccessTokenSilently().then((t) => {
        // KAS auth flow won't support token login atm
        if (enableTokenLogin && !isKasAuthFlow) {
          setCmd(
            `brew install brevdev/homebrew-brev/brev && brev login --token ${t}`
          );
        } else {
          setCmd(`brew install brevdev/homebrew-brev/brev && brev login`);
        }
      });
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (revealToken) {
      setVisibleCmd(cmd);
    } else {
      setVisibleCmd(
        "brew install brevdev/homebrew-brev/brev && brev login --token *****"
      );
    }
  }, [revealToken]);

  return (
    <div className="flex flex-col justify-start items-start">
      <TunnelSection
        environment={props.environment}
        capabilities={props.workspaceCapabilities}
      />
    </div>
  );
};

export default Tunnels;
