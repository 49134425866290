"use client";

import React, { useState } from "react";
import { Button } from "components/Graphs/Button";
import { Input } from "components/Graphs/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { Trash2 } from "lucide-react";
import { FirewallRule } from ".";

interface FirewallRulesProps {
  rules: FirewallRule[];
  onAddRule: (port: string) => void;
  onRemoveRule: (id: string) => void;
}

export const FirewallRules: React.FC<FirewallRulesProps> = ({
  rules,
  onAddRule,
  onRemoveRule,
}) => {
  const [newPort, setNewPort] = useState("");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const isPortInRange = (port: number, rangeStr: string): boolean => {
    const [start, end] = rangeStr.split("-").map(Number);
    return port >= start && port <= end;
  };

  const validatePortRange = (start: number, end: number): boolean => {
    return (
      !isNaN(start) &&
      !isNaN(end) &&
      start >= 1 &&
      start <= 65535 &&
      end >= 1 &&
      end <= 65535 &&
      start < end
    );
  };

  const isPortConflicting = (newPortValue: string): boolean => {
    // If new port is a range
    if (newPortValue.includes("-")) {
      const [newStart, newEnd] = newPortValue.split("-").map(Number);

      return rules.some((rule) => {
        // Check against existing single ports
        if (!rule.port.includes("-")) {
          const existingPort = Number(rule.port);
          return isPortInRange(existingPort, newPortValue);
        }
        // Check against existing ranges
        const [existingStart, existingEnd] = rule.port.split("-").map(Number);
        return newStart <= existingEnd && existingStart <= newEnd;
      });
    }

    // If new port is a single port
    const newPortNum = Number(newPortValue);
    return rules.some((rule) => {
      if (rule.port.includes("-")) {
        return isPortInRange(newPortNum, rule.port);
      }
      return rule.port === newPortValue;
    });
  };

  const handleAddRule = () => {
    setErrorMessages([]); // Clear previous errors

    if (!newPort) {
      setErrorMessages(["Please provide a port number or range"]);
      return;
    }

    // Handle port range
    if (newPort.includes("-")) {
      const [start, end] = newPort.split("-").map(Number);

      if (!validatePortRange(start, end)) {
        setErrorMessages([
          "Invalid port range. Must be between 1-65535 and start must be less than end",
        ]);
        return;
      }

      if (isPortConflicting(newPort)) {
        setErrorMessages(["Port range conflicts with existing rules"]);
        return;
      }
    } else {
      // Handle single port
      const port = Number(newPort);
      if (isNaN(port) || port < 1 || port > 65535) {
        setErrorMessages(["Port must be a number between 1 and 65535"]);
        return;
      }

      if (isPortConflicting(newPort)) {
        setErrorMessages([`Port ${newPort} is already exposed`]);
        return;
      }
    }

    onAddRule(newPort.trim());
    setNewPort("");
  };

  return (
    <div className="mt-3">
      <div className="grid gap-4 md:grid-cols-2">
        <div className="space-y-2">
          <h2 className="text-sm font-medium">Port Number</h2>
          <Input
            id="port-number"
            placeholder="e.g., 80 or 1000-2000"
            type="text"
            value={newPort}
            onChange={(e) => setNewPort(e.target.value)}
          />
        </div>
        <div className="flex items-end">
          <div>
            <Button onClick={handleAddRule} className="w-full">
              Add Rule
            </Button>
          </div>
        </div>
      </div>
      {errorMessages.length > 0 && (
        <div className="flex flex-col gap-2">
          {errorMessages.map((error, index) => (
            <div key={index} className="mt-2 text-red-500 text-xs font-mono">
              {error}
            </div>
          ))}
        </div>
      )}

      <div className="border border-zinc-800 rounded-md mt-4">
        <TableRoot>
          <Table>
            <TableRow>
              <TableHeaderCell>Port(s)</TableHeaderCell>
              <TableHeaderCell className="w-[100px]">Actions</TableHeaderCell>
            </TableRow>
            <TableBody>
              {rules.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    className="text-center text-muted-foreground py-6"
                  >
                    No firewall rules configured. Add your first rule above.
                  </TableCell>
                </TableRow>
              ) : (
                rules.map((rule) => (
                  <TableRow key={rule.id}>
                    <TableCell>{rule.port}</TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        onClick={() => onRemoveRule(rule.id)}
                        aria-label={`Remove port ${rule.port} rule`}
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableRoot>
      </div>
    </div>
  );
};
