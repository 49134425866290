import Chip from "components/Environment/Create/chips";
import { classNames } from "components/utils";
import { DarkModeContext } from "contexts/DarkModeContext";
import React, { useContext, useEffect, useState } from "react";
import DatabaseIcon from "assets/img/svg/databaseicon.svg?react";
import CPUIcon from "assets/img/svg/cpuicon.svg?react";
import LogDisplay from "components/Verb/LogDisplay";
import {
  DeployState,
  OneClickDeployContext,
} from "contexts/OneClickDeployContext";
import Workspace from "entities/Workspace.entity";
import FlatCard from "components/UI-lib/FlatCard";
import { OrgContext } from "contexts/OrgContext";
import { VERB_FINISHED_PHRASE } from "components/Environment/Settings/utils";
import agent from "server";
import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import { usePollingEffect } from "components/hooks";
import { Environment } from "components/Environment/utils/types";
import {
  getEnvironmentStatus,
  getGpuCountFromEnvironment,
  getGpuManufacturerFromEnvironment,
  getGpuNameFromEnvironment,
  getMemoryFromEnvironment,
  getVcpuFromEnvironment,
  getVerbBuildStatusFromTasks,
} from "components/Environment/utils/environtmentUtils";

interface InfoBoxProps {
  index: number;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  activeStep: DeployState;
  environment?: Environment;
  hideDeployingPreview?: boolean;
}

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const { darkMode } = useContext(DarkModeContext);

  if (props.index > 1) {
    return <></>;
  }

  if (props.index === 0 && props.hideDeployingPreview) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        "absolute z-30 min-w-[330px] max-w-[42vh] min-h-[200px] p-2 bg-secondaryBg shadow-xl rounded-md text-sm text-secondary top-full left-1/2 -translate-x-1/2 mt-2 border border-zinc-800",
        darkMode ? "dark-background-texture" : "light-background-texture",
        props.activeStep === DeployState.Dependencies ? "ml-20" : "",
        props.index === -1 ? "" : ""
      )}
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={props.onMouseEnter}
    >
      <div>
        {props.index === 0 && (
          <InfoBox1_DeployProgress
            environment={props.environment}
            activeStep={props.activeStep}
          />
        )}
        {props.index === 1 && (
          <InfoBox2_DeployProgress
            activeStep={props.activeStep}
            environment={props.environment}
          />
        )}
      </div>
    </div>
  );
};

export default InfoBox;

interface InfoBox1Props {
  activeStep: DeployState;
  environment?: Environment;
}

const InfoBox1_DeployProgress: React.FC<InfoBox1Props> = ({
  activeStep,
  environment,
}) => {
  const [chip, setChip] = useState("");
  const [manufacturer, setManufacturer] = useState("NVIDIA");
  const [gpuQty, setGpuQty] = useState(0);
  const [cpuQty, setCpuQty] = useState(0);
  const [storage, setStorage] = useState("");
  const [elasticMemory, setElasticMemory] = useState(false);

  useEffect(() => {
    if (environment) {
      if (environment.instance_type) {
        setChip(getGpuNameFromEnvironment(environment) || "");
        setManufacturer(getGpuManufacturerFromEnvironment(environment) || "");
        setCpuQty(getVcpuFromEnvironment(environment) || 0);
        setElasticMemory(
          environment.instance_type?.elastic_root_volume || false
        );
        setStorage(
          environment?.instance?.disk_size ||
            String(getMemoryFromEnvironment(environment)) ||
            ""
        );
        setGpuQty(getGpuCountFromEnvironment(environment) || 0);
      }
    }
  }, [environment]);

  return (
    <>
      <h3 className="text-center text-lg font-semibold text-gray-800 dark:text-secondary">
        {activeStep === DeployState.Deploying ? "Deploying" : "Deployed"} GPU
      </h3>
      <div className="w-full h-full flex flex-row justify-center items-center">
        {/* <div className="w-full h-full flex flex-col justify-center items-center"> */}

        {chip && manufacturer ? (
          <Chip
            key={"1"}
            selected={true}
            chipName={chip}
            manufacturerName={manufacturer}
            onClick={() => {}}
            className={classNames("h-[150px] mr-2 flex-shrink-0")}
          />
        ) : (
          <>
            <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono text-center mt-20">
              instance is provisioning
            </p>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-evenly items-center text-sm text-gray-500 ">
        <div className="mt-2 flex items-center text-xs text-primary">
          <CPUIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 dark:text-secondary"
            aria-hidden="true"
          />
          <div className="flex flex-col">{`${gpuQty} GPUs x ${cpuQty} CPUs`}</div>
        </div>
        <div className="mt-2 flex items-center text-xs text-primary">
          <DatabaseIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 dark:text-secondary"
            aria-hidden="true"
          />
          {elasticMemory ? `${storage}B` : storage}
        </div>
      </div>
    </>
  );
};

interface InfoBox2Props {
  activeStep: DeployState;
  environment?: Environment;
}

export const InfoBox2_DeployProgress: React.FC<InfoBox2Props> = ({
  activeStep,
  environment,
}) => {
  const [logFileContent, setLogFileContent] = useState("");

  const ReadLogFileFromMachine = async (workspaceID: string) => {
    const res = await agent.Workspaces.readFileFromWorkspace(workspaceID, {
      filePath: "$HOME/.verb-setup.log",
    });
    if (res.success && res.data && res.data.content !== "\n") {
      if (!logFileContent.includes(res.data.content)) {
        setLogFileContent(logFileContent + res.data.content);
      }
    } else {
      console.log(res);
    }
    return res;
  };

  usePollingEffect(
    async () => {
      if (
        !environment ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Stopped ||
        getEnvironmentStatus(environment) === WorkspaceStatus.Stopping
      ) {
        return;
      }

      if (
        getEnvironmentStatus(environment) === WorkspaceStatus.Running &&
        (getVerbBuildStatusFromTasks(environment.tasks || []) ===
          VerbBuildStatus.Building ||
          getVerbBuildStatusFromTasks(environment.tasks || []) ===
            VerbBuildStatus.Pending ||
          getVerbBuildStatusFromTasks(environment.tasks || []) ===
            VerbBuildStatus.Completed)
      ) {
        await ReadLogFileFromMachine(environment.workspaceId || "");
      }
    },
    [environment],
    { executeImmediately: true, interval: 1000 }
  );

  if (activeStep === DeployState.Deploying) {
    return (
      <>
        <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono text-center mt-20">
          Logs will appear here once your instance is deployed
        </p>
      </>
    );
  } else {
    return (
      <>
        {logFileContent === "" && (
          <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono text-center my-2">
            Waiting for logs
          </p>
        )}
        {logFileContent !== "" &&
          !logFileContent.includes(VERB_FINISHED_PHRASE) && (
            <p className="text-sm text-gray-700 dark:text-cyan-100 font-mono text-center my-2">
              Install may take several minutes
            </p>
          )}
        {environment && (
          <LogDisplay
            environment={environment}
            logFileContent={logFileContent}
            retroMode={false}
            initialIsMinimized={true}
          />
        )}
      </>
    );
  }
};
