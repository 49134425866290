import { AppContext } from "contexts/AppContext";
import { UserContext } from "contexts/UserContext";
import React, { useCallback, useContext } from "react";
import "./styles.scss";
import "./smallerloaders.scss";
import { classNames } from "components/utils";
import { Spinner } from "@kui-react/spinner";
import { isPublicRoute } from "SecuredRoute/isPublicRoute";

interface Props {
  children: React.ReactNode;
}
const FullScreenLoader: React.FC<Props> = ({ children }) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { isLoading } = appContext;

  // Banana: undo this
  if (isPublicRoute(window.location.pathname)) {
    return <>{children}</>;
  }
  if (isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }
  return <>{children}</>;
};

export default FullScreenLoader;

// export const JellyfishLoader: React.FC = () => {
//   console.log("wtf");
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "flex-end",
//         flexDirection: "row",
//       }}
//     >
//       {/* <div className="logo-loader-container">
//     <LightModeLogo className={"logo-loader"} />
//     <Spark className={"spark-loader"} />
//   </div> */}
//       <div className="whirlpool">
//         <div className="brev-ring brev-ring1" />
//         <div className="brev-ring brev-ring2" />
//         <div className="brev-ring brev-ring3" />
//         <div className="brev-ring brev-ring4" />
//         <div className="brev-ring brev-ring5" />
//         <div className="brev-ring brev-ring6" />
//         <div className="brev-ring brev-ring7" />
//         <div className="brev-ring brev-ring8" />
//         <div className="brev-ring brev-ring9" />
//       </div>
//     </div>
//   );
// };

interface LoaderProps {
  className?: string;
}
export const Loader1: React.FC<LoaderProps> = (props) => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className={classNames(props.className ? props.className : "mt-[24%]")}>
      <div id="loader-1">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader2: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-2">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader3: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-3">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader4: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-4">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader5: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-5">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader6: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-6">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const Loader7: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div id="loader-7">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
);
export const WaveLoader: React.FC = () => (
  <div className="alt-simple-loaders flex justify-center items-end">
    <div className="mt-[24%]">
      <div className="wave-loader">
        <div className="wave-loading" />
      </div>
    </div>
  </div>
);
