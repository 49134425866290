import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { Button } from "components/Graphs/Button";
import React, { useContext, useEffect, useState } from "react";
import { api, BaseRes, AxiosRes } from "../../server/config";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { isKasAuthFlow } from "server/kas/utils";
import Modal from "components/Modals/Modal";
import { UserContext } from "contexts/UserContext";
import { useHistory, useLocation } from "react-router";
import { OrgContext } from "contexts/OrgContext";

interface MigrateBannerProps {
  // Add your props here
}

export const MigrateBanner: React.FC<MigrateBannerProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const migrateFlag = useFeatureFlagEnabled("migrate-user-brev-nvidia");
  const [showMigrateModal, setShowMigrateModal] = useState(false);
  const userContext = useContext(UserContext);
  const orgContext = useContext(OrgContext);

  const handleMigrationClick = async () => {
    try {
      setIsLoading(true);
      const response = (await api.post(
        "/api/users/initiate-migration"
      )) as AxiosRes & BaseRes;

      // Based on your axios interceptor, response will have this structure:
      // { success: boolean, data?: any, message?: string }
      if (!response.success) {
        throw new Error(response.message || "Migration initiation failed");
      }

      // If successful, the migration token will be in response.data
      const migrationToken = (response as any).data.migrationToken;
      if (!migrationToken) {
        throw new Error("No migration token received");
      }

      window.open(
        `https://login.brev.nvidia.com/signin?migrationToken=${migrationToken}&activeOrgId=${orgContext.activeOrgId}`,
        "_blank"
      );
    } catch (error) {
      console.error("Migration error:", error);
      // You might want to add error handling UI here
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      userContext.me &&
      userContext.me?.externalIdentities &&
      userContext.me?.externalIdentities?.length >= 2 &&
      !location.pathname.includes("/launchable/deploy") &&
      !location.pathname.includes("/launchable-migrate-notice") &&
      !location.pathname.includes("/launchable/deploy/now") &&
      !isKasAuthFlow
    ) {
      history.push(
        `/launchable-migrate-notice?${new URLSearchParams({
          redirect: location.pathname,
        })}`
      );
    }
  }, [userContext.me, location]);

  if (!migrateFlag || isKasAuthFlow) {
    return null;
  }

  return (
    <div className="w-full max-w-7xl mx-auto sm:px-6 lg:px-8 py-3">
      <div className="px-4 sm:px-6 lg:px-8">
        <MigrateModal
          show={showMigrateModal}
          setShow={setShowMigrateModal}
          onCancel={() => setShowMigrateModal(false)}
          onSuccess={() => {
            handleMigrationClick();
            setShowMigrateModal(false);
          }}
        />
        <div className="flex flex-row items-center justify-start border border-amber-700 rounded-lg p-2">
          <div className="flex flex-row items-center gap-1">
            <ExclamationTriangleIcon className="w-4 h-4 text-amber-700 dark:text-amber-500" />
            <p className="text-xs font-medium text-amber-700 dark:text-amber-500">
              <span className="font-bold">console.brev.dev</span> will be
              sunsetting. We are migrating to a new system under{" "}
              <span className="font-bold">brev.nvidia.com</span>.
            </p>
          </div>
          <Button
            className="h-6 ml-2"
            onClick={() => setShowMigrateModal(true)}
            disabled={isLoading}
          >
            <span className="text-xs">
              {isLoading ? "Preparing migration..." : "Click here to migrate"}
            </span>
            <ArrowUpRightIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MigrateBanner;

export interface MigrateModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const MigrateModal: React.FC<MigrateModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      title={"Confirm Migration"}
      body={
        <>
          <div className="w-[450px] ">
            <div className="flex flex-col">
              <div className="flex flex-row items-start justify-start mt-2">
                <p className="text-sm dark:text-white">
                  This migration will take you to a NVIDIA login page where you
                  can create or associate your NVIDIA account. Once complete,
                  you will be able to login on <strong>brev.nvidia.com</strong>.
                </p>
              </div>
              <div className="flex flex-row items-start justify-center mt-4">
                <p className="text-sm dark:text-white">
                  If you have any questions, please contact support at{" "}
                  <a
                    href="mailto:brev-support@nvidia.com"
                    className="text-highlight"
                  >
                    brev-support@nvidia.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      }
      cancelLabel="Cancel"
      confirmLabel={"Start Migration"}
      onSuccess={() => onSuccess()}
    />
  );
};
