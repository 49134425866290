import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UnauthenticatedDash from "components/DashboardContainer/UnauthenticatedDash";
import { FlatCard } from "components/UI-lib";
import { Spinner } from "@kui-react/spinner";
import { Button } from "components/Graphs/Button";
import { createCustomFetchPost } from "server/config";
import { isKasAuthFlow, KAS_TOKEN_STORAGE_KEY } from "server/kas/utils";

// Callback handler after Auth0 authentication
const MergeCallback: React.FC = () => {
  const [status, setStatus] = useState("processing");
  const [errorMessage, setErrorMessage] = useState("");
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();

  useEffect(() => {
    const processAuth0Callback = async () => {
      try {
        // Only proceed if Auth0 authenticated the user
        if (!isAuthenticated || !user) {
          setStatus("error");
          return;
        }

        const tokenClaims = await getIdTokenClaims();
        const auth0IdToken = tokenClaims.__raw;

        const kasToken = isKasAuthFlow
          ? localStorage.getItem(KAS_TOKEN_STORAGE_KEY) || ""
          : "";

        const mergeUserRequest = createCustomFetchPost(kasToken);
        const mergeUserResponse = await mergeUserRequest(
          "/api/users/merge-user",
          {
            token: auth0IdToken,
          }
        );

        if (mergeUserResponse.success) {
          setStatus("success");
        } else {
          setErrorMessage(mergeUserResponse.message);
          if (
            mergeUserResponse.status === 404 ||
            (mergeUserResponse.message &&
              mergeUserResponse.message?.includes("user not found"))
          ) {
            setStatus("user-not-found");
          } else {
            setStatus("error");
          }
        }
      } catch (error) {
        console.log("Migration error:", error);
        setStatus("error");
      }
      console.log("Call API here");
    };

    if (isAuthenticated) {
      processAuth0Callback();
    }
  }, [isAuthenticated, user, getIdTokenClaims]);

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-3">
      <div className="px-4 sm:px-6 lg:px-8 text-white">
        <div className="flex flex-col items-center justify-center h-screen">
          {status === "processing" && (
            <FlatCard>
              <div className="flex flex-col items-center gap-4">
                <span className="text-2xl font-bold">Merging Accounts</span>
                <div>
                  Merging your account from
                  <span className="text-cyan-500 font-bold">
                    {" "}
                    console.brev.dev{" "}
                  </span>{" "}
                  to{" "}
                  <span className="text-highlight font-bold">
                    brev.nvidia.com{" "}
                  </span>
                </div>
              </div>
              <div className="mt-8">
                <Spinner />
              </div>
            </FlatCard>
          )}
          {status === "success" && (
            <FlatCard>
              <div className="flex flex-col items-center gap-4">
                <span className="text-2xl font-bold text-highlight">
                  Account Migration Successful!
                </span>
                <div>
                  <p>
                    Your account has been successfully migrated to our new
                    system.
                  </p>
                </div>
                <div className="">
                  <Button onClick={() => (window.location.href = "/")}>
                    Go to Console on brev.nvidia.com
                  </Button>
                </div>
              </div>
            </FlatCard>
          )}
          {status === "user-not-found" && (
            <FlatCard>
              <div className="flex flex-col items-center gap-4">
                <span className="text-2xl font-bold text-red-500">
                  User Not Found
                </span>
                <p>
                  We couldn't find your account in our old system. Please try
                  again or contact support at{" "}
                  <a
                    href="mailto:support@brev.nvidia.com"
                    className="text-highlight"
                  >
                    brev-support@nvidia.com
                  </a>
                </p>
                <div className="">
                  <Button onClick={() => (window.location.href = "/")}>
                    Go back to Console
                  </Button>
                </div>
                <div className="mt-8">
                  <FlatCard>{errorMessage}</FlatCard>
                </div>
              </div>
            </FlatCard>
          )}
          {status === "error" && (
            <FlatCard>
              <div className="flex flex-col items-center gap-4">
                <span className="text-2xl font-bold text-red-500">
                  Migration Error
                </span>
                <p>
                  There was a problem migrating your account. Please try again
                  or contact support at{" "}
                  <a
                    href="mailto:support@brev.nvidia.com"
                    className="text-highlight"
                  >
                    brev-support@nvidia.com
                  </a>
                </p>
                <div className="">
                  <Button onClick={() => (window.location.href = "/")}>
                    Go back to Console
                  </Button>
                </div>
                <div className="mt-8">
                  <FlatCard>{errorMessage}</FlatCard>
                </div>
              </div>
            </FlatCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default MergeCallback;
