import { string } from "io-ts";
import { useMemo } from "react";
import { prependZeroToCost } from "utils/CreateWorkspaceUtils";
import { GPUInstanceType } from "../Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import {
  ITunnel,
  VerbBuildStatus,
  WorkspaceStatus,
} from "models/Workspace.model";
import { Environment } from "./types";
import {
  getEnvironmentStatus,
  getVerbBuildStatusFromTasks,
} from "./environtmentUtils";

export const getNotebookNameFromUrl = (url: string) => {
  const lastPart = url.split("/").pop() || "";
  const extractedName = lastPart.replace(".ipynb", "");
  return extractedName;
};

export const formatMemory = (
  memory: string | number,
  playformType: string
): string | number => {
  if (playformType === "akash") {
    const index = (memory as string).indexOf("GiB");
    if (index !== -1) {
      return (memory as string).slice(0, index + 3);
    }
  }
  return memory;
};

export function useNotebookDetailsFromWorkspace(workspace, notebookList) {
  const notebookDetails = useMemo(() => {
    if (
      !workspace ||
      !workspace.fileObjects ||
      Object.entries(workspace.fileObjects).length === 0 ||
      !notebookList
    ) {
      return { name: "", path: "" };
    }
    const url =
      workspace.fileObjects[Object.keys(workspace.fileObjects)[0]].url;
    const currentName = getNotebookNameFromUrl(url);
    const found = notebookList.find((n) => {
      const extractedName = getNotebookNameFromUrl(n.file);
      return extractedName === currentName;
    });

    if (found) {
      return {
        name: currentName,
        path: found.name.replace(".html", ""),
        found: true,
      };
    }

    return { name: currentName, path: url, found: false };
  }, [workspace?.fileObjects, notebookList]);

  return notebookDetails;
}

export function isGPUfromInstanceType(
  instanceType: string,
  allInstancesAvailable: any[]
) {
  return !!allInstancesAvailable.find(
    (instance) => instance.type === instanceType
  )?.supported_gpus;
}

interface TunnelApplication {
  cloudflareApplicationID: string;
  cloudflareDnsRecordID: string;
  healthCheckID: string;
  hostname: string;
  name: string;
}

interface HealthCheck {
  health_check_id: string;
  status: string;
}

export function getTunnelApplicationHealthCheck(
  healthChecks: HealthCheck[],
  application: TunnelApplication
) {
  return (
    healthChecks.find(
      (healthCheck) => healthCheck.health_check_id === application.healthCheckID
    )?.status || "unknown"
  );
}

export function getStorageCostNumber(input: string): string {
  let result = "";
  for (let char of input) {
    if (isNaN(char as any) || char === " ") {
      break;
    }
    result += char;
  }
  return result;
}

export function getInstancePriceRate(
  runningCost: number,
  storageAmount: number,
  storageCost: number,
  isRunning: boolean
): string {
  if (!isRunning) {
    return (storageAmount * storageCost).toFixed(2);
  }
  return (runningCost + storageAmount * storageCost).toFixed(2);
}

export function parsePortMappingEntry(
  portsEncoded: string
): Record<string, string> | null {
  if (!portsEncoded) {
    return null;
  }
  const portMappings: Record<string, string> = {};
  const pairs = portsEncoded.split("|"); // Split by encoded '|'

  pairs.forEach((pair) => {
    const [name, port] = pair.split(":"); // Split by encoded ':'
    portMappings[name] = port;
  });

  return portMappings;
}

export function displayPlatformType(platformType: string) {
  if (platformType === "GCPALPHA") {
    return "NVIDIA DGX Cloud";
  }
  return platformType;
}

export const dummyInstanceObject: GPUInstanceType = {
  type: "a2-ultragpu-4g:nvidia-a100-80gb:4",
  default_price: "29.771904",
  prices: [],
  network_performance: "10 Gbps",
  available_azs: [],
  default_cores: 48,
  maximum_network_interfaces: 8,
  supported_gpus: [
    {
      count: 4,
      memory: "80GiB",
      manufacturer: "NVIDIA",
      name: "A100",
    },
  ],
  supported_storage: [
    {
      size: "0B",
      type: "hyperdisk-extreme",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.0002109589041095890411",
      },
    },
    {
      size: "0B",
      type: "pd-balanced",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.0001506849315068493151",
      },
    },
    {
      size: "0B",
      type: "pd-extreme",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.0001890410958904109589",
      },
    },
    {
      size: "0B",
      type: "pd-local",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.0001205479452054794521",
      },
    },
    {
      size: "0B",
      type: "pd-snapshot",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.00008356164383561643836",
      },
    },
    {
      size: "0B",
      type: "pd-ssd",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.0002561643835616438356",
      },
    },
    {
      size: "0B",
      type: "pd-standard",
      min_size: "10GiB",
      max_size: "16TiB",
      price_per_gb_hr: {
        currency: "USD",
        amount: "0.00006027397260273972603",
      },
    },
  ],
  memory: "680GiB",
  vcpu: 48,
  supported_architectures: ["x86_64"],
  clock_speed_in_ghz: "0.000000",
  sub_location: "asia-southeast1-c",
  elastic_root_volume: true,
  location: "asia-southeast1",
  is_available: true,
  base_price: {
    currency: "USD",
    amount: "29.771904",
  },
  supported_num_cores: [],
  sub_location_type_changeable: true,
  workspace_groups: [
    {
      id: "GCP",
      name: "GCP",
      platform: "dev-plane",
      platformId: "brev-gcp-test",
      platformRegion: "us-east-1",
      platformType: "gcp",
      status: "RUNNING",
      tenantType: "shared",
      version: "1.0.0",
      baseDns: "brev.dev",
      createdAt: "2021-09-29T14:00:00Z",
      locations: [
        {
          name: "asia-southeast1",
          description: "asia-southeast1",
          available: true,
          country: "SGP",
        },
        {
          name: "europe-west4",
          description: "europe-west4",
          available: true,
          country: "NLD",
        },
        {
          name: "us-central1",
          description: "us-central1",
          available: true,
          country: "USA",
        },
        {
          name: "us-central1",
          description: "us-central1",
          available: true,
          country: "USA",
        },
        {
          name: "us-east4",
          description: "us-east4",
          available: true,
          country: "USA",
        },
        {
          name: "us-east5",
          description: "us-east5",
          available: true,
          country: "USA",
        },
      ],
    },
  ],
};

export const dummyDGXInstanceObject: GPUInstanceType = {
  type: "a3-megagpu-8g:nvidia-h100-mega-80gb:8",
  supported_gpus: [
    {
      count: 8,
      memory: "80GiB",
      manufacturer: "NVIDIA",
      name: "H100",
    },
  ],
  memory: "1TiB848GiB",
  vcpu: 208,
  supported_architectures: ["x86_64"],
  supported_num_cores: [],
  clock_speed_in_ghz: "0.000000",
  sub_location: "europe-west4-b",
  elastic_root_volume: true,
  location: "europe-west4",
  is_available: true,
  network_performance: "",
  available_azs: [],
  default_cores: 0,
  maximum_network_interfaces: 0,
  prices: [],
  base_price: {
    currency: "USD",
    amount: "39.324000",
  },
  default_price: "",
  supported_storage: [],
  sub_location_type_changeable: true,
  workspace_groups: [
    {
      id: "dgxc-gcp",
      baseDns: "",
      createdAt: "",
      name: "dgxc-gcp",
      platform: "dev-plane",
      platformId: "dgxc-gcp-1",
      platformRegion: "us-east-1",
      platformType: "gcpalpha",
      status: "RUNNING",
      tenantType: "isolated",
      version: "v0.1.0",
      locations: [
        {
          name: "europe-west4",
          description: "europe-west4",
          available: true,
          country: "NLD",
        },
      ],
    },
  ],
};

export function getJupyterHealthcheck(
  healthCheck: HealthCheck[],
  jupyterApp?: { healthCheckID: string }
): HealthCheck | undefined {
  if (!healthCheck || !jupyterApp) {
    return undefined;
  }

  return healthCheck.find((hc: HealthCheck) => {
    return hc.health_check_id === jupyterApp.healthCheckID;
  });
}

const isHealthyPolicy1 = (
  healthCheck: HealthCheck[],
  instanceStatus: WorkspaceStatus,
  containerStatus: VerbBuildStatus,
  jupyterApp: any
): boolean => {
  return !!(
    instanceStatus === WorkspaceStatus.Running &&
    healthCheck &&
    healthCheck.length > 0 &&
    containerStatus === VerbBuildStatus.Completed &&
    jupyterApp != undefined &&
    getJupyterHealthcheck(healthCheck, jupyterApp)?.status === "healthy"
  );
};
// new logic that uses the tunnel status field in addition to the existing logic.
const isHealthyPolicy2 = (
  healthCheck: HealthCheck[],
  tunnel: ITunnel | undefined,
  instanceStatus: WorkspaceStatus,
  containerStatus: VerbBuildStatus,
  jupyterApp: any
): boolean => {
  const basicInstanceHealthyStatus = !!(
    instanceStatus === WorkspaceStatus.Running &&
    healthCheck &&
    healthCheck.length > 0 &&
    containerStatus === VerbBuildStatus.Completed
  );
  const jupyterHealthyStatus = !!(
    jupyterApp != undefined &&
    getJupyterHealthcheck(healthCheck, jupyterApp)?.status === "healthy" &&
    tunnel &&
    tunnel.tunnelStatus === "HEALTHY"
  );
  return basicInstanceHealthyStatus && jupyterHealthyStatus;
};

export const isHealthy = (
  environment: Environment,
  jupyterApp: any,
  flagUseTunnelStatusForNotebookHealth: boolean
): boolean => {
  return flagUseTunnelStatusForNotebookHealth
    ? isHealthyPolicy2(
        environment.healthCheck || [],
        environment.tunnel,
        getEnvironmentStatus(environment),
        getVerbBuildStatusFromTasks(environment.tasks || []),
        jupyterApp
      )
    : isHealthyPolicy1(
        environment.healthCheck || [],
        getEnvironmentStatus(environment),
        getVerbBuildStatusFromTasks(environment.tasks || []),
        jupyterApp
      );
};
