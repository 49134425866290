import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/browser";
import {
  LOCALSTORAGE_ACTIVE_ORG_ID_KEY,
  LOCALSTORAGE_USER_CACHE_PREFIX,
} from "constants/index";
import User from "../entities/User.entity";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import agent, { CreateUserRes, UserRes, UserUpdateReqBody } from "server";
import {
  AxiosRes,
  configureRequestInterceptors,
  createCustomFetchPost,
} from "server/config";
import { userContextDefaultValues } from "tests/defaultValues";
import { PermissionHierarchyType } from "../models/Permission.model";
import {
  APIKeys,
  UserOnboardingStatus,
  VerificationStatus,
} from "../models/User.model";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { createDefaultAnalyticsObject } from "components/Analytics";
import posthog from "posthog-js";
import Modal from "components/Modals/Modal";
import { isNvidiaEmail } from "utils";
import { useKas } from "./KasContext";
import { isKasAuthFlow } from "server/kas/utils";
import { NotificationContext } from "./NotificationContext";

export interface UserContextType {
  me: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  getUser: (userId: string, forceRefresh?: boolean) => Promise<User | null>;
  updateUser: (updates: UserUpdateReqBody) => Promise<UserRes>;
  verifyMe: () => void;
  getMe: () => Promise<User | null>;
  onboardingObject: UserOnboardingStatus;
  refreshOnboardingObject: () => Promise<void>; // Added this function
  setOnboardingObjectFunc: (updates: UserOnboardingStatus) => void;
  updateApiKeys: (apiKeys: APIKeys) => Promise<AxiosRes>;
}

export const UserContext = React.createContext<UserContextType>(
  userContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const UserContextProvider: React.FC<Props> = ({ children }) => {
  const posthog = usePostHog();
  const [me, setMe] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [onboardingObject, setOnboardingObject] =
    useState<UserOnboardingStatus>({
      SSH: false,
      usedCLI: false,
      attemptedToConnectCloud: false,
    });
  const [showTermsModal, setShowTermsModal] = useState(false);
  const notificationContext = useContext(NotificationContext);
  const migrateFlag = useFeatureFlagEnabled("migrate-user-brev-nvidia");

  const history = useHistory();

  const {
    isLoading: kasIsLoading,
    isUserLoggedIn: kasIsAuthenticated,
    currentNcaId,
    starfleetIdToken,
    user: kasUser,
  } = useKas();

  const {
    isAuthenticated: _auth0IsAuthenticated,
    isLoading: _auth0IsLoading,
    getAccessTokenSilently,
    getIdTokenClaims: _auth0getIdTokenClaims,
    error,
    user: _auth0User, // TODO - auth0User used to get picture
    logout,
  } = useAuth0?.();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0IsLoading = isKasAuthFlow ? kasIsLoading : _auth0IsLoading;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;
  const getIdTokenClaims = isKasAuthFlow
    ? () => starfleetIdToken
    : _auth0getIdTokenClaims;

  console.log("auth0IsAuthenticated", auth0IsAuthenticated);
  console.log("auth0IsLoading", auth0IsLoading);

  console.log("userContext");

  console.log("auth0User", auth0User);

  const refreshOnboardingObject = async () => {
    const res = await agent.Users.me();
    if (res.success && res.data) {
      const freshOnboardingData = res.data.onboardingData;
      setOnboardingObject(freshOnboardingData);
    }
  };

  const setOnboardingObjectFunc = async (o: UserOnboardingStatus) => {
    console.log("setOnboardingObjectFunc");
    // Optimistically update the local state
    setOnboardingObject(o);

    // Send updates to the server
    const result = await updateUser(createDefaultAnalyticsObject(me, o), false);

    // Handle potential server errors
    if (!result.success) {
      // Revert local state to previous value or handle the error differently
      console.error("Failed to update user on the server:", result.message);
      // Optionally: setOnboardingObject(previousOnboardingObject);
    }
    refreshOnboardingObject();
  };

  const updateApiKeys = async (apiKeys: APIKeys): Promise<AxiosRes> => {
    console.log("updateApiKeys");
    if (!me)
      return { success: false, message: "Cannot update, user not logged in." };

    // todo - we won't have all of these?
    const updates: UserUpdateReqBody = {
      username: me?.username,
      email: me?.email,
      name: me?.name,
      apiKeys: apiKeys,
    };
    const res = await agent.Users.update(me.id, updates);
    if (res.success && res.data) {
      getMe();
      return { success: true };
    }
    return { success: false, message: res.message || "" };
  };

  const getUser = async (
    userId: string,
    forceRefresh: boolean = false
  ): Promise<User | null> => {
    if (!forceRefresh) {
      const user = getCachedUser(userId);
      if (user) return user;
    }
    const res = await agent.Users.getById(userId);
    if (res.success && res.data) {
      const newUser = new User(res.data);
      setCachedUser(newUser);
      return newUser;
    }
    return null;
  };

  const updateUser = async (
    updates: UserUpdateReqBody,
    refreshUser: boolean = true
  ): Promise<UserRes> => {
    console.log("updateUser");
    if (!me)
      return { success: false, message: "Cannot update, user not logged in." };
    const res = await agent.Users.update(me.id, updates);
    if (res.success && res.data && refreshUser) {
      const newUser = new User(res.data);
      newUser.profilePhotoUrl = auth0User?.picture;
      setMe(newUser);
    }
    return res;
  };

  const migrateUserFromConsoleBrevToBrevNvidia = async (
    starfleetIdToken: string | null | undefined
  ) => {
    const migrationToken = window.localStorage.getItem("migration-token");
    if (!migrationToken) {
      return;
    }

    if (!starfleetIdToken) {
      notificationContext.showNotification(
        "No starfleetIdToken found with migration token",
        "",
        "error"
      );
      window.localStorage.removeItem("migration-token");
      return;
    }

    const verifyMigrationTokenRequest = createCustomFetchPost();
    const verifyMigrationTokenResponse = await verifyMigrationTokenRequest(
      "/api/users/verify-migration-token",
      {
        token: migrationToken,
      }
    );

    if (!verifyMigrationTokenResponse.success) {
      console.error(
        "Failed to verify migration token:",
        verifyMigrationTokenResponse.message
      );
      notificationContext.showNotification(
        "Failed to verify migration token (/api/users/verify-migration-token)",
        "",
        "error"
      );
      window.localStorage.removeItem("migration-token");
      return;
    }

    if (!verifyMigrationTokenResponse.data.valid) {
      console.error("Invalid migration token");
      notificationContext.showNotification(
        "Invalid migration token (/api/users/verify-migration-token)",
        "",
        "error"
      );
      window.localStorage.removeItem("migration-token");
      return;
    }

    if (!verifyMigrationTokenResponse.data.auth0IdToken) {
      console.error("No auth0IdToken found in migration token");
      notificationContext.showNotification(
        "No auth0IdToken found in migration token (/api/users/verify-migration-token)",
        "",
        "error"
      );
      window.localStorage.removeItem("migration-token");
      return;
    }

    const migrateUserRequest = createCustomFetchPost(
      verifyMigrationTokenResponse.data.auth0IdToken
    );
    const migrateUserResponse = await migrateUserRequest(
      "/api/users/migrate-user",
      {
        token: starfleetIdToken,
      }
    );

    if (!migrateUserResponse.success) {
      console.error(migrateUserResponse.message);
      if (
        migrateUserResponse.message &&
        migrateUserResponse.message.includes("external identity already exists")
      ) {
        notificationContext.showNotification(
          "An account with this email already exists on brev.nvidia.com. Click the merge banner button below the the navigation bar to merge your accounts.",
          migrateUserResponse.message,
          "error"
        );
      } else {
        notificationContext.showNotification(
          "Failed to migrate user from console.brev.dev to brev.nvidia.com (/api/users/migrate-user)",
          migrateUserResponse.message || "",
          "error"
        );
      }
      window.localStorage.removeItem("migration-token");
      return;
    }
    notificationContext.showNotification(
      "Successfully migrated user from console.brev.dev to brev.nvidia.com",
      "",
      "success"
    );
    window.localStorage.removeItem("migration-token");
  };

  const verifyMe = () => {
    console.log("verifyMe");
    if (!me) return;
    me.setVerificationStatus(VerificationStatus.Verified);
    setCachedUser(me);
  };

  const clear = () => {
    console.log("clear");
    setMe(null);
  };

  useEffect(() => {
    console.log("UserContextMeUseEffect1");
    if (!!me) {
      console.log("UserContextMeUseEffect2");
      setupAnalytics(me);
      if (me.onboardingData == null) {
        console.log("UserContextMeUseEffect3");
        setOnboardingObject({
          SSH: false,
          usedCLI: false,
          attemptedToConnectCloud: false,
        });
      } else {
        console.log("UserContextMeUseEffect4");
        // BANANA: should we be populating onboardingData elsewhere?
        setOnboardingObject(me?.onboardingData);
      }
      if (!me.onboardingData?.isTermsAccepted) {
        console.log("UserContextMeUseEffect5");
        setTimeout(() => {
          setShowTermsModal(true);
        }, 1000);
      }
    }
  }, [me]);

  // Load user and organization information
  useEffect(() => {
    console.log("UserContextLoadUseEffect1");
    const loadMeFromDBOrSignup = async () => {
      console.log("loadMeFromDBOrSignup");
      setIsLoading(true);
      // get invite token if it exists
      const inviteToken = window.localStorage.getItem(
        `${LOCALSTORAGE_USER_CACHE_PREFIX}-invite-token`
      );
      window.localStorage.removeItem(
        `${LOCALSTORAGE_USER_CACHE_PREFIX}-invite-token`
      );

      await migrateUserFromConsoleBrevToBrevNvidia(starfleetIdToken);

      // get me from our DB
      const me = await getMe();
      console.log("UserContextLoadUseEffect2", me);
      if (me) {
        console.log("UserContextLoadUseEffect3");
        if (inviteToken) {
          console.log("UserContextLoadUseEffect4");
          const res = await agent.Permissions.putWithToken(
            PermissionHierarchyType.Organization,
            inviteToken
          );
          if (res.success && res.data) {
            console.log("UserContextLoadUseEffect5");
            // something to alert orgContext to reload...
            localStorage.setItem(
              LOCALSTORAGE_ACTIVE_ORG_ID_KEY,
              res.data.object
            );
          } else {
            // should handle case where we cannot somehow change role permissions...
            // but also should probably be a toast that doesn't block the user from logging in...
          }
        }
        console.log("UserContextLoadUseEffect6");

        const tempMe = me;
        tempMe.profilePhotoUrl = auth0User?.picture;
        setMe(tempMe);
        setIsAuthenticated(true);
        setIsLoading(false);
        return;
      }

      // if we don't exist, signup and create first organization
      console.log("UserContextLoadUseEffect7", inviteToken);
      const res = await signup(inviteToken);
      console.log("UserContextLoadUseEffect8", res);
      if (res.success && res.data) {
        console.log("UserContextLoadUseEffect9", res);
        const meWithPublicKeyRes = await getMe();
        console.log("meWithPublicKeyRes", meWithPublicKeyRes);
        if (meWithPublicKeyRes) {
          console.log("UserContextLoadUseEffect10", res);
          const tempMe = meWithPublicKeyRes;
          tempMe.profilePhotoUrl = auth0User?.picture;
          setMe(meWithPublicKeyRes);
        } else {
          console.log("UserContextLoadUseEffect11", res);
          const tempMe = new User(res.data.user);
          tempMe.profilePhotoUrl = auth0User?.picture;
          setMe(tempMe);
        }

        console.log("UserContextLoadUseEffect12", res);
        setIsAuthenticated(true);
        let callback = localStorage.getItem("brev-redirect-url");
        if (callback && callback.includes("/login")) {
          console.log("UserContextLoadUseEffect13", res);
          callback = "/org/environments";
        }
        history.push(callback || "/org/environments");
        setIsLoading(false);
      } else {
        console.log("UserContextLoadUseEffect14", res);
        setIsAuthenticated(false);
        posthog.capture("SignUpError", {
          response: res,
        });
        console.log("errorPage2");
        history.push("/error/500", {
          title: "Signup Error",
          description: res.message,
        });
        setIsLoading(false);
      }
    };

    if (auth0IsLoading) {
      console.log("UserContextUseEffect1");
      setIsLoading(true);
    } else if (error) {
      console.log("UserContextUseEffect2");
      posthog.capture("SignUpError-Auth0State", {
        response: error,
      });
      setIsAuthenticated(false);
      console.log("errorPage3");
      history.push("/error/500", {
        title: "Signup Error",
        description: error.message,
      });
      setIsLoading(false);
    } else if (!auth0IsAuthenticated) {
      setIsAuthenticated(false);
      clear();
      localStorage.setItem(
        "brev-redirect-url",
        window.location.pathname + window.location.search
      ); // MAYBE THIS COULD BE SET MORE LIBERALLY

      // This is how we catch routes that are auth secured before redirecting to login page
      if (location.pathname.includes("/launchable/deploy/now")) {
        const searchParams = new URLSearchParams(location.search);
        //forceLogin=true is used to force the user to login before redirecting to the launchable deploy page
        if (!!searchParams.get("forceLogin")) {
          history.push("/login");
          // if (!isKasAuthFlow) {
          //   window.location.href = "https://brev.nvidia.com/login";
          // } else {
          //   history.push("/login");
          // }
          return;
        }
        const queryString = searchParams.toString();
        const redirectPath = `/launchable/deploy${
          queryString ? `?${queryString}` : ""
        }`;
        history.push(redirectPath);
        // if (!isKasAuthFlow) {
        //   window.location.href = "https://brev.nvidia.com" + redirectPath;
        // } else {
        //   history.push(redirectPath);
        // }
      } else {
        history.push("/logout");
      }
    } else if (auth0IsAuthenticated && !me) {
      console.log("UserContextUseEffect4");
      setIsAuthenticated(false);
      configureRequestInterceptors(getAccessTokenSilently).then(() => {
        loadMeFromDBOrSignup();
      });
    }
  }, [auth0IsLoading, auth0IsAuthenticated]);

  /** ***********************
   ******** HELPERS ********
   ************************ */

  const getMe = async (): Promise<User | null> => {
    console.log("useGetMe1");
    const res = await agent.Users.me();
    console.log("useGetMe2", res);
    if (res.success && res.data) {
      console.log("useGetMe3", res.success, res.data);
      const user = new User(res.data);
      console.log("useGetMe4", user);
      setCachedUser(user);
      console.log("useGetMe5");
      return user;
    }
    console.log("useGetMe6");
    return null;
  };

  const signup = async (inviteToken: string | null): Promise<CreateUserRes> => {
    console.log("signup1");
    const idToken = await getIdTokenClaims();
    if (!idToken) {
      console.log("signup2");
      return { success: false, message: "Unable to get id token from auth0." };
    }
    console.log("signup3");
    const res = await agent.Users.auth0Register(
      isKasAuthFlow ? idToken : idToken.__raw,
      inviteToken
    );
    console.log("signup4", res);
    return res;
  };

  const setupAnalytics = (me: User | null) => {
    console.log("setupAnalytics1");
    if (me) {
      console.log("setupAnalytics2");
      const { email, baseWorkspaceRepo, externalAuthId, id, name, username } =
        me;
      const meNoSensitiveData = {
        displayName: me.username,
        source: "brev-workspace",
        email,
        username,
        id,
        baseWorkspaceRepo,
        externalAuthId,
        name,
      };
      posthog?.identify(me.id, {
        name: me.name,
        email: me.email,
      });
      // Configure Sentry
      Sentry.configureScope((scope) => {
        scope.setUser({ ...meNoSensitiveData });
      });
    } else {
      console.log("setupAnalytics3");
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  };

  const getCachedUser = (userId: string): User | null => {
    console.log("getCachedUser1");

    const user = window.localStorage.getItem(
      `${LOCALSTORAGE_USER_CACHE_PREFIX}-${userId}`
    );

    console.log("getCachedUser2", user);
    if (!user) return null;
    return JSON.parse(user);
  };

  const setCachedUser = (user: User): void => {
    console.log("setCachedUser1");
    console.log("setCachedUser2", user);

    window.localStorage.setItem(
      `${LOCALSTORAGE_USER_CACHE_PREFIX}-${user.id}`,
      JSON.stringify(user)
    );
  };

  const providerValues = {
    me,
    isAuthenticated,
    isLoading,
    getUser,
    updateUser,
    verifyMe,
    getMe,
    refreshOnboardingObject,
    onboardingObject,
    setOnboardingObjectFunc,
    updateApiKeys,
  };

  return (
    <>
      <Modal
        setOpen={setShowTermsModal}
        isOpen={showTermsModal}
        onClose={() => {
          history.push("/logout");
        }}
        disableClickOutside={true}
        onSuccess={() => {
          setOnboardingObjectFunc({
            isTermsAccepted: true,
          });
        }}
        title={"Terms of Service"}
        body={
          <>
            <div className="flex flex-row items-center">
              <p className="text-sm text-gray-500 dark:text-secondary mr-1">
                I understand and agree to to the
              </p>
              <p
                onClick={() => {
                  window.open(
                    "https://www.nvidia.com/en-us/agreements/cloud-services/service-specific-terms-for-brev/",
                    "_blank"
                  );
                }}
                className="text-sm text-gray-500 dark:text-secondary underline mr-1 cursor-pointer"
              >
                Terms of Service
              </p>
              <p className="text-sm text-gray-500 dark:text-secondary mr-1">
                and
              </p>
              <p
                onClick={() => {
                  window.open(
                    "https://www.nvidia.com/en-us/about-nvidia/privacy-policy/",
                    "_blank"
                  );
                }}
                className="text-sm text-gray-500 dark:text-secondary underline cursor-pointer"
              >
                Privacy Policy
              </p>
              <p className="text-sm text-gray-500 dark:text-secondary mr-1">
                .
              </p>
            </div>
          </>
        }
        confirmLabel="Agree and Continue"
      />
      <UserContext.Provider value={providerValues}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export default UserContextProvider;
