import React, { useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { classNames } from "components/utils";
import Chip from "../chips";
import {
  CloudIcon,
  CpuChipIcon,
  PlayCircleIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Badge } from "components/Graphs/Badge";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import ChipStack from "../chips/ChipStack";
import { displayPlatformType } from "components/Environment/utils";

interface GPUFlatCardProps {
  includeTitle?: boolean;
  setInstanceType: (instanceType: GPUInstanceType) => void;
  instanceType: GPUInstanceType;
  chipName: string;
  manufacturerName: string;
  gpuCount: number;
  vramSize: string;
  ramSize: string;
  cpuCount: number;
  cloudProvider: string;
  price: string;
  withChipIcon?: boolean;
}

const GPUFlatCard: React.FC<GPUFlatCardProps> = ({
  includeTitle,
  instanceType,
  setInstanceType,
  chipName,
  manufacturerName,
  gpuCount,
  vramSize,
  ramSize,
  cpuCount,
  cloudProvider,
  price,
  withChipIcon,
}) => {
  return (
    <div
      onClick={() => {
        setInstanceType(instanceType);
      }}
    >
      <FlatCard className={`animate-border cursor-pointer`}>
        <div className="flex flex-col">
          {includeTitle && (
            <div className="flex flex-row items-center mb-1">
              <CpuChipIcon className="text-white h-5 w-5 mr-1" />
              <div className="text-white">Compute</div>
            </div>
          )}
          <div className="flex flex-row items-center justify-between">
            <div className="flex">
              {withChipIcon && (
                <Chip
                  dgx={cloudProvider === "GCPALPHA"}
                  selected={true}
                  chipName={chipName}
                  manufacturerName={manufacturerName}
                  onClick={() => {}}
                  className={classNames("h-[50px] mr-3 flex-shrink-0")}
                />
              )}
              <div className="flex flex-row">
                <div className="flex items-center">
                  <div className="text-md text-gray-900 dark:text-slate-100">
                    {gpuCount}x {manufacturerName} {chipName}
                  </div>
                  <div className="text-md text-gray-500 dark:text-secondary ml-3">
                    {vramSize} VRAM
                  </div>
                </div>
                <div className="flex items-center ml-3">
                  <div className="text-xs text-gray-500 dark:text-secondary mt-1">
                    {ramSize} RAM x {cpuCount} CPUS
                  </div>
                </div>
                {instanceType.supported_gpus[0].memory_details && (
                  <div className="flex items-center ml-3">
                    <div className="text-xs text-gray-500 dark:text-secondary mt-1">
                      (
                      {instanceType.supported_gpus[0].memory_details.toUpperCase()}
                      )
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-row items-center text-gray-900 dark:text-secondary mt-1 hidden sm:flex">
              <div className="flex flex-row items-center">
                <CloudIcon className="h-6 w-6 mr-1" />
                <h1 className="text-sm">
                  {displayPlatformType(cloudProvider)}
                </h1>
              </div>
              <div className="flex flex-row items-center ml-2">
                <PlayCircleIcon className="h-6 w-6 mr-1" />
                <h1 className="text-sm">${price}</h1>
              </div>
            </div>
          </div>
        </div>
        <span
          className={`btnBefore ${
            instanceType.workspace_groups[0].id === "dgxc-gcp" ? "gold" : ""
          }
        `}
        ></span>
        <span
          className={`btnAfter ${
            instanceType.workspace_groups[0].id === "dgxc-gcp" ? "gold" : ""
          }
        `}
        ></span>
      </FlatCard>
    </div>
  );
};

export default GPUFlatCard;
