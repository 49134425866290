// This works because App.tsx has the unauthenticated route outside of the Dash, which requires auth.
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, Bars3Icon, XCircleIcon } from "@heroicons/react/24/outline";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { OrgContext } from "contexts/OrgContext";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";
import { useHistory, useLocation } from "react-router-dom";
import shaka from "assets/img/png/shaka.png";
import { UserContext } from "contexts/UserContext";
import User from "../../entities/User.entity";
import DevToggle from "components/DevToggle";
import OrgDropdown from "../OrgPages/OrgDropdown";
import OrgSettingsSlideOver from "./OrgSlideOver";
import Button from "components/UI-lib/Button";
import { DarkModeContext } from "../../contexts/DarkModeContext";
import { GlobalUserType } from "models/User.model";
import "./index.scss";
import axios from "axios";
import agent from "server";
import { useAuth0 } from "@auth0/auth0-react";
import Dash, { GetLogo } from "./Dash";
import { useKas } from "contexts/KasContext";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";
import { AppBar } from "@kui/react";
import { cx } from "components/Graphs/utils";
import openExternal from "assets/img/svg/kui-open-external.svg";

const userNavigation = [
  { name: "Create an account!", href: "/" },
  { name: "Support / Help Center", href: "/help" },
];

const adminNavigation = [
  {
    name: "📆 Content Calendar",
    href: "https://docs.google.com/spreadsheets/d/1ds4n4G2YCgt-7QTndLBHNSk-IMg5Jr-WKqbC5YZ_n-4/edit?usp=sharing",
  },
  {
    name: "📈 Mixpanel Daily Board",
    href: "https://mixpanel.com/project/2604005/view/3142793/app/boards#id=1652147",
  },
  {
    name: "✒️ Figma Planning",
    href: "https://www.figma.com/file/gmECGaSCLadCJBYXWJUc4s/Planning?type=whiteboard&node-id=0%3A1&t=OA1DduRdUmQUh9P3-1",
  },
  {
    name: "🗺️ Figma Map",
    href: "https://www.figma.com/file/OMVmr6xiq7VlqwjJTlND2V/Mapping-it-out?type=whiteboard&node-id=0%3A1&t=uDngcVQaYbTNBdTb-1",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}
interface OptionalCallbackProps {
  children: React.ReactNode;
  callbackUrl?: string;
  notebookNotFound?: boolean;
  isGiftCard?: boolean;
}

export const DashWrapper: React.FC<OptionalCallbackProps> = (props) => {
  const {
    isAuthenticated: _auth0IsAuthenticated,
    user: _auth0User,
    isLoading: _auth0IsLoading,
  } = useAuth0?.();

  const {
    isUserLoggedIn: kasIsAuthenticated,
    user: kasUser,
    isLoading: kasIsLoading,
  } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0user = isKasAuthFlow ? kasUser : _auth0User;
  const auth0IsLoading = isKasAuthFlow ? kasIsLoading : _auth0IsLoading;

  if (!auth0IsLoading && auth0IsAuthenticated && auth0user) {
    return <>{props.children}</>;
  } else {
    return (
      <UnauthenticatedDash
        callbackUrl={props.callbackUrl}
        notebookNotFound={props.notebookNotFound}
        isGiftCard={props.isGiftCard}
      >
        {props.children}
      </UnauthenticatedDash>
    );
  }
};

const GetActionLabel = (notebookNotFound, isGiftCard) => {
  if (location.pathname.includes("notebook") && !notebookNotFound) {
    return "Deploy Notebook 🚀";
  }
  if (isGiftCard) {
    return "Go to Console";
  }
  return "Create an Account";
};

interface NavItemProps {
  className?: string;
  children: React.ReactNode;
  href: string;
  isExternal?: boolean;
  useExternalIcon?: boolean;
  selected?: boolean;
}

function NavItem({
  className,
  children,
  selected,
  href,
  isExternal,
  useExternalIcon,
  ...props
}: NavItemProps) {
  return (
    <a
      href={href as string}
      {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      className={cx(
        "nav-GPUsflex flex items-center text-[#8f8f8f] dark:hover:text-white border-highlight text-sm",
        selected && "!border !border-b-highlight",
        className
      )}
      {...(isExternal && { target: "_blank" })}
    >
      {children}{" "}
      {useExternalIcon && (
        <svg
          className="w-4 h-4 ml-1"
          fill="currentColor"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M14,7h-1v-3.293l-5.646,5.647l-0.708,-0.708l5.647,-5.646h-3.293v-1h5z"
          />
          <path fill="currentColor" d="M7,4h-4v9h9v-4h1v5h-11v-11h5z" />
        </svg>
      )}
    </a>
  );
}

// function DocsItem() {
//   return (
//     <NavItem
//       {...nvtrackProps("Docs click", { adobeType: "ctaButtonClick" })}
//       href="https://docs.api.nvidia.com/"
//       isExternal
//     >
//       Docs
//     </NavItem>
//   );
// }

const UnauthenticatedDash: React.FC<OptionalCallbackProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const [me, setMe] = useState<User | null>(null);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("GPUs");
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

  // we need to adjust tabs when the org context loads
  const tabs = [];
  const [navigation, setNavigation] = useState<NavigationItem[]>(tabs);
  const location = useLocation();
  // TODO: manually check if the user is authenticated. Something from usercontext

  const showTopButton = () => {
    if (
      location.pathname.includes("/launchable/deploy") &&
      !location.pathname.includes("/launchable/deploy/now")
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="min-h-full">
      <div className="flex bg-[#0e0e0e] w-full">
        <div className="w-full">
          <nav className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex min-h-12 gap-sm items-center">
            <GetLogo
              onClick={() => {
                window.location.href = "https://build.nvidia.com";
              }}
              className="cursor-pointer"
            />
            <div className="ml-8">
              <div className="flex flex-row items-center gap-6">
                <NavItem href="https://build.nvidia.com/explore" isExternal>
                  Explore
                </NavItem>
                <NavItem href="https://build.nvidia.com/models" isExternal>
                  Models
                </NavItem>
                <NavItem href="https://build.nvidia.com/blueprints" isExternal>
                  Blueprints
                </NavItem>
                <NavItem
                  href="https://brev.nvidia.com/environment/new/public"
                  isExternal
                >
                  GPUs
                </NavItem>
                <NavItem
                  href="https://docs.api.nvidia.com/"
                  isExternal
                  useExternalIcon
                >
                  Docs
                </NavItem>
                <NavItem
                  href="https://forums.developer.nvidia.com/c/ai-data-science/nvidia-nim/678"
                  isExternal
                  useExternalIcon
                >
                  Forum
                </NavItem>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="">
        <main>
          <div className="">{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default UnauthenticatedDash;

function SunIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z" />
      <path
        d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061"
        fill="none"
      />
    </svg>
  );
}

function MoonIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
