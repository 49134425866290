"use client";

import React, { useState } from "react";
import { Button } from "components/Graphs/Button";
import { Input } from "components/Graphs/Input";
import { Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { Tunnel } from ".";

interface CloudflareTunnelsProps {
  tunnels: Tunnel[];
  onAddTunnel: (name: string, port: number) => void;
  onRemoveTunnel: (id: string) => void;
}

export function CloudflareTunnels({
  tunnels,
  onAddTunnel,
  onRemoveTunnel,
}: CloudflareTunnelsProps) {
  const [newTunnelName, setNewTunnelName] = useState("");
  const [newTunnelPort, setNewTunnelPort] = useState("");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleAddTunnel = () => {
    setErrorMessages([]); // Clear previous errors

    // Check if name or port is empty
    if (!newTunnelName || !newTunnelPort) {
      setErrorMessages((prev) => [
        ...prev,
        "Please provide both a name and port number",
      ]);
      return;
    }

    // Check if tunnel name starts with an alphanumeric character
    if (!/^[a-zA-Z0-9]/.test(newTunnelName)) {
      setErrorMessages((prev) => [
        ...prev,
        "Tunnel names must start with an alphanumeric character",
      ]);
      return;
    }

    // Check for spaces in tunnel name
    if (newTunnelName.includes(" ")) {
      setErrorMessages((prev) => [
        ...prev,
        "Tunnel names cannot contain spaces",
      ]);
      return;
    }

    if (newTunnelName.includes("`")) {
      setErrorMessages((prev) => [
        ...prev,
        "Tunnel names cannot contain backticks",
      ]);
      return;
    }

    const port = Number.parseInt(newTunnelPort);
    if (isNaN(port) || port < 1 || port > 65535) {
      setErrorMessages((prev) => [
        ...prev,
        "Port must be a number between 1 and 65535",
      ]);
      return;
    }

    // Check if tunnel name already exists
    if (tunnels.some((tunnel) => tunnel.name === newTunnelName)) {
      setErrorMessages((prev) => [
        ...prev,
        `A tunnel with the name "${newTunnelName}" already exists`,
      ]);
      return;
    }

    // Check if port is already in use
    if (tunnels.some((tunnel) => tunnel.port === port)) {
      setErrorMessages((prev) => [
        ...prev,
        `Port ${port} is already assigned to another tunnel`,
      ]);
      return;
    }

    onAddTunnel(newTunnelName, port);
    setNewTunnelName("");
    setNewTunnelPort("");
    setErrorMessages([]);
  };

  const handleRemoveTunnel = (id: string) => {
    onRemoveTunnel(id);
  };

  return (
    <div className="mt-3">
      <div className="grid gap-4 md:grid-cols-3">
        <div className="space-y-2">
          <h2 className="text-primary text-sm">Tunnel Name</h2>
          <Input
            id="tunnel-name"
            placeholder="e.g., web-server"
            value={newTunnelName}
            onChange={(e) => setNewTunnelName(e.target.value)}
          />
          <p className="text-xs text-muted-foreground">
            Tunnel names cannot contain spaces. Use hyphens instead.
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-primary text-sm">Port Number</h2>
          <Input
            id="tunnel-port"
            placeholder="e.g., 8080"
            type="number"
            min="1"
            max="65535"
            value={newTunnelPort}
            onChange={(e) => setNewTunnelPort(e.target.value)}
          />
        </div>
        <div className="flex items-start mt-8">
          <div>
            <Button onClick={handleAddTunnel} className="w-full">
              Add Tunnel
            </Button>
          </div>
        </div>
      </div>
      {errorMessages.length > 0 && (
        <div className="flex flex-col gap-2">
          {errorMessages.map((error, index) => (
            <div key={index} className="text-red-500 text-sm font-mono">
              {error}
            </div>
          ))}
        </div>
      )}
      <div className="border border-zinc-800 rounded-md mt-4">
        <TableRoot>
          <Table>
            <TableRow>
              <TableHeaderCell>Tunnel Name</TableHeaderCell>
              <TableHeaderCell>Port</TableHeaderCell>
              <TableHeaderCell className="w-[100px]">Actions</TableHeaderCell>
            </TableRow>
            <TableBody>
              {tunnels.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} className="text-center py-6">
                    No tunnels configured. Add your first tunnel above.
                  </TableCell>
                </TableRow>
              ) : (
                tunnels.map((tunnel) => (
                  <TableRow key={tunnel.id}>
                    <TableCell>{tunnel.name}</TableCell>
                    <TableCell>{tunnel.port}</TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        className="h-8 w-8 p-0"
                        onClick={() => handleRemoveTunnel(tunnel.id)}
                        aria-label={`Remove ${tunnel.name} tunnel`}
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableRoot>
      </div>
    </div>
  );
}
