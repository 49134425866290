import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ComputeSelector from "./ComputeSelector";
import FlatCard from "components/UI-lib/FlatCard";
import ContainerSelector from "./ContainerSelector";
import "./style.scss";
import { GPUInstanceType } from "../Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import InputField from "components/UI-lib/InputField";
import { classNames, validateName } from "components/utils";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import Button from "components/UI-lib/Button";
import { BillingContext } from "contexts/BillingContext";
import { AddCreditsModal } from "components/ConnectCloudsOrCardModal/AddCreditsModal";
import ConnectCloudsOrCardModal from "components/ConnectCloudsOrCardModal";
import {
  allPublicCloudsName,
  handleIsPaymentSetup,
  hasVerbBaseImageContainer,
  usesVerbYaml,
} from "./utils";
import Checkbox from "components/UI-lib/Checkbox";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { useHistory } from "react-router";
import { UserContext } from "contexts/UserContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { useFeatureFlagEnabled } from "posthog-js/react";
import agent from "server";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import posthog from "posthog-js";
import { Badge } from "components/Graphs/Badge";
import FilterBar from "./ComputeSelector/Advanced/FilterBar";
import { ContainerSelected, parseDockerComposeUrl } from "../shared/BuildTypes";

export interface EnvironmentCreateProps {}

const EnvironmentCreate: React.FC = () => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const billingContext = useContext(BillingContext);
  const workspaceContext = useContext(WorkspaceContext);
  const history = useHistory();

  const flagEnvironmentsV1FlagEnabled = useFeatureFlagEnabled(
    "use-environments-v1"
  );

  // Add query parameter parsing
  const queryParams = new URLSearchParams(window.location.search);
  const instanceFromQuery = queryParams.get("instanceType");
  const storageFromQuery = queryParams.get("storage");

  //environment state
  const [container, setContainer] = useState<ContainerSelected | undefined>(
    instanceFromQuery
      ? {
          vmBuild: {
            forceJupyterInstall: false,
          },
        }
      : undefined
  );
  const [pythonVersion, setPythonVersion] = useState<string>("3.10");
  const [cudaVersion, setCudaVersion] = useState<string>("12.0.1");

  const [environmentName, setEnvironmentName] =
    useState<string>("awesome-gpu-name");
  const [nameFieldErrorMessage, setNameFieldErrorMessage] =
    useState<string>("");
  const [selectedInstanceType, setSelectedInstanceType] =
    useState<GPUInstanceType>();
  const [storage, setStorage] = useState<string>(
    storageFromQuery ? storageFromQuery : "256"
  );
  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);
  const [loading, setLoading] = useState(false);
  const [showFinalDeployMessage, setShowFinalDeployMessage] = useState(false);
  const [portMappings, setPortMapping] = useState({});

  // Credit card modal state
  const [showConnectCloudsOrCardModal, setShowConnectCloudsOrCardModal] =
    useState<boolean>(false);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState<any>(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const [noCardOnFile, setNoCardOnFile] = useState<boolean>(false);
  const [displayGPUInstances, setDisplayGPUInstances] = useState<
    GPUInstanceType[]
  >([]);

  useEffect(() => {
    if (instanceFromQuery) {
      const found = orgContext.GPUInstancesAvailable.find(
        (i) => i.type === instanceFromQuery
      );
      if (found) {
        setSelectedInstanceType(found);
      } else {
        console.error("Instance type not found");
      }
    }
  }, [instanceFromQuery, orgContext.GPUInstancesAvailable]);

  useEffect(() => {
    if (
      selectedInstanceType?.workspace_groups &&
      selectedInstanceType.workspace_groups.length > 0
    ) {
      const firstWorkspaceGroup = selectedInstanceType.workspace_groups[0];
      if (firstWorkspaceGroup.id === "dgxc-gcp") {
        setStorage("512");
      } else {
        setStorage(storageFromQuery ? storageFromQuery : "256");
      }
    }
  }, [selectedInstanceType, storageFromQuery]);

  const handleNameChange = (val: string) => {
    setEnvironmentName(val);

    // Set validation error messaging
    if (val === "") {
      setNameFieldErrorMessage("Name required!");
      return;
    }
    const isValid = validateName(val);
    if (!isValid) {
      setNameFieldErrorMessage(
        "Name can only have letters, numbers and dashes."
      );
      return;
    }
    setNameFieldErrorMessage("");
  };

  const handleCompletion = async () => {
    agent.Brevent.track({
      eventName: "Workspace Created From New UI",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
      },
    });

    if (
      selectedCloudName === allPublicCloudsName &&
      ((billingContext.billingProfile?.billing_type === "usage-arrears" &&
        noCardOnFile) ||
        (billingContext.billingProfile?.billing_type === "credit" &&
          billingContext.currentBalance <= 0))
    ) {
      setShowConnectCloudsOrCardModal(true);
      return;
    }

    if (!selectedInstanceType) {
      setInlineAlertMessage("Please select an instance");
      setInlineAlertSeverity("info");
      return;
    }

    if (
      !selectedInstanceType?.workspace_groups ||
      selectedInstanceType?.workspace_groups.length === 0
    ) {
      setInlineAlertMessage(
        "Please select a configuration from the options above."
      );
      setInlineAlertSeverity("error");
      console.error("No workspace groups available for this instance type");
      return;
    }

    let workspaceGroup;
    if (
      !!(workspaceGroup = selectedInstanceType?.workspace_groups.find(
        (wsg) => wsg.id === "dgxc-gcp"
      ))
    ) {
      workspaceGroup = selectedInstanceType?.workspace_groups.find(
        (wsg) => wsg.id === "dgxc-gcp"
      );
    } else if (selectedCloudName === allPublicCloudsName) {
      workspaceGroup = selectedInstanceType?.workspace_groups.find(
        (wsg) => wsg.tenantType === "shared"
      );
    } else {
      workspaceGroup = selectedInstanceType?.workspace_groups.find(
        (wsg) => wsg.id === selectedCloudName
      );
    }

    if (
      !workspaceGroup ||
      !workspaceGroup.locations ||
      workspaceGroup.locations.length === 0
    ) {
      console.error("No locations available for this workspace group");
      return;
    }

    //REMOVED BECAUSE OF BABY PROVISIONER
    const region = selectedInstanceType?.location
      ? workspaceGroup.locations.find(
          (w) => w.name === selectedInstanceType?.location
        )
      : workspaceGroup.locations[0];
    // then now we need an instance type and region to choose
    setLoading(true);
    setShowFinalDeployMessage(true);

    const customYaml = buildDefaultVerbYamlConfig(
      hasVerbBaseImageContainer(container)
        ? container?.verbBuild?.containerUrl
        : "",
      portMappings,
      cudaVersion,
      pythonVersion
    );

    console.log("-------container:----------", container);

    const res = await workspaceContext.createWorkspace(
      environmentName,
      orgContext.getActiveOrg()?.id || "",
      workspaceGroup.id, //  region?.workspaceGroup.id || "",
      "description",
      {}, // repo
      {}, // execs
      undefined, // ide config
      selectedInstanceType?.type,
      "", // image
      workspaceGroup.platformType === "akash" && region?.name
        ? region.name
        : "", // region?.name || "",
      "",
      storage || "120",
      false, // Spot
      false, // isStoppable
      false, // on container false
      container?.verbBuild?.containerUrl ? customYaml : "", // verb yaml
      hasVerbBaseImageContainer(container)
        ? container?.verbBuild?.containerUrl
        : "", // verb container url if it's not default
      container?.customContainer
        ? {
            containerUrl: container?.customContainer?.containerUrl,
            entryPoint: container?.customContainer?.entryPoint,
            registry: container?.customContainer?.registry,
          }
        : undefined, // custom container
      !!container?.vmBuild, // vm build
      portMappings,
      null,
      null,
      {
        isPublic: false,
      },
      "v1",
      !!container?.vmBuild?.forceJupyterInstall, // force jupyter install for vmMode,
      container?.dockerCompose
    );

    posthog.capture("Creating Instance", {
      instanceType: selectedInstanceType?.type,
      gpuName: selectedInstanceType?.supported_gpus
        ? selectedInstanceType?.supported_gpus[0].name
        : "",
      cloudProvider: workspaceGroup.platformType,
      container: container?.vmBuild
        ? "VM Mode"
        : container?.verbBuild?.containerUrl ||
          container?.customContainer?.containerUrl ||
          container?.dockerCompose?.fileUrl,
      orgID: orgContext.activeOrgId,
      userID: userContext.me?.id,
      wasSuccess: res.success,
    });

    if (res.success) {
      history.push(
        `/org/${orgContext.activeOrgId}/environments/${res.data?.id || ""}`,
        {
          message: "Instance is attempting to start!",
          messageSeverity: "info",
        }
      );

      setLoading(false);
      setShowFinalDeployMessage(false);
    } else if (res.message.includes("duplicate workspace with name")) {
      // setLoading(false);
      setShowFinalDeployMessage(false);
      setNameFieldErrorMessage(res.message);
    } else {
      console.error("Error in create instance: ", res.message);
      // errorRef.current?.scrollIntoView({ behavior: "smooth" });
      // TODO: if SSH step, show a different message
      if (
        res.message.includes("user not allowed to create workspace of type")
      ) {
        setInlineAlertMessage(
          "You need to add a payment method to access more powerful machines. You can do that from your org settings."
        );
        setInlineAlertSeverity("error");
        setInlineAlertAction(
          <Button
            label="Add payment method"
            type="secondary"
            hrefInNewTab={`/org/${orgContext.activeOrgId}/settings`}
            onClick={() => {
              history.push(`/org/${orgContext.activeOrgId}/settings`);
            }}
          />
        );
      } else if (res.message.includes("have requested more vCPU capacity")) {
        setInlineAlertMessage(
          "You have a limit on your AWS account preventing you from creating envs with this many vCPUs. Either create an env with the Brev Cloud or contact AWS support to increase your limit: https://aws.amazon.com/contact-us/ec2-request"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(<></>);
        // If its GCP it may take longer than browser expects
      } else if (res.message === "") {
        setInlineAlertMessage(
          "The provider has taken longer to respond than expected, the instance has likely been created. Refresh the instances page to check, if not try creating in a different region"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(<></>);
      } else if (
        res.message.includes(
          "There was a billing issue because there is no payment method visit the settings page to resolve this issue"
        )
      ) {
        setInlineAlertMessage(
          "There was a billing issue because there is no payment method visit the settings page to resolve this issue"
        );
        setInlineAlertSeverity("warning");
        setInlineAlertAction(
          <Button
            label="Add payment method"
            type="secondary"
            hrefInNewTab={`/org/${orgContext.activeOrgId}/settings`}
            onClick={() => {
              history.push(`/org/${orgContext.activeOrgId}/settings`);
            }}
          />
        );
      } else {
        setInlineAlertMessage(`Error creating instance: ${res.message}`);
        setInlineAlertSeverity("error");
        setInlineAlertAction(<></>);
      }

      setNameFieldErrorMessage("");
      setLoading(false);
      setShowFinalDeployMessage(false);
      return null;
    }
    setLoading(false);
  };

  const isDeployDisabled = useMemo(() => {
    return (
      orgContext.GPUInstancesAvailable.length === 0 ||
      !billingContext.billingProfile?.billing_profile_id ||
      nameFieldErrorMessage !== "" ||
      environmentName === "" ||
      Number(storage) < 100 ||
      Number(storage) > 16000
    );
  }, [
    orgContext.GPUInstancesAvailable,
    billingContext.billingProfile,
    nameFieldErrorMessage,
    environmentName,
    storage,
  ]);

  useEffect(() => {
    handleIsPaymentSetup(selectedCloudName, orgContext.activeOrgId).then(
      (isPaymentSetUp) => setNoCardOnFile(!isPaymentSetUp)
    );
  }, [orgContext.workspaceGroups, selectedCloudName]);

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-3">
      {billingContext.billingProfile?.billing_type === "credit" ? (
        <AddCreditsModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          noCardOnFile={noCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
        />
      ) : (
        <ConnectCloudsOrCardModal
          show={showConnectCloudsOrCardModal}
          setShow={setShowConnectCloudsOrCardModal}
          onCancel={() => {
            setShowConnectCloudsOrCardModal(false);
          }}
          setNoCardOnFile={setNoCardOnFile}
          onSuccess={() => {
            setShowConnectCloudsOrCardModal(false);
            setInlineAlertSeverity("success");
            setInlineAlertMessage("Credit card details saved successfully!");
          }}
          setSelectedCloudName={setSelectedCloudName}
          selectedCloudName={selectedCloudName}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row justify-between">
          <div className="flex mt-6">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-slate-100">
              {!container && "Select your Container"}
              {container && !selectedInstanceType && "Select your Compute"}
              {container && !!selectedInstanceType && "Configure and Deploy"}
              {container && (
                <>
                  <span
                    className="flex flex-col mt-1 text-highlight cursor-pointer hover:text-highlightLighter"
                    onClick={() => {
                      setContainer(undefined);
                    }}
                  >
                    <div className="flex flex-row">
                      <div className="flex flex-row items-center">
                        <RectangleGroupIcon className="w-5 h-5 mr-1" />
                      </div>
                      <div className="flex flex-row items-end">
                        <div className="text-sm">
                          {container?.verbBuild?.containerUrl === "Default" &&
                            `Default (Python ${pythonVersion}  and CUDA ${cudaVersion})`}
                          {container.vmBuild && "None (VM Mode)"}
                          {container.verbBuild?.containerUrl !== "Default" &&
                            container.verbBuild?.containerUrl}
                          {container.customContainer
                            ? container.customContainer.containerUrl
                            : ""}
                          {container.dockerCompose
                            ? container.dockerCompose.yamlString
                              ? "Uploaded Docker Compose"
                              : parseDockerComposeUrl(
                                  container.dockerCompose.fileUrl || ""
                                )
                            : ""}
                        </div>
                        <div className="text-sm">
                          {(container?.vmBuild?.forceJupyterInstall ||
                            container.dockerCompose?.jupyterInstall) && (
                            <>&nbsp;|&nbsp;Preinstalls Jupyter</>
                          )}
                        </div>
                      </div>
                    </div>
                  </span>
                </>
              )}
            </h1>
          </div>
          <div className="flex flex-row items-end">
            {container && !selectedInstanceType && (
              <FilterBar
                allGPUInstances={orgContext.GPUInstancesAvailable}
                setDisplayGPUInstances={setDisplayGPUInstances}
                selectedCloud={selectedCloudName}
                setSelectedCloud={setSelectedCloudName}
                containerSelected={container}
                displayGPUInstances={displayGPUInstances}
              />
            )}
          </div>
        </div>
        <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        <div className="flex flex-col">
          <AnimatePresence initial={false}>
            {!container && (
              <motion.section
                key="container"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{
                  duration: 0.8,
                  ease: [0.04, 0.62, 0.23, 0.98],
                }}
              >
                <motion.div
                  variants={{
                    collapsed: { scale: 0.8 },
                    open: { scale: 1 },
                  }}
                  transition={{ duration: 0.8 }}
                  className="content-placeholder"
                >
                  <ContainerSelector
                    hideTitle={true}
                    containerSelected={container}
                    onSelectContainer={setContainer}
                    pythonVersion={pythonVersion}
                    setPythonVersion={setPythonVersion}
                    cudaVersion={cudaVersion}
                    setCudaVersion={setCudaVersion}
                  />
                </motion.div>
              </motion.section>
            )}
            {container && (
              <>
                {/* <div
                  onClick={() => {
                    setContainer({ container: "", isCustomContainer: false });
                  }}
                  className="mb-5"
                >
                  <ContainerPreview
                    container={container}
                    pythonVersion={pythonVersion}
                    cudaVersion={cudaVersion}
                  />
                </div> */}
                <ComputeSelector
                  allGPUInstances={orgContext.GPUInstancesAvailable}
                  isLoadingInstances={orgContext.isInstancesLoading}
                  displayGPUInstances={displayGPUInstances}
                  setDisplayGPUInstances={setDisplayGPUInstances}
                  selectedInstance={selectedInstanceType}
                  setSelectedInstance={setSelectedInstanceType}
                  setStorage={setStorage}
                  storage={storage}
                  containerSelected={container}
                  selectedCloudName={selectedCloudName}
                  setSelectedCloudName={setSelectedCloudName}
                />
                {!!selectedInstanceType && (
                  <div className="mt-5">
                    <NameEnvironment
                      nameFieldErrorMessage={nameFieldErrorMessage}
                      environmentName={environmentName}
                      handleNameChange={handleNameChange}
                    />
                    <div className="flex flex-col mt-5">
                      <div>
                        <Button
                          className={classNames(
                            "mt-2",
                            isDeployDisabled ? "" : "pulse-ready-button"
                          )}
                          type={"primary"}
                          label={false ? "Deploying" : "Deploy"}
                          disabled={isDeployDisabled}
                          loading={loading}
                          onClick={handleCompletion}
                          data-testid="deploy-button"
                        />
                      </div>
                      <InlineNotification
                        show={!!inlineAlertMessage}
                        severity={inlineAlertSeverity}
                        className="w-full mt-5 pb-2"
                        text={inlineAlertMessage}
                        text2={
                          <div className="mt-2 ml-[36px]">
                            {inlineAlertAction}
                          </div>
                        }
                        autoClose={false}
                        onClose={() => {
                          setInlineAlertMessage("");
                          setInlineAlertAction(<></>);
                          setInlineAlertSeverity("error");
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentCreate;

interface FinalizeAndProvisionProps {
  environmentName: string;
  handleNameChange: (val: string) => void;
  nameFieldErrorMessage: string;
}

export const NameEnvironment: React.FC<FinalizeAndProvisionProps> = ({
  environmentName,
  handleNameChange,
  nameFieldErrorMessage,
}) => {
  return (
    <div className="w-full">
      <InputField
        label="Name Instance"
        value={environmentName}
        placeholder="Name your environment"
        errorMessage={nameFieldErrorMessage}
        onChange={handleNameChange}
        className="CodeCard-GitUrlInputField"
      />
    </div>
  );
};
