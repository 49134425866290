import { Container } from "components/Environment/Create/ContainerPicker";
import {
  githubRepoRegexCheck,
  gitlabRepoRegexCheck,
  sanitizeRepo,
  replaceBlobWithRaw,
} from "components/utils";
import { IFileType } from "models/Workspace.model";
import agent, {
  LaunchableFileRequest,
  LaunchableFromList,
  LaunchablePort,
} from "server";
import { useState, useEffect } from "react";
import { parsePortMappingEntry } from "components/Environment/utils";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import { usesVerbYaml } from "components/Environment/Create/utils";
import yaml from "js-yaml";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

export const isEmptyContainer = (container: Container): boolean => {
  if (container.baseImage) {
    return false;
  }
  if (container.pythonVersion && container.cudaVersion) {
    return false;
  }
  return true;
};

const getColabFileName = async (
  url: string,
  fileType: IFileType
): Promise<string> => {
  const res = await agent.Workspaces.validateFilePublicity({
    fileUrl: url,
    fileType: fileType,
  });
  if (res.success && res.data) {
    return res.data.fileName || "";
  }
  return "Colab File";
};

export const shortenFileName = async (fileName: string): Promise<string> => {
  const slashIndex = fileName.lastIndexOf("/");
  const dotPynbIndex = fileName.lastIndexOf(".ipynb");

  if (slashIndex !== -1) {
    let shortFileName = fileName.slice(
      slashIndex + 1,
      dotPynbIndex !== -1 ? dotPynbIndex : undefined
    );

    if (fileName.includes("colab")) {
      const colabFile = await getColabFileName(
        fileName,
        IFileType.ColabFileType
      );
      return `Colab: ${colabFile}`;
    } else if (fileName.includes("ipynb")) {
      return `Notebook: ${shortFileName}`;
    } else if (fileName.includes("github.com")) {
      return `GitHub Repo: ${sanitizeRepo(shortFileName)}`;
    } else if (fileName.includes("gitlab.com")) {
      return `GitLab Repo: ${sanitizeRepo(shortFileName)}`;
    }
  }
  return "";
};

export enum FileType {
  Notebook,
  Colab,
  GithubRepo,
  GitlabRepo,
  Unknown,
}

export function getFileType(fileName: string | null | undefined): FileType {
  if (fileName?.endsWith(".ipynb")) {
    return FileType.Notebook;
  }
  if (fileName?.includes("colab")) {
    return FileType.Colab;
  }
  if (githubRepoRegexCheck(fileName || "")) {
    return FileType.GithubRepo;
  }
  if (gitlabRepoRegexCheck(fileName || "")) {
    return FileType.GitlabRepo;
  }

  return FileType.Unknown;
}

export const getLaunchableUrl = (launchable: LaunchableFromList): string => {
  if (launchable.rawURL === "")
    return `${
      isKasAuthFlow
        ? CONSOLE_1_KAS_DEPLOYMENT_URL
        : CONSOLE_1_AUTH0_DEPLOYMENT_URL
    }/launchable/deploy?launchableID=${launchable.id}`;
  let newUrl = new URL(launchable.rawURL || "");
  newUrl.searchParams.append("launchableID", launchable.id);
  return newUrl.toString();
};

export interface ProcessedLaunchableData {
  instanceType: string | null;
  workspaceGroupId: string | null;
  containerSelected: ContainerSelected;
  cuda: string | null;
  python: string | null;
  diskStorage: string | null;
  file: string | null;
  ports: Record<string, string> | null;
  firewallRules: string[] | null;
  userId: string | null;
  orgId: string | null;
  name: string | null;
  launchableId: string | null;
  url: string;
  couponCode: string | null;
}

export const transformPortListToMap = (
  ports: LaunchablePort[]
): Record<string, string> | null => {
  if (!ports || ports.length === 0) {
    return null;
  }
  const portMap: Record<string, string> = {};
  ports.forEach((port) => {
    portMap[port.name] = port.port;
  });
  return portMap;
};

export const transformPortMapToList = (
  portMap: Record<string, string> | null
): LaunchablePort[] => {
  if (!portMap) {
    return [];
  }
  return Object.entries(portMap).map(([name, port]) => ({
    name,
    port,
  }));
};

export const processLaunchableData = (
  data: LaunchableFromList
): ProcessedLaunchableData | null => {
  // New way doesn't parse rawURL anymore but still needs to be backwards compatible
  if (!data.rawURL) {
    // Plumb together the containerSelected object
    let pythonVersion = "3.10";
    let cudaVersion = "12.0.1";
    let containerSelected: ContainerSelected = {
      verbBuild: {
        containerUrl: "Default",
      },
    };

    if (data.buildRequest?.vmBuild) {
      containerSelected = {
        vmBuild: data.buildRequest.vmBuild,
      };
    } else if (
      data.buildRequest?.verbBuild &&
      data.buildRequest?.verbBuild.verbYaml != ""
    ) {
      const verbYaml = yaml.load(data.buildRequest?.verbBuild.verbYaml);
      if (verbYaml?.build?.base_image) {
        containerSelected = {
          verbBuild: {
            containerUrl: verbYaml.build.base_image,
          },
        };
      } else {
        containerSelected = {
          verbBuild: {
            containerUrl: "Default",
          },
        };
        pythonVersion = verbYaml?.build?.python_version || "3.10";
        cudaVersion = verbYaml?.build?.cuda || "12.0.1";
      }
    } else if (
      data.buildRequest?.containerBuild?.containerUrl &&
      data.buildRequest?.containerBuild?.containerUrl != ""
    ) {
      const containerUrl = data.buildRequest.containerBuild.containerUrl;
      containerSelected = {
        customContainer: {
          containerUrl: containerUrl,
          entryPoint: data.buildRequest.containerBuild.entryPoint || "",
        },
      };
    } else if (data.buildRequest?.dockerCompose) {
      containerSelected = {
        dockerCompose: {
          fileUrl: data.buildRequest.dockerCompose.fileUrl,
          jupyterInstall: data.buildRequest.dockerCompose.jupyterInstall,
          yamlString: data.buildRequest.dockerCompose.yamlString,
          registries: data.buildRequest.dockerCompose.registries,
        },
      };
    }

    return {
      instanceType: data.createWorkspaceRequest?.instanceType || "",
      workspaceGroupId: data.createWorkspaceRequest?.workspaceGroupId || "",
      diskStorage: data.createWorkspaceRequest?.storage || "",
      containerSelected: containerSelected,
      cuda: cudaVersion,
      python: pythonVersion,
      file: data.file?.url || "",
      ports: transformPortListToMap(data.buildRequest?.ports || []),
      firewallRules: data.createWorkspaceRequest?.exposedPorts || null,
      userId: data.createdByUserId || null,
      orgId: data.createdByOrgId || null,
      name: data.name || null,
      launchableId: data.id || null,
      url: getLaunchableUrl(data),
      couponCode: data.couponCode || null,
    };
  } else {
    let searchParams = "";
    try {
      searchParams = new URL(data.rawURL || "").search;
    } catch (error) {
      console.error("Invalid URL", error);
      return null;
    }
    // If no version field, grab from query params
    const query = new URLSearchParams(searchParams);
    const ports = query.get("ports");
    const mappedPortsFromString = parsePortMappingEntry(ports || "");

    return {
      instanceType: query.get("instance"),
      workspaceGroupId: query.get("cloudID"),
      containerSelected: {
        verbBuild: {
          containerUrl: query.get("baseImage") || "Default",
        },
      },
      cuda: query.get("cuda"),
      python: query.get("python"),
      diskStorage: query.get("diskStorage"),
      file: query.get("file"),
      ports: mappedPortsFromString,
      firewallRules: [],
      userId: query.get("userID"),
      orgId: query.get("orgID"),
      name: query.get("name"),
      launchableId: query.get("launchableID"),
      url: getLaunchableUrl(data),
      couponCode: query.get("couponCode"),
    };
  }
};

interface UseLaunchableResult {
  launchableData: ProcessedLaunchableData | null;
  error: string | null;
  loading: boolean;
  launchableNotFound: boolean;
}

export const useLaunchable = (launchableId: string): UseLaunchableResult => {
  const [launchableData, setLaunchableData] =
    useState<ProcessedLaunchableData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [launchableNotFound, setLaunchableNotFound] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await agent.Launchables.getLaunchable(launchableId);
      if (response.success && response.data) {
        const processedResult = processLaunchableData(response.data);
        if (!processedResult) {
          setError("Error processing launchable data");
          setLoading(false);
          return;
        }

        setLaunchableData(processedResult);
        setLoading(false);
      } else {
        setError(response.message || "Error fetching launchable data");
        if (response.message?.includes("environment not found")) {
          setLaunchableNotFound(true);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [launchableId]);

  return { launchableData, error, loading, launchableNotFound };
};

export const createFileRequest = (
  file: string,
  containerSelected?: ContainerSelected
): LaunchableFileRequest[] | null => {
  if (file === "") {
    return null;
  }

  const path = !!containerSelected?.verbBuild?.containerUrl
    ? "./verb-workspace"
    : "./";

  return [{ url: replaceBlobWithRaw(file), path }];
};
