import React, { useEffect, useState } from "react";
import { FlatCard } from "components/UI-lib";
import { Input } from "components/Graphs/Input";
import { Textarea } from "components/Graphs/TextArea";
import { Button } from "components/Graphs/Button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectGroupLabel,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/Graphs/Select";
import GB200Image from "assets/img/png/gb200.png";
import GB200HardwareImage from "assets/img/png/gb200-hardware.png";
import {
  DatePicker,
  DateRange,
  DateRangePicker,
  formatDate,
} from "components/Graphs/DateRangePicker";
import { enUS } from "date-fns/locale";

interface GB200RequestFormProps {}

const GB200RequestForm: React.FC<GB200RequestFormProps> = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    useCase: "",
    gb200Features: "",
    gpuCount: "",
    cloudPartner: "",
    duration: "",
    startTime: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wasSuccess, setWasSuccess] = useState<boolean | null>(null);
  const [date, setDate] = useState<Date | undefined>();

  const cloudPartnerOptions = [
    {
      label: "Hyperscaler Cloud Providers",
      items: [
        { value: "aws", label: "AWS" },
        { value: "gcp", label: "GCP" },
        { value: "azure", label: "Azure" },
        { value: "oracle", label: "Oracle" },
      ],
    },
    {
      label: "NVIDIA Cloud Partners",
      items: [
        { value: "coreweave", label: "CoreWeave" },
        { value: "lambda", label: "Lambda Labs" },
        { value: "crusoe", label: "Crusoe" },
        { value: "fluidstack", label: "FluidStack" },
        { value: "nebius", label: "Nebius" },
        { value: "paperspace", label: "Paperspace" },
        { value: "genesis", label: "Genesis Cloud" },
        { value: "vultr", label: "Vultr" },
        { value: "other", label: "Other" },
      ],
    },
  ];

  useEffect(() => {
    console.log(date, "Testing");
  }, [date]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const startTime = date ? formatDate(date, enUS, false) : "";

    const body = {
      ...formData,
      startTime: startTime,
    };

    try {
      const response = await fetch(
        `https://gb-200-intake-723439408606.us-central1.run.app/register-interest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const result = await response.json();

      if (!result.success) {
        throw new Error("Failed to submit form");
      }

      // Clear form
      setFormData({
        name: "",
        email: "",
        companyName: "",
        useCase: "",
        gb200Features: "",
        gpuCount: "",
        cloudPartner: "",
        duration: "",
        startTime: "",
      });
      setDate(undefined);

      setWasSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setWasSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `!function(t){var k="ko",i=(window.globalKoalaKey=window.globalKoalaKey||k);if(window[i])return;var ko=(window[i]=[]);["identify","track","removeListeners","on","off","qualify","ready"].forEach(function(t){ko[t]=function(){var n=[].slice.call(arguments);return n.unshift(t),ko.push(n),ko}});var n=document.createElement("script");n.async=!0,n.setAttribute("src","https://cdn.getkoala.com/v1/pk_0ec57e6e120adea8326447375daf68ccaeaf/sdk.js"),(document.body || document.head).appendChild(n)}();`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="mb-8">
      <h1 className="text-2xl font-bold mb-2 text-white">GB200 Request Form</h1>
      <p className="text-white mb-4">
        GB200s are in high demand so please fill out the form to request early
        access
      </p>
      <div className="flex flex-row bg-black p-4">
        <div className="flex-col items-start justify-start w-full hidden sm:flex">
          <div className="h-full bg-black justify-center flex flex-col">
            <img
              src={GB200HardwareImage}
              className="h-auto object-contain"
              alt="NVIDIA GB200 Hardware"
            />
            <img
              src={GB200Image}
              className="h-auto object-contain"
              alt="NVIDIA GB200"
            />
          </div>
        </div>
        <FlatCard className="bg-gray-900 border-gray-800 p-6 rounded-lg">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <div className="text-gray-300">Name</div>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="text-white mt-1"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">Email</div>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="text-white mt-1"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">Company Name</div>
                <Input
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="text-white mt-1"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">Describe your use case</div>
                <Textarea
                  id="useCase"
                  name="useCase"
                  value={formData.useCase}
                  onChange={handleChange}
                  className="text-white mt-1 min-h-[100px]"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">
                  Describe any GB200 specific features important to your use
                  case
                </div>
                <p className="text-xs text-gray-400 mb-1">
                  (FP8, FP4, 72-GPU NVLink domain, tight CPU-GPU communication,
                  larger GPU memories)
                </p>
                <Textarea
                  id="gb200Features"
                  name="gb200Features"
                  value={formData.gb200Features}
                  onChange={handleChange}
                  className="text-white mt-1 min-h-[100px]"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">
                  Number of total GPUs required
                </div>
                <Input
                  id="gpuCount"
                  name="gpuCount"
                  type="number"
                  min="1"
                  value={formData.gpuCount}
                  onChange={handleChange}
                  className="text-white mt-1"
                  required
                />
              </div>

              <div>
                <div className="text-gray-300">Preferred Cloud Partner</div>
                <Select
                  name="cloudPartner"
                  value={formData.cloudPartner}
                  onValueChange={(value) =>
                    setFormData((prev) => ({ ...prev, cloudPartner: value }))
                  }
                >
                  <SelectTrigger className="text-white mt-1">
                    <SelectValue placeholder="Select cloud partner" />
                  </SelectTrigger>
                  <SelectContent className="text-white">
                    {cloudPartnerOptions.map((group) => (
                      <SelectGroup key={group.label}>
                        <SelectGroupLabel>{group.label}</SelectGroupLabel>
                        {group.items.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <div className="text-gray-300">Duration of project</div>
                <Select
                  name="duration"
                  value={formData.duration}
                  onValueChange={(value) =>
                    setFormData((prev) => ({ ...prev, duration: value }))
                  }
                >
                  <SelectTrigger className="text-white mt-1">
                    <SelectValue placeholder="Select duration" />
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(
                      (months) => (
                        <SelectItem
                          key={months}
                          value={`${months} ${
                            months === 1 ? "month" : "months"
                          }`}
                        >
                          {months} {months === 1 ? "month" : "months"}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <div className="text-gray-300">Estimated start time</div>
                <DatePicker
                  id="startTime"
                  value={date}
                  onChange={setDate}
                  fromDate={new Date()}
                  className="text-white mt-1"
                  required
                />
              </div>
            </div>

            <Button
              type="submit"
              className="w-full bg-[#76B900] hover:bg-[#5a8c00] text-black font-medium"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Request"}
            </Button>
          </form>
          {wasSuccess !== null && (
            <div className="mt-4 text-center">
              {wasSuccess ? (
                <span className="text-highlight">
                  Request submitted successfully!
                </span>
              ) : (
                <span className="text-red-500">
                  Request failed. Please try again.
                </span>
              )}
            </div>
          )}
        </FlatCard>
      </div>
    </div>
  );
};

export default GB200RequestForm;
