import { useAuth0 } from "@auth0/auth0-react";
import { DashWrapper } from "components/DashboardContainer/UnauthenticatedDash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getLaunchableQueryParams } from "../shared/utils";
import {
  BookOpenIcon,
  Cog6ToothIcon,
  Cog8ToothIcon,
  PlayCircleIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import FlatCard from "components/UI-lib/FlatCard";
import LaunchableConfigurationViewFromParams from "../shared/LaunchableConfigurationViewFromParams";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import posthog from "posthog-js";
import FileViewer from "../shared/FileViewer";
import { useLaunchable } from "../../utils";
import LaunchableConfigurationView from "../shared/LaunchableConfigurationView";
import { dummyInstanceObject } from "components/Environment/utils";
import { useKas } from "contexts/KasContext";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";
import { Tooltip } from "@mui/material";
import { roundPriceToTwoDigits } from "components/utils";
import { LaunchableNotFound } from "../shared/LaunchableNotFound";
import { Button } from "@kui/react";
import Footer from "components/Footer";

const LaunchableDeployPublic: React.FC = () => {
  const location = useLocation();
  console.log("Route: /launchable/deploy", location);
  const {
    isAuthenticated: _auth0IsAuthenticated,
    user: _auth0User,
    isLoading: _auth0IsLoading,
  } = useAuth0?.();

  const {
    isUserLoggedIn: kasIsAuthenticated,
    user: kasUser,
    isLoading: kasIsLoading,
  } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;
  const auth0IsLoading = isKasAuthFlow ? kasIsLoading : _auth0IsLoading;

  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const { queryLaunchableID } = getLaunchableQueryParams(location.search);
  const { launchableData, error, loading, launchableNotFound } = useLaunchable(
    queryLaunchableID || ""
  );
  const [instanceObject, setInstanceObject] = useState<GPUInstanceType | null>(
    null
  );
  const [extraEventProperties, setExtraEventProperties] = useState<
    Record<string, any>
  >({});

  useEffect(() => {
    const fn = async () => {
      const eventProperties = {
        launchableID: queryLaunchableID,
        launchableInstanceType: launchableData?.instanceType,
        workspaceGroupID: launchableData?.workspaceGroupId,
        launchableCreatedByUserID: launchableData?.userId,
        launchableCreatedByOrgID: launchableData?.orgId,
        launchableRawURL: location.pathname + location.search,
      };
      setExtraEventProperties(eventProperties);
      posthog.capture("Launchable Viewed", eventProperties);
    };
    if (!!launchableData) {
      fn();
    }
  }, [launchableData]);

  const handleDeployNow = () => {
    localStorage.setItem(
      "brev-redirect-url",
      `/launchable/deploy/now?forceLogin=true&${query.toString()}`
    );
    window.open(
      `${
        isKasAuthFlow
          ? CONSOLE_1_KAS_DEPLOYMENT_URL
          : CONSOLE_1_AUTH0_DEPLOYMENT_URL
      }/launchable/deploy/now?forceLogin=true&${query.toString()}` ||
        `${
          isKasAuthFlow
            ? CONSOLE_1_KAS_DEPLOYMENT_URL
            : CONSOLE_1_AUTH0_DEPLOYMENT_URL
        }/login`,
      "_blank"
    );
  };

  useEffect(() => {
    if (auth0IsLoading) {
      return;
    }

    if (!isKasAuthFlow && auth0IsAuthenticated && auth0User?.email) {
      // Use the full URL with the correct query parameters
      const redirectUrl = `/launchable-migrate-notice?${new URLSearchParams({
        redirect: `https://brev.nvidia.com/launchable/deploy?${query.toString()}`,
      }).toString()}`;

      history.push(redirectUrl);
    } else if (isKasAuthFlow && auth0IsAuthenticated) {
      history.push(`/launchable/deploy/now?${query.toString()}`);
    } else if (!isKasAuthFlow && !auth0IsAuthenticated && !auth0User) {
      window.location.href = `https://brev.nvidia.com/launchable/deploy?${query.toString()}`;
    } else if (isKasAuthFlow && auth0IsAuthenticated && auth0User) {
      history.push(`/launchable/deploy/now?${query.toString()}`);
    }
  }, [auth0IsAuthenticated, auth0User, isKasAuthFlow, auth0IsLoading, query]);

  return (
    <DashWrapper>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10 min-h-screen">
        {!loading && launchableNotFound ? (
          <LaunchableNotFound />
        ) : (
          <>
            <div className="flex flex-row justify-between mb-5">
              <div className="flex flex-col">
                <h1 className="text-white text-3xl mr-2">
                  {launchableData?.name || ""}
                </h1>
                <div className="flex items-center text-secondary">
                  {instanceObject && (
                    <Tooltip placement="top" title="Cost to run launchable">
                      <div className="flex flex-row items-center">
                        <PlayCircleIcon className="w-4 h-4" />
                        <div className="flex flex-col text-sm ml-1">
                          $
                          {roundPriceToTwoDigits(
                            instanceObject?.base_price?.amount || "0.00"
                          )}
                          /hr
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              {!loading && (
                <div className="flex flex-col justify-center">
                  <div className="flex flex-row justify-center">
                    <div className="ml-5">
                      <Button
                        onClick={() => {
                          handleDeployNow();
                        }}
                      >
                        Deploy Launchable
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <FlatCard
              className="min-h-[150px] flex items-center"
              noBorder={true}
            >
              {loading && (
                <div className="w-full mb-3 flex text-gray-900 dark:text-white">
                  <h1 className="text-lg font-semibold mr-2">
                    Loading Launchable...
                  </h1>
                </div>
              )}
              {!loading ? (
                <>
                  <LaunchableConfigurationViewFromParams
                    containerSelected={
                      launchableData?.containerSelected || null
                    }
                    cuda={launchableData?.cuda || ""}
                    diskStorage={launchableData?.diskStorage || ""}
                    file={launchableData?.file || ""}
                    instanceType={launchableData?.instanceType || ""}
                    ports={launchableData?.ports || null}
                    firewallRules={launchableData?.firewallRules || null}
                    python={launchableData?.python || ""}
                    workspaceGroupId={launchableData?.workspaceGroupId || ""}
                    setInstance={setInstanceObject}
                    forceJupyterInstall={
                      launchableData?.containerSelected.vmBuild
                        ?.forceJupyterInstall ||
                      launchableData?.containerSelected.dockerCompose
                        ?.jupyterInstall ||
                      false
                    }
                  />
                </>
              ) : (
                <div className="opacity-50 shimmer">
                  <LaunchableConfigurationView
                    shortFileName="Your Notebook Here"
                    loadingInstance={true}
                    containerSelected={{
                      verbBuild: {
                        containerUrl: "loading-base-image",
                      },
                    }}
                    forceJupyterInstall={false}
                    cuda={""}
                    diskStorage={"256Gi"}
                    file={"file name"}
                    instanceObject={null}
                    ports={null}
                    python={""}
                    firewallRules={null}
                  />
                </div>
              )}
            </FlatCard>
            {!loading && <FileViewer file={launchableData?.file || ""} />}
          </>
        )}
      </div>
      <Footer />
    </DashWrapper>
  );
};

export default LaunchableDeployPublic;
