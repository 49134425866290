// make a basic react component that just says hello world
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts/UserContext";
import FlatCard from "components/UI-lib/FlatCard";
import SSHKeys from "components/SSHKeys/SSHKeys";
import SecretsPanel from "components/SecretsPanel";
import { HierarchyType } from "models/Secret.model";
import Button from "components/UI-lib/Button";
import PersonalTerminalSettings from "./PersonalTerminalSettings";
import ProfileUserMetadata from "./ProfileUserMetadata";
import UserResourceLimits from "./UserResourceLimits";
import InputField from "components/UI-lib/InputField";
import DevToggle from "components/DevToggle";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import MergeBanner from "components/DashboardContainer/MergeBanner";

function Profile() {
  console.log("Route: /profile");

  const userContext = useContext(UserContext);

  // New state for Secret Keys
  const [showSecretKeys, setShowSecretKeys] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secretKeys, setSecretKeys] = useState({
    ngcKey: "",
    huggingFaceKey: "",
  });
  const [savedSecretKeys, setSavedSecretKeys] = useState({
    ngcKey: "",
    huggingFaceKey: "",
  });

  const [inlineAlertMessage, setInlineAlertMessage] = useState("");

  useEffect(() => {
    document.title = "Your Profile | Brev.dev";
  }, []);

  useEffect(() => {
    if (!userContext.me) return;
    setSecretKeys({
      ngcKey: userContext.me.apiKeys?.ngc || "",
      huggingFaceKey: userContext.me.apiKeys?.huggingFace || "",
    });
    setSavedSecretKeys({
      ngcKey: userContext.me.apiKeys?.ngc || "",
      huggingFaceKey: userContext.me.apiKeys?.huggingFace || "",
    });
  }, [userContext.me]);

  const handleSecretKeysSave = async () => {
    setLoading(true);
    const res = await userContext.updateApiKeys({
      ngc: secretKeys.ngcKey,
      huggingFace: secretKeys.huggingFaceKey,
    });
    setLoading(false);
    if (res.success) {
      setInlineAlertMessage("Secret keys saved successfully.");
    } else {
      setInlineAlertMessage("Failed to save secret keys.");
      setSecretKeys(savedSecretKeys);
    }
    setShowSecretKeys(false);
  };

  return (
    <>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5 pb-5">
        <ProfileUserMetadata />
      </div>
      <MergeBanner alwaysShow={true} />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5 pt-0"></div>
      <DevToggle>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5 pt-0">
          <FlatCard>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Secret Keys
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-secondary">
              Save secret keys to your profile and load them into every GPU
              instance you create.
            </p>
            <div className="min-w-fit-content items-center">
              <div className="flex flex-col space-y-4">
                <InputField
                  key={1}
                  label={"NGC Key"}
                  type="password"
                  value={secretKeys.ngcKey}
                  onChange={(v) =>
                    setSecretKeys((prev) => ({ ...prev, ["ngcKey"]: v }))
                  }
                  placeholder={`Enter NGC Key`}
                  errorMessage={""}
                />
                <InputField
                  key={2}
                  label={"Hugging Face Key"}
                  type="password"
                  value={secretKeys.huggingFaceKey}
                  onChange={(v) =>
                    setSecretKeys((prev) => ({
                      ...prev,
                      ["huggingFaceKey"]: v,
                    }))
                  }
                  placeholder={`Enter Hugging Face Key`}
                  errorMessage={""}
                />
              </div>
              <div>
                <Button
                  label="Save Secret Keys"
                  disabled={Object.values(secretKeys).every(
                    (value) => value === ""
                  )}
                  className="mt-3"
                  onClick={handleSecretKeysSave}
                  type="primary"
                  loading={loading}
                />
                <InlineNotification
                  show={!!inlineAlertMessage}
                  severity="info"
                  text={inlineAlertMessage}
                  autoClose={false}
                  onClose={() => setInlineAlertMessage("")}
                />
              </div>
            </div>
          </FlatCard>
        </div>
      </DevToggle>
    </>
  );
}

export default Profile;
