import { UserContext } from "contexts/UserContext";
import React, { useCallback, useContext } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { isPublicRoute } from "./isPublicRoute";
import { isKasAuthFlow, isLocalHost } from "server/kas/utils";
import { KasContext, useKas } from "contexts/KasContext";
import { useAuth0 } from "@auth0/auth0-react";

const SecuredRoute: React.FC<RouteProps> = (props) => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const { isAuthenticated, me } = userContext;
  const { isAuthenticated: _auth0IsAuthenticated, user: _auth0User } =
    useAuth0?.();

  const { isUserLoggedIn: kasIsAuthenticated, user: kasUser } = useKas();

  const auth0IsAuthenticated = isKasAuthFlow
    ? kasIsAuthenticated
    : _auth0IsAuthenticated;
  const auth0User = isKasAuthFlow ? kasUser : _auth0User;

  if (isPublicRoute(location.pathname)) {
    return <Route {...props} />;
  }

  // TODO - for some reason on localhost
  // this code path gets triggered maybe 1 out
  // of 3 or 4 times when loading the app???
  // investigate after... but seems like it doesn't happen in prod
  if (!auth0IsAuthenticated && !isLocalHost) {
    return <Redirect to="/discover" />;
  }
  if (auth0IsAuthenticated && auth0User) {
    const callBack = localStorage.getItem("brev-redirect-url");
    if (callBack && callBack.includes("/login")) {
      localStorage.setItem("brev-redirect-url", "/org/environments");
    }
    return <Route {...props} />;
  }
  return <Redirect to="/discover" />;
};

export default SecuredRoute;
