import config from "config";
import { TEMPLATE_ID } from "constants/index";
import Project from "../entities/Project.entity";
import Workspace from "../entities/Workspace.entity";
import { IExec } from "models/Exec.model";
import { IRepo } from "models/Repo.model";
import { IdeConfig } from "models/User.model";
import IWorkspace, { IFileObject } from "models/Workspace.model";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import agent, {
  UpdateWorkspaceReqBody,
  CreateWorkspaceReqBody,
  WorkspaceRes,
  WorkspacesRes,
  CloneWorkspaceReqBody,
  EnvEvent,
  EnvEventArgs,
  EnvSaving,
  LaunchableFileRequest,
  LaunchableConfig,
} from "server";
import { workspaceContextDefaultValues } from "tests/defaultValues";
import { OrgContext } from "./OrgContext";
import { mapValuesToArray, replaceBlobWithRaw } from "components/utils";
import {
  CustomContainer,
  DockerCompose,
} from "components/Environment/shared/BuildTypes";
import {
  Environment,
  EnvironmentBuild,
} from "components/Environment/utils/types";
import { transformWorkspaceToEnvironment } from "components/Environment/utils/environtmentUtils";

export interface WorkspaceContextType {
  isLoading: boolean;
  environments: (Environment | null)[];
  setEnvironments: (envs: (Environment | null)[]) => void;
  clear: () => void;
  reloadEnvironments: (activeOrgId: string) => Promise<Environment[]>;
  getWorkspace: (id: string) => Promise<Environment | null>;
  envSavings: (id: string, args: EnvEventArgs) => Promise<EnvSaving | null>;
  createWorkspace: (
    name: string,
    orgId: string,
    activeWorkspaceGroupId: string,
    description?: string,
    repos?: { [key: string]: IRepo },
    execs?: { [key: string]: IExec },
    ideConfig?: IdeConfig,
    instanceType?: string,
    image?: string,
    region?: string,
    architecture?: string,
    diskStorage?: string,
    spot?: boolean,
    isStoppable?: boolean,
    onContainer?: boolean,
    verbYaml?: string,
    baseImage?: string,
    customContainer?: CustomContainer,
    vmOnlyMode?: boolean,
    portMappings?: Record<string, string> | null,
    files?: LaunchableFileRequest[] | null,
    labels?: Record<string, string> | null,
    templateConfig?: {
      isPublic: boolean;
    },
    workspaceVersion?: "v1" | "v0",
    launchJupyterOnStart?: boolean,
    dockerCompose?: DockerCompose,
    launchableConfig?: LaunchableConfig,
    firewallRules?: string[]
  ) => Promise<WorkspaceRes>;
  updateWorkspace: (
    wsid: string,
    args: UpdateWorkspaceReqBody
  ) => Promise<WorkspaceRes>;
  stopWorkspace: (id: string) => Promise<WorkspaceRes>;
  startWorkspace: (id: string) => Promise<WorkspaceRes>;
  deleteWorkspace: (id: string) => Promise<WorkspaceRes>;
  resetWorkspace: (id: string) => Promise<WorkspaceRes>;
  hardResetWorkspace: (id: string) => Promise<WorkspaceRes>;
  cloneWorkspace: (id: string, newName: string) => Promise<WorkspaceRes>;
  changeIsStoppable: (
    id: string,
    newIsStoppable: boolean
  ) => Promise<WorkspaceRes>;
  addNewPortToWorkspace: (
    id: string,
    newPortUserWantsToExpose: string
  ) => Promise<WorkspaceRes>;
  revokePortFromWorkspace: (
    id: string,
    portUserWantsToRevoke: string
  ) => Promise<WorkspaceRes>;
  shareWorkspace: (
    id: string,
    addUsers: string[],
    organizationID: string
  ) => Promise<WorkspaceRes>;
}

export const WorkspaceContext = createContext<WorkspaceContextType>(
  workspaceContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const WorkspaceContextProvider: React.FC<Props> = ({ children }) => {
  const orgContext = useContext(OrgContext);
  const [environments, setEnvironments] = useState<(Environment | null)[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Helper function to update environments list when a workspace is modified
  const updateEnvironmentsList = useCallback(
    (updatedWorkspace: IWorkspace | undefined) => {
      if (!updatedWorkspace) return;
      const updatedEnv = transformWorkspaceToEnvironment(updatedWorkspace);
      if (!updatedEnv) return;
      const otherEnvs = environments.filter(
        (env) => env && env.workspaceId !== updatedWorkspace.id
      );
      setEnvironments([...otherEnvs, updatedEnv]);
    },
    [environments]
  );

  const reloadEnvironments = async (
    activeOrgId: string
  ): Promise<Environment[]> => {
    const res = await agent.Workspaces.getAll(activeOrgId);
    if (res.success && res.data) {
      const envs = res.data
        .map(transformWorkspaceToEnvironment)
        .filter((env): env is Environment => env !== null);
      setEnvironments(envs);
      return envs;
    } else {
      console.error("Failed to reload workspaces");
      return [];
    }
  };

  const clear = (): void => {
    setEnvironments([]);
  };

  const getWorkspace = async (id: string): Promise<Environment | null> => {
    if (environments.length === 0) {
      reloadEnvironments(orgContext.activeOrgId);
      const res = await agent.Workspaces.get(id);
      if (res.success && res.data) {
        const env = transformWorkspaceToEnvironment(res.data);
        if (env) {
          setEnvironments([env]);
          return env;
        }
        return null;
      }
      return null;
    }
    const env = environments.find((w) => w && w.workspaceId === id);
    if (env) return env;
    const res = await agent.Workspaces.get(id);
    if (res.success && res.data) {
      const newEnv = transformWorkspaceToEnvironment(res.data);
      if (newEnv) {
        setEnvironments([newEnv]);
        return newEnv;
      }
      return null;
    }
    return null;
  };

  const envSavings = async (
    id: string,
    args: EnvEventArgs
  ): Promise<EnvSaving | null> => {
    const res = await agent.Workspaces.envSavings(id, args);
    if (res.success && res.data) {
      return res.data;
    }
    return null;
  };

  const createWorkspace = async (
    name: string,
    orgId: string,
    activeWorkspaceGroupId: string,
    description?: string,
    repos?: { [key: string]: IRepo },
    execs?: { [key: string]: IExec },
    ideConfig?: IdeConfig,
    instanceType?: string,
    image?: string,
    region?: string,
    architecture?: string,
    diskStorage?: string,
    spot?: boolean,
    isStoppable?: boolean,
    onContainer?: boolean,
    verbYaml?: string,
    baseImage?: string,
    customContainer?: CustomContainer,
    vmOnlyMode?: boolean,
    portMappings?: Record<string, string> | null,
    files?: LaunchableFileRequest[] | null,
    labels?: Record<string, string> | null,
    templateConfig?: {
      isPublic: boolean;
    },
    workspaceVersion?: "v1" | "v0",
    launchJupyterOnStart?: boolean,
    dockerCompose?: DockerCompose,
    launchableConfig?: LaunchableConfig,
    firewallRules?: string[]
  ) => {
    const workspaceTemplateId = TEMPLATE_ID;
    const args: CreateWorkspaceReqBody = {
      name,
      workspaceGroupId: activeWorkspaceGroupId, // config.workspaceGroupID,
      workspaceTemplateId,
      description,
      isStoppable,
      ...(repos ? { reposV1: repos } : {}),
      ...(execs ? { execsV1: execs } : {}),
      ...(ideConfig ? { ideConfig } : {}),
      instanceType,
      ...(image ? { image } : {}),
      ...(region ? { region } : {}),
      ...(architecture ? { architecture } : {}),
      ...(diskStorage ? { diskStorage: `${diskStorage}Gi` } : {}),
      ...(spot ? { spot } : {}),
      ...(onContainer ? { onContainer } : {}),
      ...(verbYaml ? { verbYaml } : {}),
      baseImage,
      customContainer,
      vmOnlyMode,
      portMappings,
      files,
      labels,
      workspaceVersion,
      launchJupyterOnStart,
      dockerCompose,
      launchableConfig,
      exposedPorts: firewallRules,
    };
    const res = await agent.Workspaces.create(orgId, args);
    if (res.success && res.data) {
      if (environments.length > 0) {
        updateEnvironmentsList(res.data);
      } else {
        reloadEnvironments(orgContext.activeOrgId);
      }

      agent.Organizations.createTemplate(orgId, {
        isPublic: !!templateConfig?.isPublic,
        workspaceRequest: args,
      });
    }
    return res;
  };

  const updateWorkspace = async (
    wsid: string,
    args: UpdateWorkspaceReqBody
  ) => {
    const res = await agent.Workspaces.update(wsid, args);
    if (res.success && res.data) {
      reloadEnvironments(orgContext.activeOrgId);
    }
    return res;
  };

  const stopWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.stop(id);
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const startWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.start(id);
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const deleteWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.delete(id);
    if (res.success && res.data) {
      setEnvironments(
        environments.filter((env) => env && env.workspaceId !== res.data?.id)
      );
    }
    return res;
  };

  const resetWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.reset(id);
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const hardResetWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.hardReset(id);
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const cloneWorkspace = async (
    id: string,
    newName: string
  ): Promise<WorkspaceRes> => {
    const args: CloneWorkspaceReqBody = {
      name: newName,
    };
    const res = await agent.Workspaces.clone(id, args);
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const changeIsStoppable = async (
    id: string,
    isStoppable: boolean
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { isStoppable });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const updateVSCodeExtensionsToWorkspaceTemplate = async (
    id: string,
    ideConfig: IdeConfig
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { ideConfig });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const updateRepoConfigToWorkspaceTemplate = async (
    id: string,
    repos: { [key: string]: IRepo }
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { reposV1: repos });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const updateExecConfigToWorkspaceTemplate = async (
    id: string,
    execs: { [key: string]: IExec }
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { execsV1: execs });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const updateStartupScriptPath = async (
    id: string,
    startupScriptPath: string
  ) => {
    const res = await agent.Workspaces.update(id, { startupScriptPath });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const addNewPortToWorkspace = async (
    id: string,
    newPortUserWantsToExpose: string
  ) => {
    const res = await agent.Workspaces.update(id, {
      addPort: newPortUserWantsToExpose,
    });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const revokePortFromWorkspace = async (
    id: string,
    portUserWantsToRevoke: string
  ) => {
    const res = await agent.Workspaces.update(id, {
      revokePort: portUserWantsToRevoke.toString(),
    });
    if (res.success && res.data) {
      updateEnvironmentsList(res.data);
    }
    return res;
  };

  const shareWorkspace = async (
    id: string,
    addUsers: string[],
    organizationID: string
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.shareWorkspace(id, {
      addUsers,
      organizationID,
    });
    return res;
  };

  const providerData = {
    isLoading,
    environments,
    setEnvironments,
    reloadEnvironments,
    getWorkspace,
    clear,
    stopWorkspace,
    startWorkspace,
    deleteWorkspace,
    resetWorkspace,
    hardResetWorkspace,
    cloneWorkspace,
    createWorkspace,
    updateWorkspace,
    changeIsStoppable,
    updateVSCodeExtensionsToWorkspaceTemplate,
    updateRepoConfigToWorkspaceTemplate,
    updateExecConfigToWorkspaceTemplate,
    updateStartupScriptPath,
    addNewPortToWorkspace,
    revokePortFromWorkspace,
    shareWorkspace,
    envSavings,
  };

  return (
    <WorkspaceContext.Provider value={providerData}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceContextProvider;
