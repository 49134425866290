import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import { classNames } from "components/utils";
import { OrgContext } from "contexts/OrgContext";
import Workspace from "entities/Workspace.entity";
import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Chip from "../Create/chips";
import { DarkModeContext } from "contexts/DarkModeContext";
import {
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "../Settings/utils";
import InstanceStatusIndicator from "./InstanceStatusIndicator";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import ConstanterStatusIndicator from "./ContainerStatusIndicator";
import { highlight } from "prismjs";
import {
  ContainerSelected,
  CustomContainer,
  DockerCompose,
  parseDockerComposeUrl,
} from "./BuildTypes";
import Modal from "components/Modals/Modal";
import { EnvironmentBuild } from "../utils/types";

interface ContainerOverviewProps {
  instanceStatus: WorkspaceStatus;
  containerStatus: VerbBuildStatus;
  build: EnvironmentBuild;
}
const ContainerOverview: React.FC<ContainerOverviewProps> = (props) => {
  const verbBuild = getVerbBuildConfigFromWorkspace(props.build.verb?.config);

  const showContainer = () => {
    if (props.build.vm) {
      return (
        <div className="flex flex-row justify-center items-center">
          <span className="block truncate text-xs font-medium">VM Mode</span>
        </div>
      );
    }

    if (props.build.container) {
      return (
        <div>
          <span className="block truncate text-xs font-medium">
            {props.build.container?.base_image}
          </span>
        </div>
      );
    }

    if (props.build.docker_compose) {
      return (
        <div>
          {props.build.docker_compose?.yaml_string ? (
            <DockerComposeYAML
              yamlString={props.build.docker_compose?.yaml_string || ""}
            />
          ) : (
            <a
              href={props.build.docker_compose?.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="block truncate text-xs font-medium hover:underline hover:text-highlight cursor-pointer">
                {parseDockerComposeUrl(
                  props.build.docker_compose?.file_url || ""
                )}
              </span>
            </a>
          )}
        </div>
      );
    }

    if (verbBuild) {
      if (verbBuild && verbBuild.base_image) {
        return (
          <div>
            <span className="block truncate text-xs font-medium">
              {verbBuild.base_image}
            </span>
          </div>
        );
      } else {
        return (
          <>
            <div className="flex items-center text-xs mt-1">
              <div className="w-[24px] flex flex-row justify-center items-center mr-1">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 112 113"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M54.9188 0.000919274C50.3351 0.0222173 45.9578 0.413137 42.1063 1.09467C30.7601 3.09917 28.7 7.29477 28.7 15.0322V25.2509H55.5125V28.6572H28.7H18.6375C10.8451 28.6572 4.02178 33.3409 1.88754 42.2509C-0.574285 52.4639 -0.68348 58.8369 1.88754 69.5009C3.79346 77.4388 8.34508 83.0947 16.1375 83.0947H25.3563V70.8447C25.3563 61.9948 33.0134 54.1884 42.1063 54.1884H68.8875C76.3425 54.1884 82.2938 48.0503 82.2938 40.5634V15.0322C82.2938 7.76583 76.1638 2.30739 68.8875 1.09467C64.2815 0.327944 59.5024 -0.020379 54.9188 0.000919274ZM40.4188 8.21967C43.1883 8.21967 45.45 10.5183 45.45 13.3447C45.45 16.161 43.1883 18.4384 40.4188 18.4384C37.6393 18.4384 35.3875 16.161 35.3875 13.3447C35.3875 10.5183 37.6393 8.21967 40.4188 8.21967Z" />
                  <path d="M85.6375 28.6572V40.5634C85.6375 49.7942 77.8116 57.5634 68.8875 57.5634H42.1063C34.7704 57.5634 28.7 63.8419 28.7 71.1884V96.7197C28.7 103.986 35.0186 108.26 42.1063 110.345C50.5936 112.84 58.7325 113.291 68.8875 110.345C75.6377 108.39 82.2938 104.457 82.2938 96.7197V86.5009H55.5125V83.0947H82.2938H95.7C103.492 83.0947 106.396 77.6593 109.106 69.5009C111.906 61.102 111.786 53.0251 109.106 42.2509C107.18 34.4935 103.502 28.6572 95.7 28.6572H85.6375ZM70.575 93.3134C73.3545 93.3134 75.6063 95.5908 75.6063 98.4072C75.6063 101.234 73.3545 103.532 70.575 103.532C67.8055 103.532 65.5438 101.234 65.5438 98.4072C65.5438 95.5908 67.8055 93.3134 70.575 93.3134Z" />
                </svg>
              </div>
              Python version: {verbBuild?.python_version || "3.10"}
            </div>
            <div className={classNames("flex items-center text-xs mt-1")}>
              <svg
                width="24"
                height="16"
                viewBox="0 0 249 165"
                className="mr-1"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
              </svg>
              CUDA version: {verbBuild?.cuda || "12.0.1"}
            </div>
          </>
        );
      }
    }
    return null;
  };

  return (
    <div
      className={classNames(
        "flex flex-col",
        props.instanceStatus === WorkspaceStatus.Running &&
          props.containerStatus !== VerbBuildStatus.Unset
          ? "on"
          : "dark:text-secondary"
      )}
    >
      <div className="flex flex-row mb-2 items-center">
        <RectangleGroupIcon className="h-6 w-6 mr-1" />
        <span className="text-sm mr-3">Container</span>
        <ConstanterStatusIndicator
          instanceStatus={props.instanceStatus}
          containerStatus={props.containerStatus}
        />
      </div>
      <div
        className={classNames(
          "flex flex-col border border-dotted border-2 p-2",
          props.instanceStatus === WorkspaceStatus.Running &&
            props.containerStatus !== VerbBuildStatus.Unset
            ? ""
            : "border-secondary"
        )}
      >
        {showContainer()}
      </div>
    </div>
  );
};

export default ContainerOverview;

interface SimplifiedContainerOverviewProps {
  environmentBuild?: EnvironmentBuild;
}
export const SimplifiedContainerOverview: React.FC<
  SimplifiedContainerOverviewProps
> = (props) => {
  const verbBuild = getVerbBuildConfigFromWorkspace(
    props.environmentBuild?.verb?.config
  );

  console.log(`
  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  
  ${JSON.stringify(verbBuild)}
  
  
  %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  
  `);

  if (props.environmentBuild?.vm) {
    return (
      <div
        className={classNames(
          "flex flex-col border border-dotted border-2 p-1 border-secondary text-secondary"
        )}
      >
        <div>
          <span className="block truncate text-xs font-medium">VM Mode</span>
        </div>
      </div>
    );
  }

  if (props.environmentBuild?.docker_compose) {
    return (
      <div
        className={classNames(
          "flex flex-col border border-dotted border-2 p-1 border-secondary text-secondary"
        )}
      >
        {props.environmentBuild?.docker_compose?.yaml_string ? (
          <DockerComposeYAML
            yamlString={
              props.environmentBuild?.docker_compose?.yaml_string || ""
            }
          />
        ) : (
          <div>
            <a
              href={props.environmentBuild.docker_compose?.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="block truncate text-xs font-medium hover:underline hover:text-highlight cursor-pointer">
                {parseDockerComposeUrl(
                  props.environmentBuild.docker_compose?.file_url || ""
                )}
              </span>
            </a>
          </div>
        )}
      </div>
    );
  }

  if (props.environmentBuild?.container) {
    return (
      <div
        className={classNames(
          "flex flex-col border border-dotted border-2 p-1 border-secondary text-secondary"
        )}
      >
        <div>
          <span className="block truncate text-xs font-medium">
            {props.environmentBuild?.container?.base_image}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          "flex flex-col border border-dotted border-2 p-1 border-secondary text-secondary"
        )}
      >
        {verbBuild && verbBuild.base_image ? (
          <div>
            <span className="block truncate text-xs font-medium">
              {verbBuild.base_image}
            </span>
          </div>
        ) : (
          <div className="flex flex-row">
            <div className="flex items-center text-xs mt-1 mr-2">
              <div className="w-[24px] flex flex-row justify-center items-center mr-1">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 112 113"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M54.9188 0.000919274C50.3351 0.0222173 45.9578 0.413137 42.1063 1.09467C30.7601 3.09917 28.7 7.29477 28.7 15.0322V25.2509H55.5125V28.6572H28.7H18.6375C10.8451 28.6572 4.02178 33.3409 1.88754 42.2509C-0.574285 52.4639 -0.68348 58.8369 1.88754 69.5009C3.79346 77.4388 8.34508 83.0947 16.1375 83.0947H25.3563V70.8447C25.3563 61.9948 33.0134 54.1884 42.1063 54.1884H68.8875C76.3425 54.1884 82.2938 48.0503 82.2938 40.5634V15.0322C82.2938 7.76583 76.1638 2.30739 68.8875 1.09467C64.2815 0.327944 59.5024 -0.020379 54.9188 0.000919274ZM40.4188 8.21967C43.1883 8.21967 45.45 10.5183 45.45 13.3447C45.45 16.161 43.1883 18.4384 40.4188 18.4384C37.6393 18.4384 35.3875 16.161 35.3875 13.3447C35.3875 10.5183 37.6393 8.21967 40.4188 8.21967Z" />
                  <path d="M85.6375 28.6572V40.5634C85.6375 49.7942 77.8116 57.5634 68.8875 57.5634H42.1063C34.7704 57.5634 28.7 63.8419 28.7 71.1884V96.7197C28.7 103.986 35.0186 108.26 42.1063 110.345C50.5936 112.84 58.7325 113.291 68.8875 110.345C75.6377 108.39 82.2938 104.457 82.2938 96.7197V86.5009H55.5125V83.0947H82.2938H95.7C103.492 83.0947 106.396 77.6593 109.106 69.5009C111.906 61.102 111.786 53.0251 109.106 42.2509C107.18 34.4935 103.502 28.6572 95.7 28.6572H85.6375ZM70.575 93.3134C73.3545 93.3134 75.6063 95.5908 75.6063 98.4072C75.6063 101.234 73.3545 103.532 70.575 103.532C67.8055 103.532 65.5438 101.234 65.5438 98.4072C65.5438 95.5908 67.8055 93.3134 70.575 93.3134Z" />
                </svg>
              </div>
              Python version: {verbBuild?.python_version || "3.10"}
            </div>
            <div className={classNames("flex items-center text-xs mt-1")}>
              <svg
                width="24"
                height="16"
                viewBox="0 0 249 165"
                className="mr-1"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
              </svg>
              CUDA version: {verbBuild?.cuda || "12.0.1"}
            </div>
          </div>
        )}
      </div>
    );
  }
};

interface SimpleContainerOverviewProps {
  pythonVersion?: string;
  cudaVersion?: string;
  containerSelected?: ContainerSelected | null;
  highlight?: boolean;
  className?: string;
}
export const SimpleContainerOverview: React.FC<SimpleContainerOverviewProps> = (
  props
) => {
  const showContainer = () => {
    console.log("containerSelected0--------", props.containerSelected);
    if (props.containerSelected?.verbBuild || !props.containerSelected) {
      if (
        props.containerSelected?.verbBuild?.containerUrl !== "Default" &&
        props.containerSelected
      ) {
        return (
          <div>
            <span className="block truncate text-xs font-medium">
              {props.containerSelected?.verbBuild?.containerUrl}
            </span>
          </div>
        );
      } else {
        return (
          <div className="flex flex-row">
            <div className="flex items-center text-xs mt-1 mr-2">
              <div className="w-[24px] flex flex-row justify-center items-center mr-1">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 112 113"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M54.9188 0.000919274C50.3351 0.0222173 45.9578 0.413137 42.1063 1.09467C30.7601 3.09917 28.7 7.29477 28.7 15.0322V25.2509H55.5125V28.6572H28.7H18.6375C10.8451 28.6572 4.02178 33.3409 1.88754 42.2509C-0.574285 52.4639 -0.68348 58.8369 1.88754 69.5009C3.79346 77.4388 8.34508 83.0947 16.1375 83.0947H25.3563V70.8447C25.3563 61.9948 33.0134 54.1884 42.1063 54.1884H68.8875C76.3425 54.1884 82.2938 48.0503 82.2938 40.5634V15.0322C82.2938 7.76583 76.1638 2.30739 68.8875 1.09467C64.2815 0.327944 59.5024 -0.020379 54.9188 0.000919274ZM40.4188 8.21967C43.1883 8.21967 45.45 10.5183 45.45 13.3447C45.45 16.161 43.1883 18.4384 40.4188 18.4384C37.6393 18.4384 35.3875 16.161 35.3875 13.3447C35.3875 10.5183 37.6393 8.21967 40.4188 8.21967Z" />
                  <path d="M85.6375 28.6572V40.5634C85.6375 49.7942 77.8116 57.5634 68.8875 57.5634H42.1063C34.7704 57.5634 28.7 63.8419 28.7 71.1884V96.7197C28.7 103.986 35.0186 108.26 42.1063 110.345C50.5936 112.84 58.7325 113.291 68.8875 110.345C75.6377 108.39 82.2938 104.457 82.2938 96.7197V86.5009H55.5125V83.0947H82.2938H95.7C103.492 83.0947 106.396 77.6593 109.106 69.5009C111.906 61.102 111.786 53.0251 109.106 42.2509C107.18 34.4935 103.502 28.6572 95.7 28.6572H85.6375ZM70.575 93.3134C73.3545 93.3134 75.6063 95.5908 75.6063 98.4072C75.6063 101.234 73.3545 103.532 70.575 103.532C67.8055 103.532 65.5438 101.234 65.5438 98.4072C65.5438 95.5908 67.8055 93.3134 70.575 93.3134Z" />
                </svg>
              </div>
              Python version: {props.pythonVersion || "3.10"}
            </div>
            <div className={classNames("flex items-center text-xs mt-1")}>
              <svg
                width="24"
                height="16"
                viewBox="0 0 249 165"
                className="mr-1"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
              </svg>
              CUDA version: {props.cudaVersion || "12.0.1"}
            </div>
          </div>
        );
      }
    }
    if (props.containerSelected?.vmBuild) {
      return (
        <div>
          <span className="block truncate text-xs font-medium">VM Mode</span>
        </div>
      );
    }

    if (props.containerSelected?.customContainer) {
      return (
        <div>
          <span className="block truncate text-xs font-medium">
            {props.containerSelected?.customContainer.containerUrl}
          </span>
        </div>
      );
    }

    if (props.containerSelected?.dockerCompose) {
      return (
        <div>
          {props.containerSelected?.dockerCompose?.fileUrl ? (
            <a
              href={props.containerSelected?.dockerCompose?.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="block truncate text-xs font-medium hover:underline hover:text-highlight cursor-pointer">
                {parseDockerComposeUrl(
                  props.containerSelected?.dockerCompose?.fileUrl
                )}
              </span>
            </a>
          ) : (
            <DockerComposeYAML
              yamlString={
                props.containerSelected?.dockerCompose?.yamlString || ""
              }
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={classNames(
        "flex flex-col border border-dotted border-2 p-1 w-max",
        props.className ? props.className : ""
      )}
    >
      {showContainer()}
    </div>
  );
};

const DockerComposeYAML: React.FC<{
  yamlString: string;
}> = (props) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  return (
    <>
      <Modal
        setOpen={setEditModalOpen}
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title=""
        width={"max-w-7xl"}
        hideCancel={true}
        hideSuccess={true}
        body={
          <>
            <div className="flex flex-col text-white">
              <pre className="text-xs font-mono whitespace-pre-wrap overflow-x-auto">
                {props.yamlString}
              </pre>
            </div>
          </>
        }
      />
      <div>
        <span
          className="block truncate text-xs font-medium hover:underline hover:text-highlight cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            setEditModalOpen(true);
          }}
        >
          Uploaded Docker Compose YAML
        </span>
      </div>
    </>
  );
};
