import React, { useContext, useMemo, useState } from "react";
import {
  Capability,
  changeInstanceTypeCapability,
  changeSpotInstanceTypeCapability,
  modifyFirewallCapability,
  resizeCapability,
} from "contexts/OrgContext";
import InstanceChanger from "./InstanceChanger";
import VolumeChanger from "./VolumeChanger";
import Workspace from "entities/Workspace.entity";
import { FlatCard } from "components/UI-lib";
import { Environment } from "components/Environment/utils/types";

interface ComputeSettingsProps {
  environment: Environment;
  workspaceCapabilities: Capability[];
}

const ComputeSettings: React.FC<ComputeSettingsProps> = ({
  environment,
  workspaceCapabilities,
}) => {
  // Memoize the instance modification check
  const instanceCanBeModified = useMemo(
    () =>
      workspaceCapabilities.includes(changeInstanceTypeCapability) &&
      workspaceCapabilities.includes(changeSpotInstanceTypeCapability),
    [workspaceCapabilities]
  );

  // Memoize the scaling capability check
  const instanceCanScale = useMemo(
    () => workspaceCapabilities.includes(resizeCapability),
    [workspaceCapabilities]
  );

  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Change Hardware
          </h1>
        </div>
      </div>
      <hr className="w-full my-3 dark:border dark:border-zinc-800" />
      <div className="mt-8">
        {instanceCanBeModified ? (
          <InstanceChanger
            environment={environment}
            instanceCantBeModified={!instanceCanBeModified}
            cantBeModifiedMessage={
              "This workspace is not running on a scalable instance so you can't modify its hardware"
            }
          />
        ) : (
          <FlatCard>
            <span className={"text-rose-500 text-sm font-mono"}>
              This workspace is not running on a scalable instance so you can't
              modify its hardware
            </span>
          </FlatCard>
        )}
      </div>
      <div className="flex flex-row items-end mt-5">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Scalable Storage
          </h1>
        </div>
      </div>
      <hr className="w-full my-3 dark:border dark:border-zinc-800" />
      <div className="mt-8">
        {instanceCanScale ? (
          <VolumeChanger
            environment={environment}
            capabilities={workspaceCapabilities}
          />
        ) : (
          <FlatCard>
            <span className={"text-rose-500 text-sm font-mono"}>
              This cloud provider doesn't allow this instance to resize its
              storage
            </span>
          </FlatCard>
        )}
      </div>
    </>
  );
};

export default ComputeSettings;
