import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import agent, {
  CreateLaunchableRequest,
  LaunchableBuildRequest,
  LaunchableCreateWorkspaceRequest,
  LaunchableFileRequest,
  LaunchablePort,
} from "server";
import { timeout, isValidFileUrl } from "components/utils";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import { Transition } from "@headlessui/react";
import ComputePicker from "components/Environment/Create/ComputePicker";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import ContainerPicker, { Container } from "../../Create/ContainerPicker";
import NotebookUploader from "../../Create/NotebookUploader";
import ExposePorts, {
  FirewallRule,
  Tunnel,
} from "../../Create/ExposePorts/index";
import {
  createFileRequest,
  isEmptyContainer,
  transformPortListToMap,
} from "../utils";
import BuilderView from "./BuilderView";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import DevToggle from "components/DevToggle";
import Checkbox from "components/UI-lib/Checkbox";
import ContainerSelector from "components/Environment/Create/ContainerSelector";
import {
  ContainerSelected,
  isContainerEquals,
} from "components/Environment/shared/BuildTypes";
import ContainerSelectorWrapper from "./ContainerSelectorWrapper";
import {
  hasVerbBaseImageContainer,
  usesVerbYaml,
} from "components/Environment/Create/utils";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import {
  CONSOLE_1_AUTH0_DEPLOYMENT_URL,
  CONSOLE_1_KAS_DEPLOYMENT_URL,
  isKasAuthFlow,
} from "server/kas/utils";

export interface LaunchableExposePorts {
  tunnels: Tunnel[];
  firewallRules: FirewallRule[];
}

export const LaunchablesRedirect: React.FC = () => {
  console.log("Route: /org/launchables");

  const router = useHistory();
  const orgContext = useContext(OrgContext);
  useEffect(() => {
    router.push(`/org/${orgContext.activeOrgId}/launchables`);
  }, [orgContext.activeOrgId]);

  return <></>;
};

const Launchables: React.FC = () => {
  console.log("Route: /launchables/create");

  const userContext = useContext(UserContext);
  const history = useHistory();

  const [errorCreatingLaunchable, setErrorCreatingLaunchable] =
    useState<string>("");
  // Display a section
  const [showCompute, setShowCompute] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const [showNotebook, setShowNotebook] = useState(false);
  //   The url params

  //Animation State
  const [clickedGenerate, setClickedGenerate] = useState(false);

  const [animateCompute, setAnimateCompute] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [animatePorts, setAnimatePorts] = useState(false);
  const [animateNotebook, setAnimateNotebook] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  //  The url input
  const [lauchableURL, setLaunchableURL] = useState("");
  const [showInput, setShowInput] = useState(false);

  const orgContext = useContext(OrgContext);
  const [name, setName] = useState<string>("");

  //Compute Picker
  const [instance, setInstance] = useState<GPUInstanceType | undefined>(
    undefined
  );
  const [storage, setStorage] = useState<string>("500");
  const [savedInstance, setSavedInstance] = useState<
    GPUInstanceType | undefined
  >();
  const [savedStorage, setSavedStorage] = useState<string>("500");

  // New state for Secret Keys
  const [showSecretKeys, setShowSecretKeys] = useState(false);
  const [requiredSecretKeys, setRequiredSecretKeys] = useState({
    "NGC Key": false,
    "HuggingFace Key": false,
  });
  const [savedRequiredSecretKeys, setSavedRequiredSecretKeys] = useState({
    "NGC Key": false,
    "HuggingFace Key": false,
  });

  const handleComputeSave = () => {
    setSavedInstance(instance);
    if (instance?.elastic_root_volume) {
      setSavedStorage(storage);
    }
    setShowCompute(false);
  };

  // Container Picker
  const [container, setContainer] = useState<ContainerSelected>();
  const [pythonVersion, setPythonVersion] = useState<string>("3.10");
  const [cudaVersion, setCudaVersion] = useState<string>("12.0.1");

  const [savedContainer, setSavedContainer] = useState<ContainerSelected>();

  const handleContainerSave = () => {
    setSavedContainer(container);
    setShowContainer(false);
  };

  // Notebook Uploader

  const [notebookFile, setNotebookFile] = useState<string>("");
  const [savedFile, setFile] = useState<string>("");
  const [isFileValid, setIsFileValid] = useState<boolean>(true);

  const isValidFile = async (file: string): Promise<boolean> => {
    const errorMessage = await isValidFileUrl(file);
    return !errorMessage;
  };

  const handleNotebookSave = async () => {
    const validCheck = await isValidFile(notebookFile);
    if (!validCheck && notebookFile !== "") {
      setIsFileValid(false);
      return;
    }
    setFile(notebookFile);
    if (notebookFile === "") {
      // setNotebookStr("");
      setShowNotebook(false);
      return;
    }
    // setNotebookStr(`file=${encodeURIComponent(notebookFile)}`);
    setShowNotebook(false);
  };

  useEffect(() => {
    document.title = "Launchables | Brev.dev";
  }, []);

  // Expose Ports
  // Ports are tunnels
  const [exposedPorts, setExposedPorts] = useState<LaunchableExposePorts>({
    tunnels: [],
    firewallRules: [],
  });
  const [savedExposedPorts, setSavedExposedPorts] =
    useState<LaunchableExposePorts>({
      tunnels: [],
      firewallRules: [],
    });

  const handlePortSave = () => {
    setSavedExposedPorts(exposedPorts);
    if (
      exposedPorts.tunnels.length === 0 &&
      exposedPorts.firewallRules.length === 0
    ) {
      setShowPorts(false);
      return;
    }
    setShowPorts(false);
  };

  const handleSetActive = (activeType: string) => {
    setShowCompute(activeType === "compute");
    setShowContainer(activeType === "container");
    setShowPorts(activeType === "ports");
    setShowNotebook(activeType === "notebook");
    setShowSecretKeys(activeType === "secretKeys");
  };

  const createLaunchable = async (
    orgId: string,
    createLaunchableRequest: CreateLaunchableRequest
  ) => {
    const res = await agent.Launchables.createEnvironmentLaunchable(
      orgId,
      createLaunchableRequest
    );
    if (res.success && res.data) {
      return res.data;
    } else {
      return null;
    }
  };

  const makeCreateLaunchableRequest = (
    instance: GPUInstanceType,
    pythonVersion: string,
    cudaVersion: string,
    container: ContainerSelected,
    file: string,
    exposedPorts: LaunchableExposePorts
  ): CreateLaunchableRequest => {
    //Worksake Request
    const createWorkspaceRequest: LaunchableCreateWorkspaceRequest = {
      instanceType: instance.type,
      workspaceGroupId: instance.workspace_groups[0].id,
      storage: instance.elastic_root_volume ? storage : "",
      exposedPorts: exposedPorts.firewallRules.map((rule) =>
        rule.port.toString()
      ),
    };

    const buildRequest: LaunchableBuildRequest = {
      ports: exposedPorts.tunnels.map((tunnel) => ({
        name: tunnel.name,
        port: tunnel.port.toString(),
      })),
    };

    if (container.vmBuild) {
      // Build Request
      buildRequest.vmBuild = {
        forceJupyterInstall: !!container.vmBuild?.forceJupyterInstall,
      };
    }

    let jupyterPort = 8888;

    if (name.includes("blueprint") || name.includes("Blueprint")) {
      jupyterPort = 8887;
    }

    console.log("jupyterPort at the start", jupyterPort);

    if (!!container.verbBuild) {
      const customYaml = buildDefaultVerbYamlConfig(
        hasVerbBaseImageContainer(container)
          ? container.verbBuild?.containerUrl
          : "",
        transformPortListToMap(
          exposedPorts.tunnels.map((tunnel) => ({
            name: tunnel.name,
            port: tunnel.port.toString(),
          }))
        ),
        cudaVersion,
        pythonVersion,
        jupyterPort,
        true
      );
      buildRequest.verbBuild = {
        verbYaml: customYaml,
      };
    }

    if (!!container.customContainer) {
      buildRequest.containerBuild = {
        containerUrl: container.customContainer.containerUrl,
        entryPoint: container.customContainer.entryPoint,
      };
    }

    if (!!container.dockerCompose) {
      buildRequest.dockerCompose = {
        fileUrl: container.dockerCompose.fileUrl,
        jupyterInstall: container.dockerCompose.jupyterInstall,
        yamlString: container.dockerCompose.yamlString,
        registries: container.dockerCompose.registries,
      };
    }

    // File Request
    let launchableFile: LaunchableFileRequest | null = null;
    const fileRequest = createFileRequest(file, container);
    if (fileRequest) {
      launchableFile = fileRequest[0];
    }

    return {
      name,
      createWorkspaceRequest: createWorkspaceRequest,
      buildRequest: buildRequest,
      file: launchableFile,
    };
  };

  const handleSecretKeysSave = () => {
    setSavedRequiredSecretKeys(requiredSecretKeys);
    setShowSecretKeys(false);
    // Encode required secret keys for URL
    const encodedRequiredSecretKeys = Object.entries(requiredSecretKeys)
      .filter(([_, isRequired]) => isRequired)
      .map(([key]) => encodeURIComponent(key))
      .join(",");
  };

  const resetSecretKeys = () => {
    setRequiredSecretKeys({
      "NGC Key": false,
      "HuggingFace Key": false,
    });
    setSavedRequiredSecretKeys({
      "NGC Key": false,
      "HuggingFace Key": false,
    });
  };

  //Reset
  const reset = () => {
    setInstance(undefined);
    setStorage("120");
    setContainer(undefined);
    setPythonVersion("3.10");
    setCudaVersion("12.0.1");
    setNotebookFile("");
    setExposedPorts({ tunnels: [], firewallRules: [] });
    setSavedInstance(undefined);
    setSavedStorage("120");
    setSavedContainer(undefined);
    setFile("");
    setSavedExposedPorts({ tunnels: [], firewallRules: [] });
    setShowCompute(false);
    setShowContainer(false);
    setShowPorts(false);
    setShowNotebook(false);
    resetAnimation();
    setClickedGenerate(false);
    setName("");
    resetSecretKeys();
  };

  useEffect(() => {
    document.title = "Launchables | Brev.dev";
  }, []);

  const handleGenerateClick = async () => {
    if (!savedInstance) {
      return;
    }

    resetAnimation();
    setClickedGenerate(true);
    setErrorCreatingLaunchable("");
    const steps: any[] = [];
    // Default Steps
    steps.push(() => setAnimateCompute(true));
    steps.push(() => setAnimateContainer(true));

    if (savedFile) {
      steps.push(() => setAnimateNotebook(true));
    }
    if (
      savedExposedPorts.tunnels.length > 0 ||
      savedExposedPorts.firewallRules.length > 0
    ) {
      steps.push(() => setAnimatePorts(true));
    }
    for (let i = 0; i < steps.length; i++) {
      steps[i]();
      await timeout(500);
    }

    const createLaunchableRequest = makeCreateLaunchableRequest(
      savedInstance,
      pythonVersion,
      cudaVersion,
      savedContainer || {
        verbBuild: {
          containerUrl: "Default",
        },
      },
      savedFile,
      savedExposedPorts
    );

    const res = await createLaunchable(
      orgContext.activeOrgId,
      createLaunchableRequest
    );
    if (!res) {
      setErrorCreatingLaunchable(
        "Error creating launchable, contact support or try again"
      );
      setClickedGenerate(false);
      resetAnimation();
      return;
    }
    const createdLaunchableURL = `${
      isKasAuthFlow
        ? CONSOLE_1_KAS_DEPLOYMENT_URL
        : CONSOLE_1_AUTH0_DEPLOYMENT_URL
    }/launchable/deploy?launchableID=${encodeURIComponent(res.id)}`;
    setLaunchableURL(createdLaunchableURL);
    const extraEventProperties = {
      launchableID: res.id,
      launchableInstanceType: savedInstance.type,
      workspaceGroupID: savedInstance.workspace_groups[0].id,
      launchableCreatedByUserID: userContext.me?.id,
      launchableCreatedByOrgID: orgContext.activeOrgId,
      launchableRawURL: createdLaunchableURL,
    };
    agent.Brevent.track({
      eventName: "Launchable Created",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
        noteBookName: name,
        ...extraEventProperties,
      },
    });
    setAnimationCompleted(true);
  };

  const resetAnimation = () => {
    setAnimateCompute(false);
    setAnimateContainer(false);
    setAnimatePorts(false);
    setAnimateNotebook(false);
    setAnimationCompleted(false);
  };

  if (orgContext.activeOrg) {
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-5">
        <nav className="flex mb-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a
                  onClick={() => {
                    history.push(`/org/${orgContext.activeOrgId}/launchables`);
                  }}
                  className="text-sm font-medium text-gray-500 dark:text-secondary hover:text-gray-700 dark:hover:text-gray-300 cursor-pointer"
                >
                  Launchables
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <a className="ml-4 text-sm font-medium text-gray-500 dark:text-secondary">
                  Create
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <div className="flex flex-col justify-evenly items-center">
          <div className="mt-3"></div>
          <div className="w-full">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              Create Launchable
            </h1>
          </div>
          <div className="mt-3"></div>

          <div className="w-full flex flex-row justify-between">
            <p className="text-gray-500 dark:text-gray-400">
              Configure 1-Click Launchables to share your environment from the
              software down to the hardware
            </p>
            <div className="flex flex-row w-[250px] justify-end">
              <Button
                label="Reset Configurations"
                className="ml-2"
                type="secondary"
                onClick={() => {
                  reset();
                }}
              />
            </div>
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-row w-full">
            <BuilderView
              clickedGenerate={clickedGenerate}
              launchableURL={lauchableURL}
              savedInstance={savedInstance}
              savedStorage={savedStorage}
              savedContainer={savedContainer}
              pythonVersion={pythonVersion}
              cudaVersion={cudaVersion}
              savedFile={savedFile}
              savedExposePorts={savedExposedPorts}
              animateCompute={animateCompute}
              animateContainer={animateContainer}
              animatePorts={animatePorts}
              animateNotebook={animateNotebook}
              animationCompleted={animationCompleted}
            />
          </div>
          <div className="flex flex-col w-full mb-5 mt-2 items-start">
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-col items-end">
                {!animationCompleted && (
                  <>
                    <div className="w-full flex flex-row justify-between mb-3 mt-2">
                      <div className="flex flex-row">
                        <p className="text-sm font-medium text-gray-700 dark:text-secondary">
                          Add configurations to the 1-click launchable:
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {!animationCompleted && (
                  <div className="flex flex-row justify-start items-end w-full">
                    <DynamicBadge
                      className="cursor-pointer mr-3"
                      text="Compute"
                      isActive={!!savedInstance}
                      pending={showCompute}
                      setIsActive={() => {
                        setInstance(savedInstance);
                        setStorage(savedStorage);
                        handleSetActive("compute");
                      }}
                    />
                    <DynamicBadge
                      className="cursor-pointer mr-3"
                      text="Container"
                      isActive={!!savedContainer}
                      pending={showContainer}
                      setIsActive={() => {
                        setContainer(savedContainer);
                        handleSetActive("container");
                      }}
                    />
                    <DynamicBadge
                      className="cursor-pointer mr-3"
                      text="Files"
                      isActive={!!savedFile}
                      pending={showNotebook}
                      setIsActive={() => {
                        setNotebookFile(savedFile);
                        handleSetActive("notebook");
                      }}
                    />
                    <DynamicBadge
                      className="cursor-pointer mr-3"
                      text="Expose Ports"
                      isActive={
                        savedExposedPorts.tunnels.length > 0 ||
                        savedExposedPorts.firewallRules.length > 0
                      }
                      pending={showPorts}
                      setIsActive={() => {
                        setExposedPorts(savedExposedPorts);
                        handleSetActive("ports");
                      }}
                    />
                    <DevToggle>
                      <DynamicBadge
                        className="cursor-pointer mr-3"
                        text="Secret Keys"
                        isActive={Object.values(savedRequiredSecretKeys).some(
                          (isRequired) => isRequired
                        )}
                        pending={showSecretKeys}
                        setIsActive={() => {
                          setRequiredSecretKeys(savedRequiredSecretKeys);
                          handleSetActive("secretKeys");
                        }}
                      />
                    </DevToggle>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-end ml-3">
                <InputField
                  label={
                    !animationCompleted ? "Name Launchable" : "Launchable Name"
                  }
                  onChange={(v) => {
                    const val = v.trimStart();
                    setName(val);
                  }}
                  required={true}
                  value={name}
                  errorMessage=""
                  className="min-w-[300px] mt-2"
                  disabled={animationCompleted}
                  placeholder="descriptive-launchable-name"
                />
                {!animationCompleted ? (
                  <Button
                    disabled={!savedInstance || !name}
                    label="Generate Launchable"
                    className="ml-2 min-w-[175px] max-h-[40px] justify-center"
                    onClick={() => {
                      handleGenerateClick();
                    }}
                  />
                ) : (
                  <Button
                    label="Back to Viewer"
                    className="ml-2 min-w-[175px] max-h-[40px] justify-center"
                    type="secondary"
                    onClick={() => {
                      resetAnimation();
                      setClickedGenerate(false);
                    }}
                  />
                )}
              </div>
              {!!errorCreatingLaunchable && (
                <div className="flex w-full mt-2">
                  <span className="text-rose-500 dark:text-rose-400 text-sm">
                    {errorCreatingLaunchable}
                  </span>
                </div>
              )}
            </div>
          </div>
          <Transition
            className="w-full"
            show={showNotebook}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <FlatCard noBorder className="mt-3">
              <div className="min-w-fit-content items-center">
                <NotebookUploader
                  file={notebookFile}
                  setFile={setNotebookFile}
                  isFileValid={isFileValid}
                  setIsFileValid={setIsFileValid}
                />
                <div>
                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Add file"
                    disabled={false}
                    className="mt-2 ml-2"
                    onClick={async () => {
                      handleNotebookSave();
                    }}
                    type={"primary"}
                  />
                </div>
              </div>
            </FlatCard>
          </Transition>
          <Transition
            className="w-full"
            show={showPorts}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <FlatCard noBorder className="mt-3">
              <div className="min-w-fit-content items-center">
                <ExposePorts
                  exposePorts={savedExposedPorts}
                  onSave={setExposedPorts}
                />
                <div>
                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Save Ports"
                    className="mt-2 ml-2"
                    onClick={async () => {
                      handlePortSave();
                    }}
                    type={"primary"}
                  />
                </div>
              </div>
            </FlatCard>
          </Transition>
          <Transition
            className="w-full"
            show={showContainer}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <FlatCard noBorder className="mt-3">
              <div className="min-w-fit-content items-center">
                <ContainerSelectorWrapper
                  container={container}
                  setContainer={setContainer}
                  cudaVersion={cudaVersion}
                  setCudaVersion={setCudaVersion}
                  pythonVersion={pythonVersion}
                  setPythonVersion={setPythonVersion}
                  hideCustomCredentials={true}
                />
                <div>
                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Save Container"
                    disabled={isContainerEquals(container, savedContainer)}
                    className="mt-2 ml-2"
                    onClick={async () => {
                      handleContainerSave();
                    }}
                    type={"primary"}
                  />
                </div>
              </div>
            </FlatCard>
          </Transition>
          <Transition
            className="w-full"
            show={showCompute}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <FlatCard noBorder className="mt-3">
              <div className="min-w-fit-content items-center">
                <ComputePicker
                  selectedInstance={instance}
                  setSelectedInstance={setInstance}
                  setStorage={setStorage}
                  storage={storage}
                  disableCpu={true}
                />
                <div>
                  {/* TODO: make this button secondary & disabled when an api key isnt there... */}
                  <Button
                    label="Save Compute"
                    disabled={!instance || !storage}
                    className="mt-5 ml-2"
                    onClick={async () => {
                      handleComputeSave();
                    }}
                    type={"primary"}
                  />
                </div>
              </div>
            </FlatCard>
          </Transition>
          {/* Add new Transition for Secret Keys */}
          <Transition
            className="w-full"
            show={showSecretKeys}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <FlatCard noBorder className="mt-3">
              <div className="min-w-fit-content items-center">
                <div className="flex flex-col space-y-4">
                  {Object.entries(requiredSecretKeys).map(
                    ([key, isRequired]) => (
                      <Checkbox
                        key={key}
                        label={`Require ${key}`}
                        checked={isRequired}
                        onChange={(checked) =>
                          setRequiredSecretKeys((prev) => ({
                            ...prev,
                            [key]: checked,
                          }))
                        }
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        labelClassName="ml-2 block text-sm text-gray-900 dark:text-gray-100"
                      />
                    )
                  )}
                </div>
                <div>
                  <Button
                    label="Save Required Secret Keys"
                    disabled={Object.values(requiredSecretKeys).every(
                      (isRequired) => !isRequired
                    )}
                    className="mt-2 ml-2"
                    onClick={handleSecretKeysSave}
                    type="primary"
                  />
                </div>
              </div>
            </FlatCard>
          </Transition>
        </div>
      </div>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default Launchables;
