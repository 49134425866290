import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import yaml from "js-yaml";

import {
  classNames,
  mapValuesToArray,
  notebooks,
  replaceRawWithBlob,
  // getManufacturerName,
} from "components/utils";
import FlatCard from "components/UI-lib/FlatCard";
import Chip from "components/Environment/Create/chips";
import Button from "components/UI-lib/Button";
import { useHistory } from "react-router-dom";
import { OrgContext } from "contexts/OrgContext";
import { GetEnvStatusLight } from "../shared/InstanceStatusIndicator";
import Badge from "components/UI-lib/Badge";
import InstanceStatusIndicator from "../shared/InstanceStatusIndicator";
import Fab from "./Fab";
import IWorkspace, {
  HealthCheck,
  HealthCheckStatus,
  HealthStatus,
  ITunnel,
  VerbBuildStatus,
  WorkspaceClassId,
  WorkspaceStatus,
} from "models/Workspace.model";
import { DarkModeContext } from "contexts/DarkModeContext";
import {
  RectangleGroupIcon,
  CpuChipIcon,
  CloudIcon,
  CircleStackIcon,
  BookOpenIcon,
  ClockIcon,
  UserIcon,
  PlayCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "components/Environment/Settings/utils";
import Workspace from "entities/Workspace.entity";
import { Tooltip } from "@mui/material";
import { DeployState } from "contexts/OneClickDeployContext";
import UsernameDisplayField from "components/UsernameDisplayField";
import InfoBox from "components/DashboardContainer/HoverInfoBox";
import { m } from "framer-motion";
import {
  getInstancePriceRate,
  getStorageCostNumber,
  isGPUfromInstanceType,
  isHealthy,
  useNotebookDetailsFromWorkspace,
} from "../utils";
import InstanceOverview from "../shared/InstanceOverview";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import ContainerOverview from "../shared/ContainerOverview";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Environment } from "../utils/types";
import {
  doesEnvironmentHaveGpu,
  getEnvironmentStatus,
  getGpuCountFromEnvironment,
  getGpuManufacturerFromEnvironment,
  getGpuMemoryFromEnvironment,
  getGpuNameFromEnvironment,
  getMemoryFromEnvironment,
  getVcpuFromEnvironment,
  getVerbBuildStatusFromTasks,
  getWorkspacePlatformTypeFromEnvironment,
  parseTimestamp,
  useNotebookDetailsFromEnvironmentFiles,
} from "../utils/environtmentUtils";
import User from "../../../entities/User.entity";

export interface EnvironmentRowItemProps {
  environment: Environment;
  notForSelf?: boolean;
  viewableEnvironments: any[];
  notebookList: any[];
  user?: User;
}

const InstanceMetadata: React.FC<EnvironmentRowItemProps> = (props) => {
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const { darkMode } = useContext(DarkModeContext);
  // Flag to determine if the new logic should be used.
  // https://us.posthog.com/project/22059/feature_flags/44359
  const flagUseTunnelStatusForNotebookHealth = useFeatureFlagEnabled(
    "use-tunnel-status-for-notebook-health"
  );
  const jupyterApp = useMemo(() => {
    if (props.environment?.tunnel?.applications) {
      return props.environment.tunnel.applications.find(
        (app) =>
          app.name.startsWith("jupyter") &&
          app.name.endsWith("-" + props.environment.workspaceId)
      );
    }
  }, [props.environment.tunnel?.applications]);

  const openNotebook = async (environment: Environment) => {
    if (environment.build?.files && environment.build.files.length > 0) {
      const file = environment.build.files[0];
      if (
        file &&
        environment.build.verb &&
        !environment.build?.verb?.config?.includes("workingDirectory")
      ) {
        window.open(
          "https://" +
            jupyterApp?.hostname +
            "/lab/tree" +
            (file.file_directory?.startsWith("/") ? "" : "/") +
            file.file_directory,
          "_blank"
        );
      } else {
        window.open("https://" + jupyterApp?.hostname, "_blank");
      }
    } else {
      window.open("https://" + jupyterApp?.hostname, "_blank");
    }
  };

  const notebookDetails = useNotebookDetailsFromEnvironmentFiles(
    props.environment.build?.files || [],
    props.notebookList
  );

  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  // Function to start a timeout to hide the InfoBox
  const handleMouseLeave = () => {
    if (!hoverTimeoutRef.current) {
      hoverTimeoutRef.current = setTimeout(() => {
        setHoveredStep(null);
      }, 300); // 300ms delay before hiding the InfoBox
    }
  };

  // Function to clear the timeout when entering the InfoBox
  const handleInfoBoxEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
      setHoveredStep(1);
    }
  };

  const handleListItemEnter = (index: number) => {
    // Clear any existing timeout to prevent it from hiding the InfoBox
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setHoveredStep(1);
  };

  return (
    <FlatCard className="brev-instance mb-8 hover:cursor-pointer dark:hover:border-highlight dark:hover:ring-cyan-500 dark:hover:cursor-pointer">
      <div className="flex flex-col">
        <div
          className="flex flex-row justify-between items-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            history.push(
              `/org/${orgContext.activeOrgId}/environments/${props.environment.workspaceId}`
            );
          }}
        >
          <div className="h-full">
            <div
              className={classNames(
                "group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm",
                "cursor-pointer"
              )}
            >
              <span className="flex min-w-0 flex-1 items-center space-x-3">
                {/* <span className="block flex-shrink-0">
                <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
              </span> */}
                <span className="block min-w-0 flex-1">
                  <span className="block truncate text-lg font-medium text-white">
                    {/* BANANA: make this real */}
                    {/* {notebooks.find((n) => n.url === file)?.name || */}
                    {props.environment.name}
                  </span>
                  <div className="flex flex-row text-secondary text-xs">
                    <span className="block truncate text-xs">
                      ID: {props.environment.workspaceId}
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="mr-1.5 leading-[16px]">User: </span>
                    <UsernameDisplayField
                      className="text-xs text-secondary"
                      userId={props.environment.labels?.userId || ""}
                    />
                    <span className="ml-1 leading-[16px]">
                      ({props.user?.id || ""})
                    </span>
                    <span className="mr-1.5 ml-1.5 leading-[16px]">
                      &middot;
                    </span>
                    <span className="block truncate text-xs text-secondary">
                      Created:{" "}
                      {` ${parseTimestamp(
                        props.environment.create_time
                      )?.toLocaleString()}`}
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div className="flex flex-row">
              <div className="p-2">
                <InstanceOverview
                  workspaceId={props.environment.workspaceId || ""}
                  workspaceStatus={getEnvironmentStatus(props.environment)}
                  workspaceInstanceType={
                    props.environment.instance?.instance_type ||
                    props.environment.instance_type?.type ||
                    ""
                  }
                  isGPUInstance={doesEnvironmentHaveGpu(props.environment)}
                  providerName={getWorkspacePlatformTypeFromEnvironment(
                    props.environment
                  ).toUpperCase()}
                  diskStorage={
                    getEnvironmentStatus(props.environment) ===
                    WorkspaceStatus.Deploying
                      ? "Syncing..."
                      : props.environment.instance?.disk_size || ""
                  }
                  gpuName={getGpuNameFromEnvironment(props.environment)}
                  gpuManufacturer={getGpuManufacturerFromEnvironment(
                    props.environment
                  )}
                  vcpu={getVcpuFromEnvironment(props.environment)}
                  memory={getMemoryFromEnvironment(props.environment)}
                  gpuMemory={getGpuMemoryFromEnvironment(props.environment)}
                  gpuCount={getGpuCountFromEnvironment(props.environment)}
                  platformType={getWorkspacePlatformTypeFromEnvironment(
                    props.environment
                  ).toUpperCase()}
                  instanceCost={
                    props.environment.instance?.instance_type_info
                      ?.supported_storage
                      ? getInstancePriceRate(
                          Number(
                            props.environment.instance?.instance_type_info
                              ?.base_price?.amount || 0
                          ),
                          Number(
                            getStorageCostNumber(
                              props.environment.instance?.disk_size || ""
                            ) || 0
                          ),
                          Number(
                            props.environment.instance?.instance_type_info
                              ?.supported_storage[0].price_per_gb_hr?.amount ||
                              0
                          ),
                          getEnvironmentStatus(props.environment) ===
                            WorkspaceStatus.Deploying ||
                            getEnvironmentStatus(props.environment) ===
                              WorkspaceStatus.Starting ||
                            getEnvironmentStatus(props.environment) ===
                              WorkspaceStatus.Running
                        )
                      : ""
                  }
                />
              </div>
              {doesEnvironmentHaveGpu(props.environment) && (
                <div
                  className={classNames(
                    "flex",
                    getEnvironmentStatus(props.environment) ===
                      WorkspaceStatus.Running &&
                      getVerbBuildStatusFromTasks(
                        props.environment.tasks || []
                      ) !== VerbBuildStatus.Unset
                      ? "on"
                      : ""
                  )}
                >
                  <div className="flex justify-center items-center ml-3 mr-3">
                    <span className="text-xl">→</span>
                  </div>
                  <div className="p-2">
                    <ContainerOverview
                      instanceStatus={getEnvironmentStatus(props.environment)}
                      containerStatus={getVerbBuildStatusFromTasks(
                        props.environment.tasks || []
                      )}
                      build={
                        props.environment.build || {
                          container: {
                            base_image: "Unknown",
                          },
                        }
                      }
                    />
                    {getEnvironmentStatus(props.environment) ===
                      WorkspaceStatus.Running && (
                      <div className="flex flex-col justify-center items-center mt-2 relative">
                        <span
                          className="text-sm text-highlight dark:text-highlight p-1"
                          onMouseEnter={() => handleListItemEnter(1)}
                          onMouseLeave={handleMouseLeave}
                        >
                          View Logs
                          {hoveredStep === 1 && (
                            <InfoBox
                              onMouseEnter={handleInfoBoxEnter}
                              onMouseLeave={handleMouseLeave}
                              activeStep={DeployState.Dependencies}
                              index={hoveredStep}
                              environment={props.environment}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {doesEnvironmentHaveGpu(props.environment) &&
                props.environment.build?.files &&
                props.environment.build.files.length > 0 && (
                  <div
                    className={classNames(
                      "flex",
                      isHealthy(
                        props.environment,
                        jupyterApp,
                        flagUseTunnelStatusForNotebookHealth || true
                      ) &&
                        getVerbBuildStatusFromTasks(
                          props.environment.tasks || []
                        ) === VerbBuildStatus.Completed
                        ? "on"
                        : ""
                    )}
                  >
                    <div className="flex justify-cente items-center ml-3 mr-3">
                      <span className="text-xl">→</span>
                    </div>
                    <div
                      className={classNames(
                        "flex flex-col p-2",
                        isHealthy(
                          props.environment,
                          jupyterApp,
                          flagUseTunnelStatusForNotebookHealth || true
                        ) &&
                          getVerbBuildStatusFromTasks(
                            props.environment.tasks || []
                          ) === VerbBuildStatus.Completed
                          ? "on"
                          : ""
                      )}
                    >
                      <div className="flex flex-row mb-1 items-center">
                        <BookOpenIcon className="h-6 w-6 mr-1" />
                        <span className="text-sm">Notebook</span>
                      </div>
                      <span className="text-sm mt-1 mb-1">
                        {notebookDetails.name}
                      </span>
                      <span
                        className="block truncate text-xs font-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Preview Notebook
                      </span>
                      {isHealthy(
                        props.environment,
                        jupyterApp,
                        flagUseTunnelStatusForNotebookHealth || true
                      ) &&
                        getVerbBuildStatusFromTasks(
                          props.environment.tasks || []
                        ) === VerbBuildStatus.Completed && (
                          <div className="flex">
                            <Button
                              type="primary"
                              label="Access Notebook"
                              className="mt-2"
                              onClick={() => {
                                openNotebook(props.environment);
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};
export default InstanceMetadata;

export const InstanceMetadataSkeleton: React.FC = (props) => {
  const notebookDetails = {
    name: "Your FineTune NoteBook",
    path: "random/path",
  };
  const mockWorkspaceTemplate: any = {
    id: "1234",
    name: "BrevGPT Finetune Workspace",
    createdByUserId: "1234",
    createdAt: "2024-04-17T03:54:16.947597898Z",
    status: WorkspaceStatus.Stopped,
    verbBuildStatus: VerbBuildStatus.Pending,
    instanceType: "g5.16xlarge",
    vcpu: 24,
    memory: "340GB",
    gpuName: "A100",
    gpuManufacturer: "NVIDIA",
    gpuMemory: "80GB",
    gpuCount: 2,
    diskStorage: "120GB",
    providerName: "GCP",
  };

  const [isHealthy, setIsHealthy] = useState(false);
  const [mockWorkspace, setMockWorkspace] = useState(mockWorkspaceTemplate);

  useEffect(() => {
    const controlFlow = async () => {
      while (true) {
        // Loop indefinitely
        // Wait for 2 seconds, then toggle the system health
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Deploying,
        }));
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Starting,
        }));
        // Wait for 2 seconds, then toggle the system health
        await new Promise((resolve) => setTimeout(resolve, 2000));

        setIsHealthy((prev) => !prev);
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Running,
        }));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Pending,
        }));

        // Wait for another 2 seconds, then toggle the workspace status
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Building,
        }));

        // Determine verbBuildStatus based on the status and wait for 2 seconds before updating
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          verbBuildStatus: VerbBuildStatus.Completed,
        }));

        await new Promise((resolve) => setTimeout(resolve, 2000));
        setIsHealthy(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Stopping,
          VerbBuildStatus: VerbBuildStatus.Pending,
        }));
        setIsHealthy(false);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setMockWorkspace((prev) => ({
          ...prev,
          status: WorkspaceStatus.Stopped,
        }));
        setIsHealthy(false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    };
    controlFlow();
    // No need for cleanup as the effect only runs once and controls its own loop
  }, []);

  return (
    <FlatCard className="">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <div className="h-full opacity-35">
            <div
              className={classNames(
                "group flex w-full items-center justify-between space-x-3 rounded-full p-2 text-left shadow-sm",
                "cursor-pointer relative"
              )}
            >
              <span className="flex min-w-0 flex-1 items-center space-x-3">
                {/* <span className="block flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
          </span> */}
                <span className="block min-w-0 flex-1">
                  <span className="block truncate text-md font-medium text-gray-900 dark:text-slate-400">
                    {/* BANANA: make this real */}
                    {/* {notebooks.find((n) => n.url === file)?.name || */}
                    {mockWorkspace.name}
                  </span>
                  <div className="flex flex-row text-gray-900 dark:text-slate-500 text-xs items-center">
                    <span className="block truncate text-xs">
                      ID: {mockWorkspace.id}
                    </span>
                    <span className="mr-1.5 ml-1.5">&middot;</span>
                    <span className="mr-1.5">User: </span>
                    <span className="text-xs text-gray-900 dark:text-slate-500">
                      Jensen Huang
                    </span>
                    <span className="ml-1">
                      ({mockWorkspace.createdByUserId})
                    </span>
                    <span className="mr-1.5 ml-1.5">&middot;</span>
                    <span className="block truncate text-xs text-gray-900 dark:text-slate-500">
                      Created:{" "}
                      {` ${new Date(mockWorkspace.createdAt).toLocaleString()}`}
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div className="flex flex-row min-h-[145px]">
              <div className="p-2">
                <InstanceOverview
                  workspaceId={mockWorkspace.id}
                  workspaceInstanceType={mockWorkspace.instanceType}
                  workspaceStatus={mockWorkspace.status}
                  isGPUInstance={true}
                  providerName={mockWorkspace.providerName}
                  diskStorage={mockWorkspace.diskStorage}
                  vcpu={mockWorkspace.vcpu}
                  memory={mockWorkspace.memory}
                  gpuName={mockWorkspace.gpuName}
                  gpuManufacturer={mockWorkspace.gpuManufacturer}
                  gpuMemory={mockWorkspace.gpuMemory}
                  gpuCount={mockWorkspace.gpuCount}
                  instanceCost={
                    mockWorkspace.status === WorkspaceStatus.Running ||
                    mockWorkspace.status === WorkspaceStatus.Starting ||
                    mockWorkspace.status === WorkspaceStatus.Deploying
                      ? "2.48"
                      : "0.03"
                  }
                />
              </div>
              <div
                className={classNames(
                  "flex",
                  mockWorkspace.status === WorkspaceStatus.Running &&
                    mockWorkspace.verbBuildStatus !== VerbBuildStatus.Unset
                    ? "on"
                    : ""
                )}
              >
                <div className="flex justify-center items-center ml-3 mr-3">
                  <span className="text-xl">→</span>
                </div>
                <div className="p-2 min-w-[210px]">
                  <ContainerOverview
                    instanceStatus={mockWorkspace.status}
                    containerStatus={mockWorkspace.verbBuildStatus}
                    build={{
                      container: {
                        base_image: "nvidia/cuda:12.2-devel-ubuntu22.04",
                      },
                    }}
                  />
                  {mockWorkspace.status === WorkspaceStatus.Running && (
                    <div className="flex flex-col justify-center items-center mt-2 relative">
                      <span className="text-sm text-highlight dark:text-highlight p-1">
                        View Logs
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  "flex",
                  isHealthy &&
                    mockWorkspace.verbBuildStatus === VerbBuildStatus.Completed
                    ? "on"
                    : ""
                )}
              >
                <div className="flex justify-cente items-center ml-3 mr-3">
                  <span className="text-xl">→</span>
                </div>
                <div
                  className={classNames(
                    "flex flex-col p-2",
                    isHealthy &&
                      mockWorkspace.verbBuildStatus ===
                        VerbBuildStatus.Completed
                      ? "on"
                      : ""
                  )}
                >
                  <div className="flex flex-row mb-1 items-center">
                    <BookOpenIcon className="h-6 w-6 mr-1" />
                    <span className="text-sm">Notebook</span>
                  </div>
                  <span className="text-sm mt-1 mb-1">
                    {notebookDetails.name}
                  </span>
                  <span
                    className="block truncate text-xs font-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Preview Notebook
                  </span>
                  {isHealthy &&
                    mockWorkspace.verbBuildStatus ===
                      VerbBuildStatus.Completed && (
                      <Button
                        type="primary"
                        label="Access Notebook"
                        className="mt-2"
                        onClick={() => {}}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FlatCard>
  );
};
