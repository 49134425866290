import {
  ArrowUpRightIcon,
  PlayCircleIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import posthog from "posthog-js";
import FlatCard from "components/UI-lib/FlatCard";
import {
  classNames,
  roundPriceToTwoDigits,
  validateName,
} from "components/utils";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { useWindupString } from "windups";
import LaunchableConfigurationView from "../shared/LaunchableConfigurationView";
import LaunchableConfigurationViewFromParams from "../shared/LaunchableConfigurationViewFromParams";
import { getLaunchableQueryParams } from "../shared/utils";
import { useFeatureFlagEnabled } from "posthog-js/react";
import FileViewer from "../shared/FileViewer";
import { useLaunchable } from "../../utils";
import { dummyInstanceObject } from "components/Environment/utils";
import { Tooltip } from "@mui/material";
import nvidiaLogo from "assets/img/svg/nvidia-white-text.svg";
import { LaunchableNotFound } from "../shared/LaunchableNotFound";
import { Button } from "@kui/react";
import Footer from "components/Footer";
import { isKasAuthFlow } from "server/kas/utils";

interface LaunchableDeployProps {}

const WillHillLaunchableIDMessUp = "env-2hQX3n7ae5mq3NjNZ32DfAG0tJf";

const LaunchableDeploy: React.FC<LaunchableDeployProps> = (props) => {
  console.log("Route: /launchable/deploy/now");
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { queryLaunchableID } = getLaunchableQueryParams(location.search);
  const { launchableData, error, loading, launchableNotFound } = useLaunchable(
    queryLaunchableID || ""
  );

  const orgContext = useContext(OrgContext);
  const [nameErrorMessage, setErrorMessage] = useState<string>("");
  const [instanceObject, setInstanceObject] = useState<GPUInstanceType | null>(
    null
  );
  const [deploymentStarted, setDeploymentStarted] = useState(false);
  const oneClickContext = useContext(OneClickDeployContext);
  const flagNimsHackathonEnabled = useFeatureFlagEnabled("nims-hackathon");

  const [extraEventProperties, setExtraEventProperties] = useState<
    Record<string, any>
  >({});

  useEffect(() => {
    const fn = async () => {
      const eventProperties = {
        launchableID: queryLaunchableID,
        launchableInstanceType: launchableData?.instanceType,
        workspaceGroupID: launchableData?.workspaceGroupId,
        launchableCreatedByUserID: launchableData?.userId,
        launchableCreatedByOrgID: launchableData?.orgId,
        launchableRawURL: location.pathname + location.search,
      };
      setExtraEventProperties(eventProperties);
      posthog.capture("Launchable Viewed", eventProperties);
    };
    if (!!launchableData) {
      fn();
    }
  }, [launchableData]);

  const transformQueryParamsToObject = (query: URLSearchParams) => {
    const paramsDict = {};
    for (let [key, value] of query.entries()) {
      paramsDict[key] = value;
    }
    return paramsDict;
  };

  const handleDeployNow = (extraProperties: Record<string, any>) => {
    if (queryLaunchableID === WillHillLaunchableIDMessUp) {
      if (launchableData) {
        launchableData?.containerSelected?.verbBuild?.containerUrl ===
          "nvcr.io/nvidia/tensorrt:24.05-py3";
      }
    }
    setDeploymentStarted(true);
    oneClickContext.startDeploymentFromLaunchable(
      launchableData,
      extraProperties
    );
  };

  const isAllowedBaseImage = useMemo(() => {
    if (!launchableData?.containerSelected.verbBuild?.containerUrl) {
      return true;
    }

    if (
      !flagNimsHackathonEnabled &&
      launchableData?.containerSelected?.verbBuild?.containerUrl ===
        "nvcr.io/mphexwv2ysej/meta-llama3-8b-instruct:24.05.rc7"
    ) {
      return false;
    }

    return true;
  }, [flagNimsHackathonEnabled, launchableData?.containerSelected]);

  useEffect(() => {
    if (!isKasAuthFlow) {
      const redirectUrl = `/launchable-migrate-notice?${new URLSearchParams({
        redirect: `https://brev.nvidia.com/launchable/deploy/now?${query.toString()}`,
      }).toString()}`;
      history.push(redirectUrl);
    }
  }, [query, history]);

  if (orgContext.activeOrg) {
    return (
      <>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10 min-h-screen">
          {!loading && launchableNotFound ? (
            <LaunchableNotFound />
          ) : (
            <>
              <div className="flex flex-row justify-between mb-5">
                <div className="flex flex-col">
                  <h1 className="text-white text-2xl font-semibold mr-2">
                    {launchableData?.name || ""}
                  </h1>
                  <div className="flex items-center text-secondary">
                    {instanceObject && (
                      <Tooltip placement="top" title="Cost to run launchable">
                        <div className="flex flex-row items-center">
                          <PlayCircleIcon className="w-4 h-4" />
                          <div className="flex flex-col text-sm ml-1">
                            $
                            {roundPriceToTwoDigits(
                              instanceObject?.base_price?.amount || "0.00"
                            )}
                            /hr
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div>
                  <div className="ml-5">
                    {!loading && oneClickContext.isFinished && (
                      <div
                        className={classNames(
                          orgContext.isInstancesLoading
                            ? "border border-highlight rounded-[25px] border-highlight"
                            : ""
                        )}
                      >
                        <Button
                          className="justify-center"
                          disabled={
                            !!nameErrorMessage ||
                            !instanceObject ||
                            !isAllowedBaseImage ||
                            deploymentStarted ||
                            orgContext.allInstancesAvailable.length === 0 ||
                            instanceObject.is_available === false
                          }
                          onClick={() => {
                            handleDeployNow(extraEventProperties);
                            posthog.capture(
                              "Launchable Deployed",
                              extraEventProperties
                            );
                          }}
                        >
                          {orgContext.isInstancesLoading
                            ? "Loading..."
                            : "Deploy Launchable"}
                        </Button>
                      </div>
                    )}
                  </div>
                  {!loading && oneClickContext.isFinished ? (
                    <>
                      <div className="flex flex-row justify-center">
                        <div className="ml-5"></div>
                      </div>
                    </>
                  ) : (
                    <div className="">
                      <Deploying />
                    </div>
                  )}
                </div>
              </div>
              <FlatCard
                className="min-h-[150px] flex items-center"
                noBorder={true}
              >
                {loading && (
                  <div className="w-full mb-3 flex text-white">
                    <h1 className="text-lg font-semibold mr-2">
                      Loading Launchable...
                    </h1>
                  </div>
                )}
                {!loading ? (
                  <>
                    <LaunchableConfigurationViewFromParams
                      containerSelected={
                        launchableData?.containerSelected || null
                      }
                      cuda={launchableData?.cuda || ""}
                      diskStorage={launchableData?.diskStorage || ""}
                      file={launchableData?.file || ""}
                      instanceType={launchableData?.instanceType || ""}
                      ports={launchableData?.ports || null}
                      firewallRules={launchableData?.firewallRules || null}
                      python={launchableData?.python || ""}
                      workspaceGroupId={launchableData?.workspaceGroupId || ""}
                      setInstance={setInstanceObject}
                      forceJupyterInstall={
                        launchableData?.containerSelected.vmBuild
                          ?.forceJupyterInstall ||
                        launchableData?.containerSelected?.dockerCompose
                          ?.jupyterInstall ||
                        false
                      }
                    />
                  </>
                ) : (
                  <div className="opacity-50 shimmer">
                    <LaunchableConfigurationView
                      shortFileName="Your Notebook Here"
                      loadingInstance={true}
                      containerSelected={{
                        verbBuild: {
                          containerUrl: "loading-base-image",
                        },
                      }}
                      forceJupyterInstall={false}
                      cuda={""}
                      diskStorage={"256Gi"}
                      file={"file name"}
                      instanceObject={null}
                      ports={null}
                      firewallRules={null}
                      python={""}
                    />
                  </div>
                )}
                {!loading && (
                  <div className="flex flex-col justify-center">
                    <div className="flex flex-col justify-center min-h-[12px]">
                      {instanceObject &&
                        instanceObject.is_available === false && (
                          <div className="flex flex-row justify-center mt-5">
                            <span className="text-red-500 dark:text-red-400 text-sm">
                              This compute type is not available at the moment
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </FlatCard>
              {!loading && <FileViewer file={launchableData?.file || ""} />}
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
  return <div />;
};

export default LaunchableDeploy;

const Deploying: React.FC = () => {
  const history = useHistory();
  const orgContext = useContext(OrgContext);
  const oneClickContext = useContext(OneClickDeployContext);
  const [text] = useWindupString("Launchable is now deploying...");

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center items-end">
          <p
            className={classNames(
              "truncate",
              "text-sm text-highlight font-mono justify-center min-w-[255px]"
            )}
          >
            {text}
          </p>
          <Button
            className="pulse-ready-button ml-5"
            onClick={() => {
              history.push(
                `/org/${orgContext.activeOrgId}/environments/${oneClickContext.environment?.workspaceId}#Container`
              );
            }}
            disabled={!oneClickContext.environment?.workspaceId}
          >
            Go to Instance Page
          </Button>
        </div>
        <div className="flex flex col justify-center mt-3">
          <p className="text-sm text-gray-700 dark:text-secondary">
            For additional options, logs, and access click the button above
          </p>
        </div>
      </div>
    </>
  );
};
