import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Button } from "components/Graphs/Button";
import React, { useContext, useEffect, useState } from "react";
import { api, BaseRes, AxiosRes } from "../../server/config";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { isKasAuthFlow } from "server/kas/utils";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "components/Modals/Modal";
import { UserContext } from "contexts/UserContext";

interface MergeBannerProps {
  alwaysShow?: boolean;
}

export const MergeBanner: React.FC<MergeBannerProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const migrateFlag = useFeatureFlagEnabled("migrate-user-brev-nvidia");
  const { loginWithRedirect, getIdTokenClaims, isAuthenticated, user } =
    useAuth0();
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const userContext = useContext(UserContext);
  const handleMergeClick = async () => {
    // This will use the *same* Auth0 client from your old domain
    // but it's now being called from your new domain
    await loginWithRedirect({
      // You can pass additional parameters to customize the login experience
      // This redirects back to your callback page on the new domain
      redirect_uri: `${window.location.origin}/merge-callback`,
      // You can store any additional state you need
      appState: { returnTo: window.location.pathname },
    });
  };

  const forceCheckUser = async () => {
    const user = await userContext.getUser(userContext.me?.id || "", true);
    if (
      user &&
      user.externalIdentities &&
      user.externalIdentities.length >= 2
    ) {
      setShowBanner(false);
      localStorage.setItem(
        `merge-banner-closed-${userContext.me?.id || ""}`,
        "true"
      );
    }
  };

  useEffect(() => {
    if (userContext.me) {
      if (!props.alwaysShow) {
        const alreadyClosed = localStorage.getItem(
          `merge-banner-closed-${userContext.me.id}`
        );
        if (alreadyClosed) {
          setShowBanner(false);
        } else {
          setShowBanner(true);
        }
        forceCheckUser();
      }
    }
  }, [userContext.me]);

  if (
    !isKasAuthFlow ||
    (userContext.me?.externalIdentities &&
      userContext.me?.externalIdentities?.length >= 2)
  ) {
    return null;
  }

  return (
    showBanner && (
      <div className="w-full max-w-7xl mx-auto sm:px-6 lg:px-8 py-3">
        <div className="px-4 sm:px-6 lg:px-8">
          <MergeModal
            show={showMergeModal}
            setShow={setShowMergeModal}
            onCancel={() => setShowMergeModal(false)}
            onSuccess={() => {
              handleMergeClick();
              setShowMergeModal(false);
            }}
          />
          <div className="flex flex-row items-center justify-start border border-amber-700 rounded-lg p-2">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row">
                <div className="flex flex-row items-center gap-1">
                  <ExclamationTriangleIcon className="w-4 h-4 text-amber-700 dark:text-amber-500" />
                  <p className="text-xs font-medium text-amber-700 dark:text-amber-500">
                    Already have an account on{" "}
                    <span className="font-bold">console.brev.dev</span>? Want to
                    merge it with your account on{" "}
                    <span className="font-bold">brev.nvidia.com</span>?
                  </p>
                </div>
                <Button
                  className="h-6 ml-2"
                  onClick={() => setShowMergeModal(true)}
                  disabled={isLoading}
                >
                  <span className="text-xs">
                    {isLoading
                      ? "Preparing merge..."
                      : "Merge account from console.brev.dev"}
                  </span>
                  <ArrowUpRightIcon className="w-4 h-4" />
                </Button>
              </div>
              {!props.alwaysShow && (
                <div
                  className="flex flex-row items-center gap-1 cursor-pointer"
                  onClick={() => {
                    localStorage.setItem(
                      `merge-banner-closed-${userContext.me?.id || ""}`,
                      "true"
                    );
                    setShowBanner(false);
                  }}
                >
                  <XCircleIcon className="w-6 h-6 text-red-500" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MergeBanner;

export interface MergeModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const MergeModal: React.FC<MergeModalProps> = ({
  show,
  setShow,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Modal
      isOpen={show}
      setOpen={setShow}
      onClose={() => {
        setShow(false);
      }}
      title={"Confirm Merge"}
      body={
        <>
          <div className="w-[450px] ">
            <div className="flex flex-col">
              <div className="flex flex-row items-start justify-start mt-2">
                <p className="text-sm dark:text-white">
                  Be aware, you will be redirected to login through your old
                  account which will merge with your current account here, on{" "}
                  <strong>brev.nvidia.com</strong>.
                </p>
              </div>
              <div className="flex flex-row items-start justify-center mt-4">
                <p className="text-sm dark:text-white">
                  If you have any questions, please contact support at{" "}
                  <a
                    href="mailto:brev-support@nvidia.com"
                    className="text-highlight"
                  >
                    brev-support@nvidia.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      }
      cancelLabel="Cancel"
      confirmLabel={"Start Merge"}
      onSuccess={() => onSuccess()}
    />
  );
};
