import React, { useContext, useEffect, useState } from "react";
import Cloud from "assets/img/svg/cloud.svg?react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import Button from "components/UI-lib/Button";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";
import { NotificationContext } from "contexts/NotificationContext";
import { DarkModeContext } from "contexts/DarkModeContext";
import aws from "assets/img/png/aws.svg";
import awsWhite from "assets/img/png/awswhite.svg";
import gcp from "assets/img/png/gcp.svg";
import Spinner from "components/UI-lib/Spinner";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import { error } from "console";
import LightModeLogo from "assets/img/svg/logo_light_mode.svg?react";
import DarkModeLogo from "assets/img/svg/logo_dark_mode.svg?react";

interface ConnectedCloudDetailsProps {
  //   connectedCard: ConnectedCard | null;
  //   isDefault?: boolean;
  wsg: WorkspaceGroup;
}

const ConnectedCloudDetails: React.FC<ConnectedCloudDetailsProps> = (props) => {
  const { activeOrgId } = useContext(OrgContext);
  const notificationContext = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const { darkMode } = useContext(DarkModeContext);
  const [cloud, setCloud] = useState<"aws" | "gcp">("aws");
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [isDeprecating, setIsDeprecating] = useState(false);
  const orgContext = useContext(OrgContext);
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  // setup inline notification
  const notificaitonContext = useContext(NotificationContext);
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");

  const getReadableTime = (timestamp: string) => {
    const readableTime = new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return readableTime;
  };

  const checkStatus = async () => {
    setCheckingStatus(true);
    try {
      const res = await agent.Organizations.getWorkspaceGroupRegions(
        props.wsg.id
      );
      console.log(res);
      if (res && res.success) {
        setStatus(true);
      } else {
        setErrorDetails(res.message);
        setStatus(false);
      }
      setCheckingStatus(false);
    } catch (error) {
      setStatus(false);
      setCheckingStatus(false);
    }
  };

  const getCloudIcon = (platformType: string, name: string) => {
    if (name === "Brev Cloud") {
      return (
        <div className="flex items-center text-highlight">
          <svg
            width="50"
            height="50"
            viewBox="0 0 249 165"
            className="mr-1"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
          </svg>
        </div>
      );
    }

    switch (platformType) {
      case "aws":
        return (
          <img
            src={darkMode ? awsWhite : aws}
            alt="AWS"
            className="h-5 w-7 mr-3 mt-1"
          />
        );

      case "gcp":
        return <img src={gcp} alt="GCP" className="h-5 w-7 mr-3 mt-1" />;
      default:
        return <Cloud className="w-[36px] h-[36px]" />;
    }
  };

  const getStatus = (isWorking: boolean) => {
    if (isWorking) {
      return (
        <div className="flex items-start">
          <CheckCircleIcon color="green" width={20} />
          <p className="text-gray-900 dark:text-secondary">Connected</p>
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex items-start">
            <ExclamationCircleIcon color="red" width={20} />
            <p
              className="text-gray-900 dark:text-secondary underline"
              onClick={() => {
                setShowErrorDetails(!showErrorDetails);
              }}
            >
              {`Error. Click to ${showErrorDetails ? "hide" : "show"} details`}
            </p>
          </div>{" "}
          {showErrorDetails && (
            <p className="text-gray-900 dark:text-secondary">{errorDetails}</p>
          )}
        </div>
      );
    }
  };

  const deprecateWsg = async () => {
    setIsDeprecating(true);
    const res = await agent.Organizations.depracateWorkspaceGroup(props.wsg.id);
    if (res && res.success) {
      orgContext.refreshWorkspaceGroups(true);
      setInlineAlertMessage("Successfully deprecated cloud");
      setInlineAlertSeverity("success");
    } else {
      setInlineAlertMessage("Failed to deprecate cloud");
      setInlineAlertSeverity("error");
    }
    setIsDeprecating(false);
  };

  useEffect(() => {
    checkStatus();
  }, [props.wsg]);

  return (
    <div className="">
      <InlineNotification
        show={!!inlineAlertMessage}
        severity={inlineAlertSeverity}
        text={inlineAlertMessage}
        autoClose={false}
        onClose={() => setInlineAlertMessage("")}
      />
      <div className="w-full flex flex-row justify-between items-center">
        <dd className="flex text-sm ml-1 flex-col">
          <div className="flex flex-row">
            <div>
              {getCloudIcon(props.wsg.platformType, props.wsg.name)}
              <p className="sr-only">{"aws"}</p>
            </div>
            <div className="ml-4">
              <p className="text-gray-900 dark:text-secondary">
                {props.wsg.name === "Brev Cloud"
                  ? "NVIDIA Brev Cloud"
                  : props.wsg.name}
                {` `}
                {`${
                  props.wsg.tenantType !== "shared" ? `(${props.wsg.id})` : ""
                }`}
              </p>
              <p className="text-gray-500 dark:text-secondary">
                {`Added ${props.wsg.createdAt.split("T")[0]}, ${getReadableTime(
                  props.wsg.createdAt
                )}`}
              </p>
              <div className="flex flex-row">
                <p className="text-gray-900 dark:text-secondary mr-2">
                  Status:{" "}
                </p>{" "}
                {checkingStatus ? (
                  <>
                    <Spinner type="secondary" />
                  </>
                ) : (
                  <>{getStatus(status)}</>
                )}
              </div>
            </div>
          </div>
        </dd>

        {props.wsg.name !== "Brev Cloud" && (
          <div className="flex flex-col justify-center items-center">
            <Button
              label={"Deprecate"}
              type="secondary"
              onClick={deprecateWsg}
              loading={isDeprecating}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectedCloudDetails;
