import React, { useContext, useState } from "react";
import {
  ContainerSelected,
  parseDockerComposeUrl,
  Registry,
} from "components/Environment/shared/BuildTypes";
import { FlatCard } from "components/UI-lib";
import { Button } from "components/Graphs/Button";
import { RadioGroup, RadioGroupItem } from "components/Graphs/RadioGroup";
import { Input } from "components/Graphs/Input";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Switch } from "components/Graphs/Switch";
import agent, { ValidateDockerComposeReqBody } from "server";
import { replaceBlobWithRaw } from "components/utils";
import { Spinner } from "@kui-react/spinner";
import { motion } from "framer-motion";
import { ChevronDown, ChevronUp } from "lucide-react";
import { XCircleIcon } from "@heroicons/react/24/outline";

export interface DockerComposeSelectorProps {
  containerSelected?: ContainerSelected;
  onSelectContainer: (container: ContainerSelected) => void;
  hideCredentials: boolean;
}

interface Port {
  mode: string;
  target: number;
  published: string;
  protocol: string;
}

interface Service {
  name: string;
  image: string;
  ports: Port[];
  restart: string;
  scale: number;
}

interface Project {
  services: Service[];
}

interface ComposeConfig {
  project: Project;
}

interface RegistryWithValidation extends Registry {
  errors?: {
    url?: string;
    username?: string;
    password?: string;
  };
}

const DockerCompose: React.FC<DockerComposeSelectorProps> = ({
  containerSelected,
  onSelectContainer,
  hideCredentials,
}) => {
  const [uploadMethod, setUploadMethod] = useState<"file" | "github">("file");
  const [gitUrl, setGitUrl] = useState("");
  const [yamlString, setYamlString] = useState<string>("");
  const [installJupyter, setInstallJupyter] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>("");
  const [validationResponse, setValidationResponse] =
    useState<ComposeConfig | null>(null);

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);

  const [registries, setRegistries] = useState<Array<RegistryWithValidation>>(
    []
  );

  // Add ref for the registry container
  const registryContainerRef = React.useRef<HTMLDivElement>(null);

  const validateDockerCompose = async (
    method: "file" | "github",
    data: string
  ) => {
    // Validate registries first if any exist
    if (registries.length > 0 && !validateAllRegistries()) {
      setValidationError("Please fill out all registry fields correctly");
      return;
    }

    setLoadingValidation(true);
    setValidationError("");
    setValidationResponse(null);
    const body: ValidateDockerComposeReqBody = {
      fileUrl: method === "github" ? replaceBlobWithRaw(data) : undefined,
      yamlString: method === "file" ? data : undefined,
    };
    const res = await agent.Workspaces.validateDockerCompose(body);
    if (res.success && res.data) {
      setValidationResponse(res.data);
    } else {
      setValidationError(res.message);
    }
    setLoadingValidation(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setLoadingFile(true);
      setFileError(null);
      setYamlString("");
      setFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const text = e.target?.result as string;
          if (!text.trim()) {
            throw new Error("File is empty");
          }
          setYamlString(text);
          console.log("yamlString", text);
          setLoadingFile(false);
        } catch (error) {
          setFileError(
            error instanceof Error ? error.message : "Failed to read file"
          );
          setLoadingFile(false);
        }
      };

      reader.onerror = () => {
        setFileError("Failed to read file");
        setLoadingFile(false);
      };

      reader.readAsText(file);
    }
  };

  const validateRegistry = (registry: RegistryWithValidation): boolean => {
    const errors: RegistryWithValidation["errors"] = {};
    let isValid = true;

    if (!registry.url.trim()) {
      errors.url = "Registry URL is required";
      isValid = false;
    } else if (!registry.url.includes(".")) {
      errors.url = "Please enter a valid URL";
      isValid = false;
    }

    if (!registry.username.trim()) {
      errors.username = "Username is required";
      isValid = false;
    }

    if (!registry.password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    }

    // Update the registry with any errors
    const updatedRegistries = [...registries];
    const index = registries.indexOf(registry);
    updatedRegistries[index] = { ...registry, errors };
    setRegistries(updatedRegistries);

    return isValid;
  };

  const validateAllRegistries = (): boolean => {
    return registries.every(validateRegistry);
  };

  const addRegistry = () => {
    setRegistries([
      ...registries,
      { url: "", username: "", password: "", errors: {} },
    ]);
    setTimeout(() => {
      registryContainerRef.current?.scrollTo({
        top: registryContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
  };

  const removeRegistry = (index: number) => {
    setRegistries(registries.filter((_, i) => i !== index));
  };

  const updateRegistry = (
    index: number,
    field: "url" | "username" | "password",
    value: string
  ) => {
    const updatedRegistries = [...registries];
    updatedRegistries[index] = {
      ...updatedRegistries[index],
      [field]: value,
      errors: {
        ...updatedRegistries[index].errors,
        [field]: undefined, // Clear the error when the field is updated
      },
    };
    setRegistries(updatedRegistries);
  };

  const resetDockerCompose = () => {
    setValidationResponse(null);
    setUploadMethod("file");
    setGitUrl("");
    setYamlString("");
    setInstallJupyter(false);
    setRegistries([]);
    setFile(null);
  };

  return (
    <>
      <div className="flex flex-col min-h-full">
        <div className="flex flex-row text-white w-full">
          <div className="flex flex-col w-full gap-4">
            <motion.div
              layout
              initial={{ height: "auto" }}
              animate={{ height: validationResponse ? "60px" : "auto" }}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 30,
              }}
            >
              <FlatCard className="mt-4">
                <motion.div layout="position">
                  {!validationResponse ? (
                    <div className="flex flex-col mb-5">
                      <div className="flex flex-col mb-3">
                        <div className="text-lg font-semibold">
                          Upload Docker Compose
                        </div>
                        <div className="text-sm">
                          Choose to upload a local file or provide a
                          GitHub/Gitlab URL
                        </div>
                      </div>
                      <RadioGroup
                        value={uploadMethod}
                        onValueChange={(value) =>
                          setUploadMethod(value as "file" | "github")
                        }
                      >
                        <div className="flex items-center gap-x-3">
                          <RadioGroupItem value="file" id="radio_1" />
                          <div className="text-sm">Upload Local File</div>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <RadioGroupItem value="github" id="radio_2" />
                          <div className="text-sm">
                            Provide GitHub/Gitlab URL
                          </div>
                        </div>
                      </RadioGroup>
                      <div className="mt-4">
                        {uploadMethod === "file" ? (
                          <div className="space-y-2">
                            <div className="text-sm font-semibold mb-2">
                              Docker Compose File
                            </div>
                            <Input
                              type="file"
                              accept=".yml,.yaml"
                              onChange={handleFileChange}
                            />
                            {fileError && (
                              <div className="text-red-500">{fileError}</div>
                            )}
                          </div>
                        ) : (
                          <div className="space-y-2">
                            <div className="text-sm font-semibold mb-2">
                              GitHub/Gitlab URL
                            </div>
                            <Input
                              type="url"
                              placeholder="https://github.com/user/repo/blob/main/docker-compose.yml"
                              value={gitUrl}
                              onChange={(e) => setGitUrl(e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row items-center gap-2 mt-5">
                        <Switch
                          size="large"
                          checked={installJupyter}
                          onCheckedChange={setInstallJupyter}
                        />
                        <div className="text-sm">Install Jupyter on Host</div>
                      </div>

                      {!hideCredentials && (
                        <div className="space-y-4 mt-4">
                          <div className="flex items-center justify-between">
                            <div className="text-sm font-semibold">
                              Docker Registry Credentials
                            </div>
                            <Button type="button" onClick={addRegistry}>
                              Add Registry
                            </Button>
                          </div>
                          <div
                            ref={registryContainerRef}
                            className="max-h-[200px] min-h-[75px] rounded-md border border-white overflow-auto"
                          >
                            {registries.map((reg, index) => (
                              <div
                                key={index}
                                className="flex flex-col space-y-2 p-4 border-b border-zinc-800 last:border-b-0"
                              >
                                <div className="flex space-x-2">
                                  <div className="flex flex-col flex-1">
                                    <Input
                                      placeholder="Registry URL"
                                      value={reg.url}
                                      onChange={(e) =>
                                        updateRegistry(
                                          index,
                                          "url",
                                          e.target.value
                                        )
                                      }
                                      className={
                                        reg.errors?.url ? "border-red-500" : ""
                                      }
                                    />
                                    {reg.errors?.url && (
                                      <span className="text-red-500 text-xs mt-1">
                                        {reg.errors.url}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-col flex-1">
                                    <Input
                                      placeholder="Username"
                                      value={reg.username}
                                      onChange={(e) =>
                                        updateRegistry(
                                          index,
                                          "username",
                                          e.target.value
                                        )
                                      }
                                      className={
                                        reg.errors?.username
                                          ? "border-red-500"
                                          : ""
                                      }
                                    />
                                    {reg.errors?.username && (
                                      <span className="text-red-500 text-xs mt-1">
                                        {reg.errors.username}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-col flex-1">
                                    <Input
                                      type="password"
                                      placeholder="Password"
                                      value={reg.password}
                                      onChange={(e) =>
                                        updateRegistry(
                                          index,
                                          "password",
                                          e.target.value
                                        )
                                      }
                                      className={
                                        reg.errors?.password
                                          ? "border-red-500"
                                          : ""
                                      }
                                    />
                                    {reg.errors?.password && (
                                      <span className="text-red-500 text-xs mt-1">
                                        {reg.errors.password}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-col">
                                    <Button
                                      type="button"
                                      variant="ghost"
                                      onClick={() => removeRegistry(index)}
                                    >
                                      <TrashIcon className="h-4 w-4" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-between items-center">
                      <div className="text-sm truncate">
                        <span className="font-bold">
                          {uploadMethod === "file"
                            ? "Uploaded File: "
                            : "URL: "}
                        </span>
                        <span className="truncate">
                          {uploadMethod === "file"
                            ? yamlString
                              ? file?.name
                              : "None"
                            : gitUrl || "None"}
                        </span>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <Button
                          type="button"
                          variant="ghost"
                          onClick={() => {
                            resetDockerCompose();
                          }}
                          className="text-red-500"
                        >
                          <XCircleIcon className="w-4 h-4 mr-1 text-red-500" />
                          <span className="text-red-500">Reset</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </motion.div>
              </FlatCard>
            </motion.div>
            {validationResponse && (
              <div className="flex flex-col mt-8">
                <h1 className="text-lg text-white">Services Preview:</h1>
                <hr className="w-[100%] dark:border dark:border-zinc-800 my-4" />
                <div className="grid grid-cols-3 gap-4">
                  {validationResponse?.project.services?.map((service) => (
                    <FlatCard
                      className="bg-secondaryBg p-4 w-full flex flex-row rounded-md border-2 border-gray-300 dark:border-zinc-800 h-full"
                      key={service.name}
                    >
                      <div className="flex flex-col h-full">
                        <div className="flex flex-col">
                          <div className="text-lg">{service.name}</div>
                          <div className="text-xs flex-row flex">
                            <span className="font-bold mr-1">Image:</span>
                            <span className="truncate" title={service.image}>
                              {service.image}
                            </span>
                          </div>
                        </div>
                        {service.ports && service.ports.length > 0 && (
                          <div className="text-xs">
                            <span className="font-bold mr-1">Ports:</span>
                            <span className="">
                              {service.ports
                                .map((port) => port.published)
                                .join(", ")}
                            </span>
                          </div>
                        )}
                      </div>
                    </FlatCard>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-auto">
              {validationResponse ? (
                <Button
                  className="w-full"
                  onClick={() => {
                    onSelectContainer({
                      dockerCompose: {
                        fileUrl:
                          uploadMethod === "github"
                            ? replaceBlobWithRaw(gitUrl)
                            : undefined,
                        yamlString: yamlString || undefined,
                        jupyterInstall: installJupyter,
                        registries: registries,
                      },
                    });
                  }}
                >
                  Save and Continue
                </Button>
              ) : (
                <Button
                  className="w-full"
                  onClick={() => {
                    validateDockerCompose(
                      uploadMethod,
                      uploadMethod === "github" ? gitUrl : yamlString || ""
                    );
                  }}
                  disabled={
                    loadingValidation ||
                    (uploadMethod === "file" && yamlString === "") ||
                    (uploadMethod === "github" && gitUrl === "")
                  }
                >
                  <>
                    {loadingValidation ? (
                      <span className="font-bold">Loading...</span>
                    ) : (
                      <>
                        {uploadMethod === "file" ? (
                          <>
                            <ArrowUpTrayIcon className="w-4 h-4 mr-2" />
                            Validate
                          </>
                        ) : (
                          <>
                            <GitHubLogoIcon className="w-4 h-4 mr-2" />
                            Validate
                          </>
                        )}
                      </>
                    )}
                  </>
                </Button>
              )}
              {validationError && (
                <div className="text-red-500 text-sm mt-4">
                  Error: {validationError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DockerCompose;
