import DevToggle from "components/DevToggle";
import { Environment } from "components/Environment/utils/types";
import NotebookList from "components/NotebookList";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import FlatCard from "components/UI-lib/FlatCard";
import CustomizeVerb from "components/Verb/CustomizeVerb";
import { VerbBuildComponent } from "components/Verb/VerbBuildComponent";
import { getVerbBuildConfigFromWorkspace } from "components/Verb/utils";
import Workspace from "entities/Workspace.entity";
import React, { useEffect, useMemo, useState } from "react";
interface ContainerSettingsProps {
  workspaceId: string;
  setShowButton: (b: boolean) => void;
  environment: Environment;
  initialVerbLoad: boolean;
}
const ContainerSettings: React.FC<ContainerSettingsProps> = (props) => {
  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            View Logs
          </h1>
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        <VerbBuildComponent
          workspaceId={props.workspaceId}
          setShowButton={props.setShowButton}
          environment={props.environment}
          initialVerbLoad={props.initialVerbLoad}
        />
      </div>
    </>
  );
};

export default ContainerSettings;
