import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import config from "config";
import { REQUEST_TIMEOUT } from "../constants";
import { isElectronApp } from "./utils";
import { useKas } from "contexts/KasContext";
import { isKasAuthFlow, KAS_TOKEN_STORAGE_KEY } from "./kas/utils";
import { rewind } from "windups/dist/Windup";

const API_ROOT = config.brevApiUrl;

const getOS = (): string => {
  if (navigator.userAgent.indexOf("Win") != -1) return "windows";
  if (navigator.userAgent.indexOf("Mac") != -1) return "darwin";
  if (navigator.userAgent.indexOf("Linux") != -1) return "linux";
  if (navigator.userAgent.indexOf("Android") != -1) return "android";
  if (navigator.userAgent.indexOf("like Mac") != -1) return "ios";
  return "unknown";
};

export const api = axios.create({
  baseURL: API_ROOT,
  timeout: REQUEST_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    utm_source: isElectronApp() ? "electron" : "web",
    os: getOS(),
  },
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    // (200-299)
    const { data, ...resParams } = response;
    return { success: true, data, ...resParams };
  },
  (error: AxiosError) => {
    // outside of (200-299)
    const errorMessages = (error.response?.data?.errors || []).map(
      (e: Error) => e.message
    );

    console.log();
    console.log("wtf123", error);
    console.error(errorMessages);
    return { success: false, message: errorMessages.join(" ") };
  }
);

export const configureRequestInterceptors = async (
  getAccessTokenSilently: () => Promise<string | null>
) => {
  api.interceptors.request.use(async (config) => {
    const token = isKasAuthFlow
      ? localStorage.getItem(KAS_TOKEN_STORAGE_KEY)
      : await getAccessTokenSilently();
    console.log("RequestConfig", config);

    config.headers.Authorization = token ? `Bearer ${token}` : null;

    return config;
  });
};

/**
 * What we append onto the response object.
 */
export type AxiosRes =
  | {
      success: true;
    }
  | {
      success: false;
      message: string;
    };

/**
 * Base response object.
 * If status is NOT successful (200 - 299), then message appears.
 */
export type BaseRes = {
  message?: string;
};

/**
 * Base params object. Copy of core.ParamsDictionary in express.
 */
export interface BaseParams {
  [key: string]: string;
}

/**
 * Creates a fetch-based POST request function with configurable options
 * @param bearerToken Optional authentication token
 * @returns Function for making POST requests to the API
 */
export const createCustomFetchPost = (bearerToken?: string) => {
  /**
   * Make a POST request to the API
   * @param endpoint API endpoint to call
   * @param body Request payload
   * @param customHeaders Optional additional headers
   * @returns Response with success/error information
   */
  return async (endpoint: string, body: any, customHeaders = {}) => {
    const defaultHeaders: HeadersInit = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (bearerToken) {
      defaultHeaders.Authorization = `Bearer ${bearerToken}`;
    }

    const url = `${API_ROOT}${endpoint}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { ...defaultHeaders, ...customHeaders },
        body: JSON.stringify(body),
        signal: AbortSignal.timeout(REQUEST_TIMEOUT),
      });

      const data = await response.json().catch(() => ({}));

      if (response.ok) {
        // Status code 200-299
        return { success: true, data, status: response.status };
      } else {
        // Status code outside 200-299
        const errorMessages = (data?.errors || [])
          .map((e: Error) => e.message)
          .join(" ");

        return {
          success: false,
          message: errorMessages || "Request failed",
          status: response.status,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error instanceof Error ? error.message : "Unknown error",
      };
    }
  };
};
