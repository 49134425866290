/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Workspace from "../../../../../../entities/Workspace.entity";
import agent, { UpdateWorkspaceReqBody } from "server";
import Toggle from "components/UI-lib/Toggle";
import FlatCard from "components/UI-lib/FlatCard";
import { WorkspaceClassId } from "models/Workspace.model";
import {
  classNames,
  displayCPUs,
  displayRAM,
  isCPU_techdebt,
} from "components/utils";
import { NotificationContext } from "contexts/NotificationContext";
import "./styles.scss";
import Button from "components/UI-lib/Button";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Modal from "components/Modals/Modal";
import { ComputePricingObject, OrgContext } from "contexts/OrgContext";
import { useHistory } from "react-router-dom";
import Spinner from "components/UI-lib/Spinner";
import CPUIcon from "assets/img/svg/cpuicon.svg?react";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import * as Slider from "@radix-ui/react-slider";
import DevToggle from "components/DevToggle";
import { getInstanceTypeCostProductApi } from "utils/CreateWorkspaceUtils";
import { GPUInstanceType } from "./GPUTypes";
import CPUSlider, { CPUInstanceType } from "./CPUSlider";
import GPUSelectorTable from "./GPUSelectorTable";
import { InstanceAttribute } from "./InstanceAttribute";
import CPUSelectorTable from "./CPUSelectorTable";
import { getWorkspaceGroupObject } from "components/Environment/Settings/utils";
import Checkbox from "components/UI-lib/Checkbox";
import {
  formatMemory,
  isGPUfromInstanceType,
} from "components/Environment/utils";
import { Environment } from "components/Environment/utils/types";
import { getEnvironmentStatus } from "components/Environment/utils/environtmentUtils";

// create a simple react component
const CurrentInstanceRow: React.FC<{
  instanceType: GPUInstanceType | undefined;
  diskSize: string | undefined;
}> = ({ instanceType, diskSize }) => {
  // get me the length of instanceType.supported_gpus
  if (!instanceType) return null;
  // const rowCss =
  // "border-b border-gray-200 whitespace-nowrap px-2 py-2 text-md text-gray-500 hidden sm:table-cell px-3 py-4 text-gray-800 font-medium text-center";
  const rowCss =
    " whitespace-nowrap  px-3 py-3.5 text-left bg-cyan-50 dark:bg-highlight text-sm text-gray-500 dark:text-secondary hidden sm:table-cell px-3 py-4 text-gray-800 font-medium";

  return (
    <div className="w-full">
      <table
        className="min-w-full border-separate border border-gray-200 overflow-auto max-h-[45vh]"
        style={{ borderSpacing: 0 }}
      >
        <thead className="w-full ">
          {/* <div className="w-full h-5 bg-green-500"> */}
          <tr className="w-full h-1">
            <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              GPU Name
            </th>
            <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              <div className="group inline-flex">
                GPU Mem
                <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" />
              </div>
            </th>
            {/* <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              <div className="group inline-flex">GPUs</div>
            </th> */}
            <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              <div className="group inline-flex">CPUs</div>
            </th>
            <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              <div className="group inline-flex">RAM</div>
            </th>
            <th
              scope="col"
              className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
            >
              <div className="group inline-flex">Disk</div>
            </th>
          </tr>
        </thead>
        <tbody className="w-full">
          <tr
            className={classNames(
              "w-full",
              "bg-white" // : "hover:bg-gray-50"
            )}
          >
            <td className={rowCss}>
              {instanceType.supported_gpus[0].count} x{" "}
              {instanceType.supported_gpus[0].manufacturer}{" "}
              {instanceType.supported_gpus[0].name.replace("Radeon Pro ", "")}{" "}
            </td>
            <td className={rowCss}>{instanceType.supported_gpus[0].memory}</td>
            {/* <td className={rowCss}>{instanceType.supported_gpus[0].count}</td> */}
            <td className={rowCss}>{instanceType.vcpu}</td>
            <td className={rowCss}>{instanceType.memory}</td>
            <td className={rowCss}>{diskSize}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const OpenDropdownButton: React.FC<{
  isOpen: boolean;
  showBelow: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}> = ({ isOpen, showBelow, disabled }) => (
  <button
    type="button"
    className={classNames(
      " inline-flex items-center justify-center rounded-md border border-transparent bg-highlight px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-highlightLighter focus:ring-offset-2 sm:w-auto",
      disabled ? "opacity-80 cursor-not-allowed" : "opacity-100"
    )}
    onClick={() => {
      showBelow(!isOpen);
    }}
    disabled={disabled}
  >
    Change Hardware
  </button>
);

interface InstanceChangerProps {
  environment: Environment;
  instanceCantBeModified: boolean;
  cantBeModifiedMessage: string;
}

const InstanceChanger: React.FC<InstanceChangerProps> = ({
  environment,
  instanceCantBeModified,
  cantBeModifiedMessage,
}) => {
  const wsContext = useContext(WorkspaceContext);
  const orgContext = useContext(OrgContext);
  const [showGPU, setShowGPU] = useState(true);
  const [userCanAccessGPUs, setUserCanAccessGPUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [instanceType, setInstanceType] = useState("");
  const [currentInstanceTypeObj, setCurrentInstanceTypeObj] =
    useState<GPUInstanceType>();
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertPaymentMessage, setinlineAlertPaymentMessage] = useState(
    "Add a payment method or connect your AWS account to use GPU instances"
  );
  const [
    cantBeModifiedInlineAlertMessage,
    setCantBeModifiedInlineAlertMessage,
  ] = useState(cantBeModifiedMessage);
  const history = useHistory();
  const [workspaceClass, setWorkspaceClass] = useState(
    WorkspaceClassId.TwoByEight
  );
  const [paymentSetup, setPaymentSetup] = useState(false);
  const notificationContext = useContext(NotificationContext);
  const [availableInstancesForThisEnv, setAvailableInstancesForThisEnv] =
    useState<GPUInstanceType[]>([]);
  const [availableCPUInstancesForThisEnv, setAvailableCPUInstancesForThisEnv] =
    useState<GPUInstanceType[]>([]);
  const [spot, setSpot] = useState(false);

  const [i_acknowledge, setI_acknowledge] = useState(false);

  const handleAcknowledgment = (value: boolean) => {
    setI_acknowledge(value);
    // Any other logic you'd like to perform when the form is acknowledged
  };

  const instanceAttributes = useMemo(
    () =>
      orgContext.allInstancesAvailable.find(
        (instance) => instance.type === instanceType
      ),
    [instanceType, orgContext.allInstancesAvailable]
  );

  useEffect(() => {
    if (environment.instance?.instance_type) {
      setInstanceType(environment.instance?.instance_type);
    }
  }, [environment.instance?.instance_type]);

  useEffect(() => {
    const availableInstances = orgContext.GPUInstancesAvailable.filter((i) => {
      if (i.workspace_groups.length > 0) {
        return i.workspace_groups.some(
          (wsg) => wsg.id === environment?.instance?.labels?.workspaceGroupId
        );
      }
      return false;
    });
    setAvailableInstancesForThisEnv(availableInstances);
  }, [orgContext.GPUInstancesAvailable]);

  useEffect(() => {
    const availableInstances = orgContext.CPUInstancesAvailable.filter((i) => {
      if (i.workspace_groups.length > 0) {
        return i.workspace_groups.some(
          (wsg) => wsg.id === environment?.instance?.labels?.workspaceGroupId
        );
      }
      return false;
    });
    setAvailableCPUInstancesForThisEnv(availableInstances);
  }, [orgContext.CPUInstancesAvailable]);

  useEffect(() => {
    const foundGPUInstanceType = availableInstancesForThisEnv.find(
      (instance) => {
        return instance.type === instanceType;
      }
    );
    if (foundGPUInstanceType) {
      setCurrentInstanceTypeObj(foundGPUInstanceType);
    }

    const foundCPUInstanceType = availableCPUInstancesForThisEnv.find(
      (instance) => {
        return instance.type === instanceType;
      }
    );
    if (foundCPUInstanceType) {
      setCurrentInstanceTypeObj(foundCPUInstanceType);
    }
  }, [
    availableInstancesForThisEnv,
    instanceType,
    availableCPUInstancesForThisEnv,
  ]);

  useEffect(() => {
    const handleIsPaymentSetup = async () => {
      const res = await agent.Billing.getSavedCreditInfo(
        orgContext.activeOrgId
      );
      if (res.success && res.data) {
        if (res.data.last4 && res.data.expiration) {
          setPaymentSetup(true);
          return;
        }
      }
      setPaymentSetup(false);
    };

    handleIsPaymentSetup();
  }, [orgContext.activeOrgId]);

  useEffect(() => {
    setUserCanAccessGPUs(
      !(
        orgContext.workspaceGroups.filter(
          (wsg) => wsg.platform === "dev-plane" && wsg.tenantType !== "shared"
        ).length === 0 && !paymentSetup
      )
    );
  }, [orgContext.workspaceGroups, paymentSetup]);

  const updateInstanceType = async () => {
    setLoading(true);
    const reqBody: UpdateWorkspaceReqBody = {};
    reqBody.instanceType = instanceType;
    if (environment) {
      // TODO: update the workspace status
      const res = await wsContext.updateWorkspace(
        environment.workspaceId || "",
        reqBody
      );
      if (res.success) {
        setInlineAlertMessage(
          "Scaling your instance. Hang tight if it's deploying 🤙"
        );
      } else {
        setInlineAlertMessage("Couldn't scale your instance.");
      }
      console.log(res);
      setHasChanged(false);
    } else {
      notificationContext.showNotification(
        "Couldn't scale your instance",
        "Please refresh the page",
        "error"
      );
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-start items-start">
      <Modal
        setOpen={setShowConfirmationModal}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSuccess={() => {
          updateInstanceType();
          setShowConfirmationModal(false);
        }}
        title="Scaling your instances will temporarily stop it."
        body={
          <p className="text-sm text-gray-500 dark:text-secondary">
            Is that okay? It will be restarted automatically
          </p>
        }
        confirmLabel="Yes, scale my instance 🤙"
        severity="warning"
      />
      <FlatCard className="mb-3">
        <div className="mb-2">
          <InlineNotification
            show={!!inlineAlertMessage}
            severity="info"
            text={inlineAlertMessage}
            autoClose={false}
            onClose={() => setInlineAlertMessage("")}
          />
        </div>
        {}
        <div className="mb-2">
          <InlineNotification
            show={
              instanceCantBeModified &&
              cantBeModifiedInlineAlertMessage.length > 0
            }
            severity="info"
            text={cantBeModifiedInlineAlertMessage}
            autoClose={false}
            onClose={() => setCantBeModifiedInlineAlertMessage("")}
          />
        </div>

        <div>
          {showGPU ? (
            <div>
              {availableInstancesForThisEnv.length > 0 && (
                <GPUSelectorTable
                  selectedInstanceType={instanceType}
                  onChange={(i) => {
                    setHasChanged(true);
                    setInstanceType(i);
                  }}
                  initialInstances={availableInstancesForThisEnv}
                  spot={false}
                  setSpot={setSpot}
                  selectedCloudName={
                    getWorkspaceGroupObject(
                      environment?.instance?.labels?.workspaceGroupId || "",
                      orgContext.workspaceGroups
                    )?.name
                  }
                  // ws={workspace}
                  // sortBy={sortBy}
                />
              )}
              {!userCanAccessGPUs && (
                <div style={{ marginTop: "1rem" }}>
                  <InlineNotification
                    show={!!inlineAlertPaymentMessage}
                    severity="info"
                    text={inlineAlertPaymentMessage}
                    text2={
                      <p className="mt-2 ml-8 text-sm text-gray-700 dark:text-secondary">
                        You can do that{" "}
                        <a
                          className="text-gray-700 dark:text-secondary font-medium underline"
                          onClick={() => {
                            history.push(
                              `/org/${orgContext.activeOrgId}/settings`
                            );
                          }}
                        >
                          in your org settings
                        </a>{" "}
                        {/* to connect your AWS account or add a payment method. */}
                      </p>
                    }
                    autoClose={false}
                    onClose={() => setinlineAlertPaymentMessage("")}
                  />
                </div>
              )}
              <div className="flex justify-between items-center pt-5">
                <Button
                  label="Deploy changes"
                  type={
                    userCanAccessGPUs && !instanceCantBeModified
                      ? "primary"
                      : "secondary"
                  }
                  disabled={!userCanAccessGPUs || instanceCantBeModified}
                  loading={loading}
                  onClick={() => {
                    if (
                      getEnvironmentStatus(environment) === "STOPPED" ||
                      getEnvironmentStatus(environment) === "STOPPING"
                    ) {
                      updateInstanceType();
                    } else {
                      setShowConfirmationModal(true);
                    }
                  }}
                />
                <InstanceAttribute
                  instanceType={instanceType}
                  isGPUInstance={isGPUfromInstanceType(
                    instanceType,
                    orgContext.allInstancesAvailable
                  )}
                  gpuName={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].name
                      : ""
                  }
                  gpuManufacturer={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].manufacturer
                      : ""
                  }
                  vcpu={instanceAttributes?.vcpu}
                  memory={formatMemory(
                    instanceAttributes?.memory || "",
                    instanceAttributes &&
                      instanceAttributes?.workspace_groups.length > 0
                      ? instanceAttributes?.workspace_groups[0].platformType ||
                          ""
                      : ""
                  )}
                  gpuMemory={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].memory
                      : ""
                  }
                  gpuCount={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].count
                      : 0
                  }
                />
              </div>
            </div>
          ) : (
            <>
              <CPUSelectorTable
                selectedCloudName={
                  getWorkspaceGroupObject(
                    environment?.instance?.labels?.workspaceGroupId || "",
                    orgContext.workspaceGroups
                  )?.name || ""
                }
                selectedInstanceType={instanceType}
                onChange={(i) => {
                  setHasChanged(true);
                  setInstanceType(i);
                }}
                initialInstances={orgContext.CPUInstancesAvailable}
                spot={false}
                setSpot={setSpot}
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center flex-row mt-3">
                  <Button
                    label="Deploy changes"
                    type={
                      hasChanged && !instanceCantBeModified
                        ? "primary"
                        : "secondary"
                    }
                    disabled={!hasChanged || instanceCantBeModified}
                    loading={loading}
                    onClick={() => {
                      if (
                        getEnvironmentStatus(environment) === "STOPPED" ||
                        getEnvironmentStatus(environment) === "STOPPING"
                      ) {
                        updateInstanceType();
                      } else {
                        setShowConfirmationModal(true);
                      }
                    }}
                  />
                  {!showGPU && (
                    <div>
                      {currentInstanceTypeObj ? (
                        <div className="pl-3 pr-3 text-sm text-gray-500 dark:text-secondary">
                          $
                          {Number(
                            currentInstanceTypeObj.base_price?.amount
                          ).toFixed(2)}{" "}
                          per hour
                        </div>
                      ) : (
                        <div className="mt-5 ml-5">
                          <Spinner type="secondary" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <InstanceAttribute
                  instanceType={instanceType}
                  isGPUInstance={isGPUfromInstanceType(
                    instanceType,
                    orgContext.allInstancesAvailable
                  )}
                  gpuName={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].name
                      : ""
                  }
                  gpuManufacturer={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].manufacturer
                      : ""
                  }
                  vcpu={instanceAttributes?.vcpu}
                  memory={instanceAttributes?.memory}
                  gpuMemory={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].memory
                      : ""
                  }
                  gpuCount={
                    instanceAttributes && instanceAttributes?.supported_gpus
                      ? instanceAttributes?.supported_gpus[0].count
                      : 0
                  }
                />
              </div>
            </>
          )}
        </div>
      </FlatCard>
    </div>
  );
};

export default InstanceChanger;

interface AgreementFormProps {
  onAcknowledge: (value: boolean) => void;
}

const AgreementForm: React.FC<AgreementFormProps> = ({ onAcknowledge }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleSubmit = () => {
    onAcknowledge(checkboxValue);
  };

  return (
    <FlatCard>
      <p className="text-gray-500 dark:text-secondary py-2">
        I understand this GPU & CPU selector wasn't redesigned so it'll be
        uglier than the rest of the Brev console and I won't judge the rest of
        the product experience by this frame.
      </p>
      <Checkbox
        label="I understand and wish to reveal the GPU picker."
        onChange={(res) => setCheckboxValue(res)}
        checked={checkboxValue}
      />

      <Button
        label="Reveal embarassing GPU picker"
        type={checkboxValue ? "primary" : "secondary"}
        disabled={!checkboxValue}
        className="mt-4"
        // loading={startLoading}
        onClick={handleSubmit}
      />
    </FlatCard>
  );
};
