import SecretsPanel from "components/SecretsPanel";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import InputField from "components/UI-lib/InputField";
import {
  CONFIRMATION_TEXT_AFTER,
  CONFIRMATION_TEXT_BEFORE,
  DELETE_ORGANIZATION_CONFIRMATION_TEXT,
  DELETE_ORGANIZATION_CONFIRMATION_TEXT_ADDITIONAL,
  DELETE_ORGANIZATION_HELPER_TEXT,
} from "constants/index";
import { OrgContext } from "contexts/OrgContext";
// import { ToastContext } from "contexts/ToastContext";
import Organization from "../../entities/Organization.entity";
import { HierarchyType } from "models/Secret.model";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import { classNames } from "components/utils";
import ConnectCloud from "./ConnectCloud";
import OrgResourceLimits from "./OrgResourceLimits";
import { BillingContext } from "contexts/BillingContext";

interface ParamTypes {
  orgId: string;
}

interface LocationState {
  segment?: string;
}

export const OrgPageSettingsRedirect: React.FC = () => {
  console.log("Route: /org/settings");
  const router = useHistory();
  const orgContext = useContext(OrgContext);

  useEffect(() => {
    router.push(`/org/${orgContext.activeOrgId}/settings`);
  }, [orgContext.activeOrgId]);

  return <></>;
};

const OrgPageSettings: React.FC = () => {
  console.log("Route: /org/:orgId/settings");
  // const [organization, setOrganization] = useState<Organization | null>(null);
  // const [loading, setLoading] = useState(true);
  const { state } = useLocation<LocationState>();

  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const [deleting, setDeleting] = useState(false);

  const [fullPage, setFullPage] = useState(false);

  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const billingContext = useContext(BillingContext);

  const notificationContext = useContext(NotificationContext);
  const freeTierCredits = 100;
  const history = useHistory();
  const { orgId } = useParams<ParamTypes>();

  const billingRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    document.title = "Organization Settings | Brev.dev";
    if (state?.segment === "billing") {
      billingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (
      history.location.pathname === `/org/${orgContext.activeOrgId}/settings`
    ) {
      setFullPage(true);
    } else {
      setFullPage(false);
    }
  }, [history.location]);

  // useEffect(() => {
  //   const loadOrganization = async () => {
  //     setLoading(true);
  //     const org = await orgContext.getOrg(orgId);
  //     if (org) setOrganization(new Organization(org));
  //     else {
  //       console.log("Unable to load organization.");
  //       notificationContext.showNotification(
  //         "Could not find organization",
  //         "Please try again",
  //         "error"
  //       );
  //     }
  //     setLoading(false);
  //   };
  //   loadOrganization();
  // }, [orgId]);

  const deleteOrganization = async () => {
    setDeleting(true);
    if (orgContext.orgs.length === 1) {
      notificationContext.showNotification(
        "You must be part of at least one organization",
        "Create another one, and then delete this organization",
        "error"
      );
      setDeleting(false);
      return;
    }
    if (orgContext.activeOrg?.id !== undefined) {
      const res = await orgContext.deleteOrg(orgContext.activeOrg?.id);
      if (res.success) {
        history.push("/");
        setDeleteConfirmationText("");
        setDeleteConfirmation(false);
      } else {
        notificationContext.showNotification(
          "Unable to delete orgainzation",
          res.message,
          "error"
        );
      }
    }
    setDeleting(false);
  };

  // if (loading) {
  //   return <>loading...</>;
  // } else
  if (orgContext.activeOrg) {
    return (
      <div className="flex flex-col justify-evenly items-center">
        <div className="mt-3"></div>
        <div
          className={classNames(
            "flex flex-col justify-start items-start",
            fullPage ? "w-4/5" : "w-full"
          )}
        >
          <ConnectCloud />
        </div>

        {/* <div
          className={classNames(
            "flex flex-col justify-start items-start",
            fullPage ? "w-4/5" : "w-full"
          )}
        >
          <SecretsPanel
            hierarchyType={HierarchyType.Organization}
            hierarchyId={orgContext.activeOrg.id || ""}
            displayMetadata
          />
        </div> */}
        <div
          className={classNames(
            "flex mt-3 flex-col justify-start items-start",
            fullPage ? "w-4/5" : "w-full"
          )}
        >
          <OrgResourceLimits />
        </div>

        <div
          className={classNames(
            "flex mt-3  flex-col justify-start items-start",
            fullPage ? "w-4/5" : "w-full"
          )}
        >
          <FlatCard className="mb-3">
            <h1 className="text-xl mb-3 font-medium dark:text-white">
              Danger Zone
            </h1>
            {!showDeleteConfirmation ? (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 dark:bg-rose-600 dark:hover:bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500"
                onClick={() => setDeleteConfirmation(true)}
                // loading={loading}
              >
                Delete This Organization
              </button>
            ) : (
              <FlatCard className="WorkspaceSettings-FlatCard">
                <p className="mt-2 text-sm text-gray-700 dark:text-secondary">
                  {DELETE_ORGANIZATION_HELPER_TEXT}
                </p>
                <p className="mt-2 mb-2 text-sm text-gray-700 dark:text-secondary">
                  Please type{" "}
                  <span className="font-bold">{orgContext.activeOrg.name}</span>{" "}
                  to confirm. In addition, ensure all workspaces in the
                  organization are deleted.
                </p>
                <div className="flex flex-row align-center justify-between">
                  <InputField
                    label=""
                    errorMessage=""
                    value={deleteConfirmationText}
                    onChange={(val: string) => setDeleteConfirmationText(val)}
                    className="flex-grow"
                    // style={{ width: "100%" }}
                  />
                  <div className="flex flex-row justify-end items-center">
                    <Button
                      label="Delete this organization"
                      onClick={deleteOrganization}
                      loading={deleting}
                      style={{ marginRight: "10px" }}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500"
                      // size="small"
                      disabled={
                        deleting ||
                        deleteConfirmationText !== orgContext.activeOrg.name
                      }
                    />
                    <Button
                      label="Cancel"
                      onClick={() => {
                        setDeleteConfirmation(false);
                        setDeleteConfirmationText("");
                      }}
                      type="secondary"
                    />
                  </div>
                </div>
              </FlatCard>
            )}
          </FlatCard>
        </div>
      </div>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default OrgPageSettings;
