import FileViewer from "components/Environment/Launchables/Deploy/shared/FileViewer";
import { useNotebookDetailsFromWorkspace } from "components/Environment/utils";
import { useNotebookDetailsFromEnvironmentFiles } from "components/Environment/utils/environtmentUtils";
import { Environment } from "components/Environment/utils/types";
import HtmlRenderer from "components/Notebooks/HtmlRenderer";
import Workspace from "entities/Workspace.entity";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
interface NotebookSettingsProps {
  environment: Environment;
  notebookList: any[];
}
const NotebookSettings: React.FC<NotebookSettingsProps> = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="flex flex-row items-end ">
        <div className="mr-3">
          {/* <h1 className="text-2xl font-semibold text-gray-900 dark:text-secondary">
            Notebook Preview
          </h1> */}
        </div>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div>
        {props.environment?.build?.files &&
          props.environment?.build?.files?.length > 0 &&
          props.environment?.build?.files?.[0]?.file_url && (
            <FileViewer
              file={props.environment?.build?.files?.[0]?.file_url || ""}
            />
          )}
      </div>
    </>
  );
};

export default NotebookSettings;
