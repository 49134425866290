import IWorkspace, {
  VerbBuildStatus,
  WorkspaceStartStatus,
  WorkspaceStatus,
} from "models/Workspace.model";
import {
  Environment,
  File,
  Status,
  Timestamp,
  Task,
  EnvironmentBuild,
} from "./types";
import { getNotebookNameFromUrl } from ".";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import agent from "server";

export const parseTimestamp = (timestamp: Timestamp | undefined) => {
  // Ensure we have a valid timestamp object
  if (!timestamp || typeof timestamp.seconds === "undefined") {
    return null;
  }

  // Convert seconds to milliseconds (JavaScript Date uses milliseconds)
  const milliseconds = Number(timestamp.seconds) * 1000;

  // Add nanoseconds (convert to milliseconds)
  // Note: JavaScript Date only has millisecond precision, so we lose some precision here
  const nanosToMillis = (timestamp.nanos || 0) / 1000000;

  // Create a new Date object
  return new Date(milliseconds + nanosToMillis);
};

export const doesEnvironmentHaveGpu = (environment: Environment): boolean => {
  return !!(
    environment.instance_type?.supported_gpus &&
    environment.instance_type?.supported_gpus?.length > 0
  );
};

export const getGpuNameFromEnvironment = (
  environment: Environment
): string | undefined => {
  return environment.instance_type?.supported_gpus?.[0]?.name;
};

export const getGpuManufacturerFromEnvironment = (
  environment: Environment
): string | undefined => {
  return environment.instance_type?.supported_gpus?.[0]?.manufacturer;
};

export const getGpuMemoryFromEnvironment = (
  environment: Environment
): string | undefined => {
  return environment.instance_type?.supported_gpus?.[0]?.memory;
};

export const getGpuCountFromEnvironment = (
  environment: Environment
): number | undefined => {
  return environment.instance_type?.supported_gpus?.[0]?.count;
};

export const getVcpuFromEnvironment = (
  environment: Environment
): number | undefined => {
  return environment.instance_type?.vcpu;
};

export const getMemoryFromEnvironment = (
  environment: Environment
): string | number | undefined => {
  return environment.instance_type?.memory;
};

export const getWorkspacePlatformTypeFromEnvironment = (
  environment: Environment
): string => {
  const { data: workspaceGroups, isLoading: workspaceGroupsLoading } = useQuery(
    ["workspaceGroups", environment.labels?.organizationId],
    () =>
      agent.Organizations.getWorkspaceGroups(
        environment.labels?.organizationId || ""
      ),
    {
      enabled: !!environment.labels?.organizationId,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  return useMemo(() => {
    if (workspaceGroupsLoading) {
      return "loading...";
    }

    if (!workspaceGroups?.data) {
      return "Unknown";
    }

    const workspaceGroup = workspaceGroups.data.find(
      (group) => group.id === environment.labels?.workspaceGroupId
    );

    return workspaceGroup?.platformType || "Unknown";
  }, [
    workspaceGroups,
    workspaceGroupsLoading,
    environment.labels?.workspaceGroupId,
  ]);
};

export const getEnvironmentStatus = (
  environment: Environment
): WorkspaceStatus => {
  if (!environment.instance?.status) {
    console.error("Environment status is undefined");
    return WorkspaceStatus.Deploying;
  }

  console.log(
    "-------environment.instance.status----------",
    environment.instance.status
  );
  const { object_status, lifecycle_status } = environment.instance.status;

  // Check object status first
  if (object_status === "pending") {
    return WorkspaceStatus.Deploying;
  }
  if (object_status === "error") {
    return WorkspaceStatus.Failure;
  }

  // Handle lifecycle status
  switch (lifecycle_status) {
    case "pending":
      // Note: We can't exactly replicate the backend logic here since we don't have
      // access to runningAt. We might need to add that to your Environment type
      return WorkspaceStatus.Starting;

    case "running":
      return WorkspaceStatus.Running;

    case "stopping":
      return WorkspaceStatus.Stopping;

    case "stopped":
      return WorkspaceStatus.Stopped;

    case "suspending":
      return WorkspaceStatus.Stopping;

    case "suspended":
      return WorkspaceStatus.Stopped;

    case "terminating":
      // Note: The container check logic from backend would need to be
      // replicated here if needed
      return WorkspaceStatus.Deleting;

    case "terminated":
      return WorkspaceStatus.Stopped;

    case "failed":
      return WorkspaceStatus.Failure;

    case "":
      return WorkspaceStatus.Deleting;

    default:
      console.error(`Unknown lifecycle status: ${lifecycle_status}`);
      return WorkspaceStatus.Failure;
  }
};

export const transformWorkspaceToEnvironment = (
  workspace: IWorkspace
): Environment | null => {
  if (!workspace.environment) {
    return null;
  }
  return {
    ...workspace.environment,
    workspaceId: workspace.id,
    tunnel: workspace.tunnel,
    healthCheck: workspace.healthCheck,
    additionalUsers: workspace.additionalUsers,
  };
};

export function useNotebookDetailsFromEnvironmentFiles(
  files: File[],
  notebookList
) {
  const notebookDetails = useMemo(() => {
    if (!files || !files.length || !notebookList) {
      return { name: "", path: "" };
    }
    const url = files[0].file_url;
    const currentName = getNotebookNameFromUrl(url || "");
    const found = notebookList.find((n) => {
      const extractedName = getNotebookNameFromUrl(n.file);
      return extractedName === currentName;
    });

    if (found) {
      return {
        name: currentName,
        path: found.name.replace(".html", ""),
        found: true,
      };
    }

    return { name: currentName, path: url, found: false };
  }, [files, notebookList]);

  return notebookDetails;
}

// Define the task status constants
export const TaskStatus = {
  WAITING: "waiting",
  RUNNING: "running",
  SUCCEEDED: "succeeded",
  CANCELED: "canceled",
  FAILED: "failed",
} as const;

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus];

// Define the task name constants
export const TaskName = {
  ENVIRONMENT_BUILD: "environment-build",
  ENVIRONMENT_PROVISION: "environment-provision",
  START_ENVIRONMENT_INSTANCE: "start-environment-instance",
} as const;

export type TaskName = (typeof TaskName)[keyof typeof TaskName];

// Helper functions to check environment states
export function isEnvironmentBuilding(tasks: Task[]): boolean {
  const currentTask = getLatestTask(TaskName.ENVIRONMENT_BUILD, tasks);
  if (!currentTask) {
    return false;
  }
  return currentTask.status === TaskStatus.RUNNING;
}

export function isEnvironmentWaiting(tasks: Task[]): boolean {
  const waitingBuildTasks = tasks.filter(
    (task) =>
      task.name === TaskName.ENVIRONMENT_BUILD &&
      task.status === TaskStatus.WAITING
  );
  return waitingBuildTasks.length > 0;
}

export function didEnvironmentSucceed(tasks: Task[]): boolean {
  const currentTask = getLatestTask(TaskName.ENVIRONMENT_BUILD, tasks);
  if (!currentTask) {
    return false;
  }
  return currentTask.status === TaskStatus.SUCCEEDED;
}

export function didEnvironmentFail(tasks: Task[]): boolean {
  const currentTask = getLatestTask(TaskName.ENVIRONMENT_BUILD, tasks);
  if (!currentTask) {
    return false;
  }
  return currentTask.status === TaskStatus.FAILED;
}

// Helper function to get the latest task by name
export function getLatestTask(taskName: string, tasks: Task[]): Task | null {
  const filteredTasks = tasks.filter((task) => task.name === taskName);
  if (filteredTasks.length === 0) {
    return null;
  }

  // Sort tasks by creation time, most recent first
  const sortedTasks = [...filteredTasks].sort((a, b) => {
    const timeA = parseTimestamp(a.create_time);
    const timeB = parseTimestamp(b.create_time);
    // Handle cases where timestamps might be null
    if (!timeA && !timeB) return 0;
    if (!timeA) return 1; // b comes first if a is null
    if (!timeB) return -1; // a comes first if b is null

    // Safe to subtract now that we've handled null cases
    return timeB.getTime() - timeA.getTime();
  });

  return sortedTasks[0];
}

/**
 * Determines the verb build status based on the state of tasks
 * @param tasks Array of tasks
 * @returns The current verb build status
 */
export function getVerbBuildStatusFromTasks(tasks: Task[]): VerbBuildStatus {
  if (isEnvironmentBuilding(tasks)) {
    return VerbBuildStatus.Building;
  } else if (isEnvironmentWaiting(tasks)) {
    return VerbBuildStatus.Pending;
  } else if (didEnvironmentSucceed(tasks)) {
    return VerbBuildStatus.Completed;
  } else if (didEnvironmentFail(tasks)) {
    return VerbBuildStatus.CreateFailed;
  } else {
    return "" as VerbBuildStatus;
  }
}

// Constants for task names
const TaskNameStartEnvironmentInstance = "start-environment-instance";

// Constants for error messages
const insufficientResourcesMessage =
  "zone has insufficient resources to fulfill the request";
const insufficientQuotaMessage =
  "out of quota in the region fulfill the requested";

/**
 * Checks if the environment start task is currently running
 * @param tasks Array of tasks to check
 * @returns True if the environment is starting, false otherwise
 */
function isEnvironmentStarting(tasks: Task[]): boolean {
  const currentTask = getLatestTask(TaskNameStartEnvironmentInstance, tasks);
  if (currentTask === null) {
    return false;
  }
  return currentTask.status === TaskStatus.RUNNING;
}

/**
 * Checks if there are any waiting environment start tasks
 * @param tasks Array of tasks to check
 * @returns True if there are waiting start tasks, false otherwise
 */
function isEnvironmentStartWaiting(tasks: Task[]): boolean {
  const waitingStartTasks = tasks.filter(
    (task) =>
      task.name === TaskNameStartEnvironmentInstance &&
      task.status === TaskStatus.WAITING
  );
  return waitingStartTasks.length > 0;
}

/**
 * Checks if the latest environment start task succeeded
 * @param tasks Array of tasks to check
 * @returns True if the environment start succeeded, false otherwise
 */
function didEnvironmentStartSucceed(tasks: Task[]): boolean {
  const currentTask = getLatestTask(TaskNameStartEnvironmentInstance, tasks);
  if (currentTask === null) {
    return false;
  }
  return currentTask.status === TaskStatus.SUCCEEDED;
}

/**
 * Checks if the latest environment start task failed and returns the failure message if any
 * @param tasks Array of tasks to check
 * @returns A tuple with [didFail, message]
 */
function didEnvironmentStartFail(tasks: Task[]): [boolean, string] {
  const currentTask = getLatestTask(TaskNameStartEnvironmentInstance, tasks);
  if (currentTask === null) {
    return [false, ""];
  }
  if (currentTask.status === TaskStatus.FAILED) {
    return [true, currentTask.message || ""];
  }
  return [false, ""];
}

/**
 * Formats a failure message for workspace start failures
 * @param message The raw error message
 * @returns A formatted workspace status message
 */
export function getWorkspaceStartFailureMessageFromTasks(
  tasks: Task[]
): string {
  const [didFail, message] = didEnvironmentStartFail(tasks);
  if (didFail) {
    if (message.includes(insufficientResourcesMessage)) {
      return insufficientResourcesMessage;
    } else if (message.includes(insufficientQuotaMessage)) {
      return insufficientQuotaMessage;
    }
    return message;
  } else {
    return "";
  }
}

/**
 * Determines both the start status and status message from the environment tasks
 * @param tasks Array of tasks to analyze
 * @returns A tuple with [startStatus, statusMessage]
 */
export function getWorkspaceStartStatusFromTasks(
  tasks: Task[]
): WorkspaceStartStatus {
  // Determine status based on task states
  if (isEnvironmentStarting(tasks)) {
    return WorkspaceStartStatus.Starting;
  } else if (isEnvironmentStartWaiting(tasks)) {
    return WorkspaceStartStatus.Starting;
  } else if (didEnvironmentStartSucceed(tasks)) {
    return WorkspaceStartStatus.Started;
  } else {
    const [didFail, message] = didEnvironmentStartFail(tasks);
    if (didFail) {
      return WorkspaceStartStatus.Failure;
    } else {
      return WorkspaceStartStatus.Unset;
    }
  }
}

/**
 * Checks if two EnvironmentBuild objects are equal by comparing all their properties
 */
export const isEnvironmentBuildSame = (
  a?: EnvironmentBuild,
  b?: EnvironmentBuild
): boolean => {
  if (a === b) return true;
  if (!a || !b) return false;

  // Compare verb builds
  if (!isEqual(a.verb, b.verb)) return false;

  // Compare vm builds
  if (!isEqual(a.vm, b.vm)) return false;

  // Compare container builds
  if (!isEqual(a.container, b.container)) return false;

  // Compare docker compose builds
  if (!isEqual(a.docker_compose, b.docker_compose)) return false;

  // Compare ingress gateway arrays
  if (!isEqual(a.ingress_gateway, b.ingress_gateway)) return false;

  // Compare lifecycle scripts arrays
  if (!isEqual(a.lifecycle_scripts, b.lifecycle_scripts)) return false;

  // Compare files arrays
  if (!isEqual(a.files, b.files)) return false;

  return true;
};

/**
 * Helper function to check if two values are equal, handling undefined values
 */
const isEqual = <T,>(a?: T, b?: T): boolean => {
  if (a === b) return true;
  if (!a || !b) return true; // Both undefined/null are considered equal
  return JSON.stringify(a) === JSON.stringify(b);
};

export const doesPlatformSupportFirewallRules = (
  platformType: string
): boolean => {
  return (
    platformType === "crusoe" ||
    platformType === "aws" ||
    platformType === "gcp"
  );
};
