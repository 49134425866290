import InputField from "components/UI-lib/InputField";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NotificationContext } from "contexts/NotificationContext";
import Pricing from "components/OrgPages/Billing/ConnectCard";
import DevToggle from "components/DevToggle";
import ReactCardFlip from "react-card-flip";
import Feed from "components/UI-lib/Feed";
import {
  classNames,
  fallbackCopyTextToClipboard,
  getInstancesBelongingToCertainClouds,
  roundPriceToTwoDigits,
} from "components/utils";
import { GPUInstanceType } from "../../Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { FlatCardToggleButtons } from "components/UI-lib/FlatCard/FlatCardToggleButtons";
import {
  ConfigurationTitle,
  LoadingDots,
  allPublicCloudsName,
  convertToGPUMap,
} from "../utils";
import ChipToggleButtons from "./ChipToggleButtons";
import Toggle from "components/UI-lib/Toggle";
import Spinner from "components/UI-lib/Spinner";
import CPUSelectorTable from "components/Environment/Settings/Tabs/Compute/InstanceChanger/CPUSelectorTable";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { AdvancedFilters } from "./AdvancedFilters";
import { prependZeroToCost } from "utils/CreateWorkspaceUtils";
import { PlayIcon } from "@heroicons/react/24/outline";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { setegid } from "process";

import type { DatapondAttachment } from "../../shared/DatapondAttachments";
import { DatapondAttachments } from "../../shared/DatapondAttachments";
import { UserContext } from "contexts/UserContext";
import { isNvidiaEmail } from "utils";

interface ComputePickerProps {
  setSelectedInstance(instanceObj: GPUInstanceType | undefined): void;
  selectedInstance: GPUInstanceType | undefined;
  setStorage(size: string): void;
  storage: string;
  setDataPondAttachments?(attachments: DatapondAttachment[]): void;
  dataPondAttachments?: DatapondAttachment[];
  disableCpu?: boolean;
}

const ComputePicker: React.FC<ComputePickerProps> = (props) => {
  const userContext = useContext(UserContext);
  const orgContext = useContext(OrgContext);
  const [panel, setPanel] = useState<"GPU" | "CPU">(
    props.selectedInstance
      ? props.selectedInstance.supported_gpus
        ? "GPU"
        : "CPU"
      : "GPU"
  );
  const flagDatapondEnabled = useFeatureFlagEnabled("show-datapond");
  const [clicksWhileLoading, setClicksWhileLoading] = useState<number>(0);
  const [selectedChip, setSelectedChip] = useState<string>(
    props.selectedInstance?.supported_gpus
      ? props.selectedInstance?.supported_gpus[0].name || "L40S"
      : "L40S"
  );
  const [isCompressed, setIsCompressed] = useState<boolean>(false);
  const [spot, setSpot] = useState<boolean>(false);
  const [enableDataPond, setEnableDataPond] = useState<boolean>(false);

  const [filteredOptions, setFilteredOptions] = useState<
    {
      value: GPUInstanceType;
      price: any;
    }[]
  >([]);
  const [selectedCloudName, setSelectedCloudName] =
    useState<string>(allPublicCloudsName);
  const [chipDropDownInstanceTypes, setChipDropDownInstanceTypes] = useState<
    GPUInstanceType[]
  >([]);
  const [runningCost, setRunningCost] = useState<number>(0);
  const [selectedCPUInstance, setSelectedCPUInstance] = useState<
    GPUInstanceType | undefined
  >(
    !props.selectedInstance?.supported_gpus ? props.selectedInstance : undefined
  );
  const [selectedGPUInstance, setSelectedGPUInstance] = useState<
    GPUInstanceType | undefined
  >(
    !!props.selectedInstance?.supported_gpus
      ? props.selectedInstance
      : undefined
  );

  const gpuMap = useMemo(() => {
    let gpuInstances = orgContext.GPUInstancesAvailable;
    //Filter akash
    gpuInstances = gpuInstances.filter((instance) => {
      return instance.workspace_groups[0].platformType !== "akash";
    });

    return convertToGPUMap(gpuInstances);
  }, [orgContext.GPUInstancesAvailable, userContext.me]);

  const chipList = useMemo(() => {
    return Object.keys(gpuMap).map((chipName) => {
      return {
        name: chipName,
        manufacturer: gpuMap[chipName][0].supported_gpus[0].manufacturer,
      };
    });
  }, [gpuMap]);

  //Initialize the Picker with the selected instance
  useEffect(() => {
    if (props.selectedInstance) {
      if (!!props.selectedInstance.supported_gpus) {
        setPanel("GPU");
        const instanceTypes = gpuMap[selectedChip];
        if (!instanceTypes) {
          return;
        }

        // Check if the current selectedInstanceType still exists in the new list
        const existingInstanceType = instanceTypes.find(
          (instanceType) => instanceType.type === props.selectedInstance?.type
        );

        // If it doesn't exist, reset or set it to another default value
        if (!existingInstanceType) {
          props.setSelectedInstance(undefined); // or set to another default value if desired
        }
        setChipDropDownInstanceTypes(instanceTypes);
      } else {
        setPanel("CPU");
      }
    } else {
      const instanceTypes = gpuMap[selectedChip];
      if (!instanceTypes) {
        return;
      }
      setChipDropDownInstanceTypes(
        instanceTypes.filter(
          (instance) => instance.workspace_groups[0].id !== "dgxc-gcp"
        )
      );
    }
  }, [gpuMap, selectedChip, props.selectedInstance]);

  const handlePanelChange = (newPanel: "CPU" | "GPU") => {
    if (newPanel === "CPU") {
      console.log(selectedCPUInstance);
      props.setSelectedInstance(selectedCPUInstance || undefined);
    } else if (newPanel === "GPU") {
      props.setSelectedInstance(selectedGPUInstance || undefined);
      console.log(selectedGPUInstance);
    }
    setPanel(newPanel);
  };

  useEffect(() => {
    setRunningCost(
      prependZeroToCost(
        Number(props.selectedInstance?.base_price?.amount || 0) +
          Number(props.storage) *
            Number(
              props.selectedInstance?.supported_storage[0].price_per_gb_hr
                ?.amount || 0
            )
      )
    );
  }, [props.selectedInstance, spot, props.storage]);

  const computeOptions = () => {
    if (panel === "GPU") {
      return chipDropDownInstanceTypes
        ? chipDropDownInstanceTypes.map((instanceType) => {
            return {
              value: instanceType,
              price: roundPriceToTwoDigits(
                prependZeroToCost(
                  Number(instanceType?.base_price?.amount)
                ).toString()
              ),
            };
          })
        : [];
    } else {
      // Assuming panel === "CPU" for CPU instances
      return orgContext.CPUInstancesAvailable
        ? orgContext.CPUInstancesAvailable.map((instanceType) => {
            return {
              value: instanceType,
              price: prependZeroToCost(
                Number(instanceType?.base_price?.amount)
              ).toString(),
            };
          })
        : [];
    }
  };

  const getStorageUnavailableReason = () => {
    if (props.selectedInstance) {
      return "Select an instance to see storage";
    } else {
      return "...";
    }
  };

  useEffect(() => {
    if (props.selectedInstance) {
      setIsCompressed(true);
    } else {
      setIsCompressed(false);
    }
  }, [props.selectedInstance]);

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <h3
          style={{
            fontSize: orgContext.isInstancesLoading
              ? `${1.5 + 0.25 * clicksWhileLoading}rem`
              : "1.875rem", // Starts at text-3xl (1.875rem) and increases by 0.25rem for each click
          }}
          className="font-medium leading-6 text-gray-900 dark:text-white flex flex-row items-center justify-start"
        >
          {orgContext.isInstancesLoading ? (
            <>
              Getting instances <LoadingDots />
            </>
          ) : (
            `Select your ${panel === "GPU" ? "GPU" : "CPU"}`
          )}
        </h3>
        {!props.disableCpu && (
          <Toggle
            // labelBefore="GPU"
            label="Show CPUs"
            checked={panel === "CPU"}
            onChange={() => {
              handlePanelChange(panel === "GPU" ? "CPU" : "GPU");
            }}
          />
        )}
      </div>
      <p className="text-md mt-2 text-gray-700 dark:text-secondary mb-4">
        You can configure it below.
      </p>

      <ReactCardFlip isFlipped={panel === "CPU"} flipDirection="horizontal">
        <>
          {panel === "GPU" && (
            <>
              <ChipToggleButtons
                allGPUInstances={orgContext.GPUInstancesAvailable}
                isLegacyPicker={true}
                selectedCloud={selectedCloudName}
                selectedChip={selectedChip}
                setSelectedChip={
                  orgContext.isInstancesLoading
                    ? () => {
                        setClicksWhileLoading(clicksWhileLoading + 1);
                      }
                    : (chipName) => {
                        setSelectedChip(chipName);
                        props.setSelectedInstance(undefined);
                      }
                }
                listOfChips={chipList}
                // loading={true}
                loading={orgContext.isInstancesLoading}
              />
              {!orgContext.isInstancesLoading ? (
                <>
                  <div className="mt-5 mb-2 flex justify-between items-center">
                    <ConfigurationTitle isCompressed={isCompressed} />

                    {props.selectedInstance && (
                      <button
                        className="flex flex-row justify-center items-center ml-2 btn btn-primary text-slate-500 dark:text-secondary underline"
                        onClick={() => setIsCompressed(!isCompressed)}
                      >
                        {!isCompressed
                          ? "Hide configurations"
                          : "Show all configurations"}

                        {!isCompressed ? (
                          <ChevronDownIcon width={18} />
                        ) : (
                          <ChevronRightIcon width={18} />
                        )}
                      </button>
                    )}
                  </div>

                  {!props.selectedInstance && (
                    <AdvancedFilters
                      options={computeOptions()}
                      setFilteredOptions={setFilteredOptions}
                      selectedCloudName={selectedCloudName}
                      setSelectedCloudName={setSelectedCloudName}
                      isGPU={panel === "GPU"}
                    />
                  )}

                  {orgContext.isInstancesLoading ? (
                    <>
                      <p className="help-text StepperCard-HelpText text-slate-500 dark:text-secondary flex items-center">
                        {"Connecting to clouds for available configurations"}
                        <LoadingDots />
                      </p>
                    </>
                  ) : (
                    <>
                      {selectedChip === "" && (
                        <p className="help-text StepperCard-HelpText text-slate-500 dark:text-secondary flex items-center">
                          {
                            "Select a chip above to see available configurations"
                          }
                        </p>
                      )}
                      <>
                        {gpuMap[selectedChip] ? (
                          <FlatCardToggleButtons
                            options={
                              props.selectedInstance
                                ? computeOptions()
                                : filteredOptions
                            }
                            selectedOption={props.selectedInstance}
                            setSelectedOption={(gpu) => {
                              props.setSelectedInstance(gpu);
                              setSelectedGPUInstance(gpu);
                            }}
                            isCompressed={isCompressed}
                            setIsCompressed={setIsCompressed}
                          />
                        ) : selectedChip !== "" ? (
                          <>
                            <p className="text-md mt-2 text-gray-700 dark:text-highlight">
                              There is currently no availability for the{" "}
                              {selectedChip} 😭
                            </p>
                            <p className="text-md mt-2 text-gray-700 dark:text-slate-500">
                              Try selecting a different chip or check back later
                              to see if it's available
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>

        <>
          {/* Note: checking the panel here saves an unecessary rerender. Hacky workaround but it works so im keeping it */}
          {panel === "CPU" && orgContext.CPUInstancesAvailable.length > 0 ? (
            <>
              <CPUSelectorTable
                selectedCloudName={selectedCloudName}
                initialInstances={orgContext.CPUInstancesAvailable}
                selectedInstanceType={props.selectedInstance?.type || ""}
                onChange={(i) => {
                  // setHasChanged(true);
                  let t = orgContext.CPUInstancesAvailable.find(
                    (e) => e.type === i
                  );
                  if (!!t) {
                    props.setSelectedInstance(t);
                    setSelectedCPUInstance(t);
                  }
                }}
                spot={spot}
                setSpot={setSpot}
              />
            </>
          ) : (
            <div className="flex flex-row justify-start items-center pl-3">
              <Spinner type="secondary" />
              <p className="text-sm text-gray-700 dark:text-cyan-300">
                Fetching available CPUs...
              </p>
            </div>
          )}
        </>
      </ReactCardFlip>
      {!orgContext.isInstancesLoading && (
        <>
          <div className="flex flex-col">
            <div>
              <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
              <div className="storage-options flex flex-row items-center justify-between">
                <div className="w-1/3 pr-1">
                  {props.selectedInstance?.elastic_root_volume ||
                  panel === "CPU" ? (
                    <>
                      <InputField
                        label="Storage (GiB)"
                        placeholder="leave blank for default (60GiB)"
                        value={props.storage}
                        onChange={(e) => {
                          if (Number(e) <= 16000) {
                            props.setStorage(e);
                          }
                        }}
                        errorMessage=""
                        onKeyDown={(e) => {
                          if (
                            !/[0-9\b]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  ) : (
                    <InputField
                      label="Storage (GiB)"
                      placeholder="leave blank for default (60GiB)"
                      value={`${
                        props.selectedInstance?.supported_storage &&
                        props.selectedInstance?.supported_storage.length &&
                        props.selectedInstance?.supported_storage.length > 0 &&
                        props.selectedInstance?.supported_storage[0].size
                          ? props.selectedInstance?.supported_storage[0].size
                          : getStorageUnavailableReason()
                      }`}
                      onChange={() => {}}
                      disabled
                      errorMessage=""
                      onKeyDown={(e) => {
                        if (
                          !/[0-9\b]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                </div>
                {flagDatapondEnabled && (
                  <div className="w-1/3 pr-1">
                    <Toggle
                      label="Enable DataPond"
                      checked={enableDataPond}
                      onChange={() => setEnableDataPond(!enableDataPond)}
                      description="Enable DataPond for this workspace"
                    />
                  </div>
                )}
              </div>
            </div>

            {enableDataPond &&
              props.dataPondAttachments &&
              props.setDataPondAttachments && (
                <>
                  <DatapondAttachments
                    attachments={props.dataPondAttachments}
                    onChange={(attachments) => {
                      if (props.setDataPondAttachments) {
                        props.setDataPondAttachments(attachments);
                      }
                    }}
                  />
                </>
              )}
            <div
              className={classNames(
                "flex flex-row justify-between items-center",
                !props.selectedInstance ? "hidden" : "visible"
              )}
            >
              <div>
                {panel === "CPU" && (
                  <div>
                    {orgContext.CPUInstancesAvailable ? (
                      // Object.keys(orgContext.computePricing).length > 0
                      <div className="mt-2 flex items-center text-sm text-gray-700 dark:text-secondary">
                        {runningCost == 0 ? (
                          <Spinner type="secondary" />
                        ) : (
                          <div className="flex flex-col">
                            <p className="font-semibold">Running Rate</p>
                            <div className="flex flex-row">
                              <PlayIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700 dark:text-secondary"
                                aria-hidden="true"
                              />
                              {!isNaN(runningCost) || runningCost !== 0 ? (
                                <div className="text-sm text-gray-700 dark:text-secondary">
                                  ${runningCost.toFixed(2)} per hour
                                  &nbsp;&nbsp;
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mt-2 flex items-center text-sm text-gray-700 dark:text-secondary">
                        <Spinner type="secondary" /> Loading latest prices
                      </div>
                    )}
                  </div>
                )}
                {panel === "GPU" && (
                  <div>
                    {!orgContext.isInstancesLoading ? (
                      <div className="flex items-center text-sm text-gray-700 dark:text-secondary">
                        <div className="flex flex-col">
                          <p className="font-semibold">Running Rate</p>
                          <div className="flex flex-row text-gray-700 dark:text-secondary">
                            <PlayIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700 dark:text-secondary"
                              aria-hidden="true"
                            />
                            {runningCost === 0 ? (
                              <div className="mr-2">Price not available</div>
                            ) : (
                              <>
                                ${runningCost.toFixed(2)} per hour &nbsp;&nbsp;
                              </>
                            )}
                          </div>
                        </div>
                        {/* <div className="flex flex-col">
                        <p className="font-semibold">Stopped Rate</p>
                        <div className="flex flex-row">
                          <PauseIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700 dark:text-secondary"
                            aria-hidden="true"
                          />
                          <p className="text-sm text-gray-700 dark:text-secondary">
                            ${stoppedCost.toFixed(3)} per hour
                          </p>
                        </div>
                      </div> */}
                      </div>
                    ) : (
                      <Spinner type="secondary" />
                    )}
                  </div>
                )}
              </div>
              {!props.selectedInstance?.preemptible && (
                <div className="w-1/3 ml-10">
                  <Toggle
                    label="SPOT"
                    // sublabel="(save 60%)"
                    checked={spot}
                    onChange={() => setSpot(!spot)}
                    description="Save 60% by using SPOT instances when available"
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        </>
      )}
    </>
  );
};

export default ComputePicker;
