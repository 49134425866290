import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import FlatCard from "components/UI-lib/FlatCard";
import agent, { WorkspaceGroup } from "server";
import { OrgContext } from "contexts/OrgContext";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import CloudMultiplex from "./CloudMultiplex";
import ConnectedCloudDetails from "./ConnectedCloudDetails";

const ConnectCloud: React.FC = () => {
  const [refreshPending, setRefreshPending] = useState(false);
  const [updateDefaultLoading, setUpdateDefaultLoading] = useState(false);
  const [defaultChanged, setDefaultChanged] = useState(false);

  const [defaultWorkspaceGroupId, setDefaultWorkspaceGroupId] =
    useState<string>("");
  const [privateWorkspaceGroups, setPrivateWorkspaceGroups] = useState<
    WorkspaceGroup[]
  >([]);
  const orgContext = useContext(OrgContext);
  const notificaitonContext = useContext(NotificationContext);

  /**
   * Connecting Clouds
   */

  const updateDefaultWorkspaceGroup = async (id: string) => {
    const wsGroup = privateWorkspaceGroups.find((wsg) => wsg.id === id);
    if (!wsGroup) {
      notificaitonContext.showNotification(
        "Couldn't find workspace group",
        "Try refreshing the page before trying again",
        "error"
      );
      return;
    }

    setUpdateDefaultLoading(true);
    const res = await agent.Organizations.updateDefaultWorkspaceGroup(
      orgContext.activeOrgId,
      wsGroup
    );
    if (res.success) {
      console.log(res);
      setUpdateDefaultLoading(false);
      setDefaultChanged(false);
      orgContext.setDefaultWorkspaceGroupForActiveOrg(wsGroup.id);
      orgContext.refreshWorkspaceGroups(true);
      notificaitonContext.showNotification(
        "Org updated successfully!",
        "The workspace group default has been updated",
        "success"
      );
    } else {
      console.error(res.message);
      setUpdateDefaultLoading(false);
      setDefaultChanged(false);
      notificaitonContext.showNotification(
        "Couldn't update the workspace group",
        "",
        "error"
      );
    }
  };

  useEffect(() => {
    // Brev Public GCP WSG unify to Brev AWS to just show Brev Cloud
    if (defaultWorkspaceGroupId === "GCP") {
      setDefaultWorkspaceGroupId("devplane-brev-1");
    }
  }, [defaultWorkspaceGroupId]);

  useEffect(() => {
    if (orgContext.workspaceGroups) {
      const privateClouds = orgContext.workspaceGroups
        .filter((wsg) => wsg.platform === "dev-plane")
        .filter((wsg) => wsg.tenantType !== "shared");
      // Add Back Brev Cloud
      const brevSecure = orgContext.workspaceGroups.find(
        (wsg) => wsg.id === "devplane-brev-1"
      );
      if (brevSecure) {
        brevSecure.name = "Brev Cloud";
        privateClouds.push(brevSecure);
      }
      setPrivateWorkspaceGroups(privateClouds);
      if (
        orgContext.activeOrg &&
        orgContext.activeOrg?.defaultWorkspaceGroupId != ""
      ) {
        setDefaultWorkspaceGroupId(
          orgContext.activeOrg.defaultWorkspaceGroupId
        );
      } else if (orgContext.workspaceGroups.length > 0) {
        setDefaultWorkspaceGroupId(orgContext.workspaceGroups[0].id);
      }
    }
  }, [orgContext.workspaceGroups]);

  return (
    <FlatCard className="mb-3">
      {/* <h1 className="text-xl mb-3 font-medium dark:text-white">
        Connect your cloud
      </h1>
      <p className="mb-2 text-sm text-gray-700 dark:text-secondary">
        Connect your cloud account to Brev.dev to deploy the instances in your
        cloud
      </p>

      <CloudMultiplex /> */}
      <div>
        {/* <div className="mt-2 text-sm text-gray-700 dark:text-secondary flex">
          {refreshPending && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="#0891b2"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}{" "}
          Already connected your cloud?{" "}
          <a
            className="text-gray-900 dark:text-white font-medium underline ml-1"
            onClick={async () => {
              setRefreshPending(true);
              await orgContext.refreshWorkspaceGroups(true);
              setRefreshPending(false);
            }}
          >
            Click here to refresh.
          </a>{" "}
        </div>

        <h3 className="text-xl mt-3 font-medium dark:text-white">
          Connected Clouds:
        </h3>
        <hr className="mt-3 mb-3 dark:border dark:border-zinc-800" /> */}

        {privateWorkspaceGroups.length > 0 && (
          <>
            {privateWorkspaceGroups.map((wsg) => {
              const readableTime = new Date(wsg.createdAt).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              );

              return (
                <ConnectedCloudDetails
                  wsg={wsg}
                  // isDefaultWorkspaceGroup={
                  //   orgContext.activeOrg?.defaultWorkspaceGroupId
                  //     ? wsg.id === orgContext.activeOrg.defaultWorkspaceGroupId
                  //     : wsg.id === defaultWorkspaceGroupId
                  // }
                  // displayValue={
                  //   wsg.name !== "Brev Cloud"
                  //     ? `${wsg.name} (Added ${
                  //         wsg.createdAt.split("T")[0]
                  //       }, ${readableTime})`
                  //     : wsg.name
                  // }
                  // depreciateButton
                />
              );
            })}
          </>
        )}

        {defaultChanged && (
          // button to save
          <Button
            label="Update Default Cloud"
            loading={updateDefaultLoading}
            className="mt-3"
            type="secondary"
            onClick={() => {
              updateDefaultWorkspaceGroup(defaultWorkspaceGroupId);
            }}
          />
        )}
      </div>
    </FlatCard>
  );
};

export default ConnectCloud;

interface DropdownProps {
  children: React.ReactNode;
  label: string;
  onChange: (item: any) => void;
  value: string;
  displayFunction?: (item: any) => any;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const [selected, setSelected] = useState(props.value);
  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return (
    <Listbox
      value={selected}
      onChange={(s) => {
        setSelected(s);
        props.onChange(s);
      }}
    >
      {({ open }) => (
        <div className="flex flex-col">
          <Listbox.Label className="block text-sm font-medium text-gray-70 dark:text-secondary">
            {props.label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white dark:bg-zinc-900 relative w-full border border-gray-300 dark:border-zinc-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-highlightLighter focus:border-highlight sm:text-sm">
              <span className="block truncate">
                {props.displayFunction
                  ? props.displayFunction(selected)
                  : selected}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white dark:bg-zinc-900 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black dark:ring-zinc-800 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {props.children}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

interface DropdownItemProps {
  value: string;
  displayValue: string;
  depreciateButton?: boolean;
  isDefaultWorkspaceGroup?: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const orgContext = useContext(OrgContext);
  const notificaitonContext = useContext(NotificationContext);

  return (
    <Listbox.Option
      key={props.value}
      className={({ active }) =>
        classNames(
          active
            ? "text-white bg-highlight"
            : "text-gray-900 dark:text-secondary dark:bg-zinc-900",
          "flex cursor-default select-none relative py-2 pl-3 pr-9"
        )
      }
      value={props.value}
    >
      {({ selected, active }) => (
        <>
          <span
            className={classNames(
              selected ? "font-semibold" : "font-normal",
              "block truncate"
            )}
          >
            {props.displayValue}
          </span>

          {selected ? (
            <span
              className={classNames(
                active ? "text-white" : "text-cyan-600",
                "absolute inset-y-0 right-0 flex items-center pr-4"
              )}
            >
              {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
            </span>
          ) : null}
          {props.depreciateButton && props.displayValue !== "Brev Cloud" ? (
            <button
              type="button"
              className={classNames(
                props.isDefaultWorkspaceGroup
                  ? "text-gray-300 dark:text-secondary"
                  : "text-black dark:text-secondary",
                "h-[20px] absolute right-0 flex items-center pr-4 rounded-md border border-gray-200 dark:border-zinc-800 rounded-md shadow-sm text-sm font-medium  bg-white dark:bg-zinc-900 hover:bg-gray-100 dark:hover:bg-zinc-700 focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 dark:focus:ring-offset-zinc-900 focus:ring-highlightLighter sm:w-auto px-4 py-2  mr-1"
              )}
              onClick={async (e) => {
                e.preventDefault();
                if (props.isDefaultWorkspaceGroup) {
                  notificaitonContext.showNotification(
                    "Cannot deprecate this Cloud as it is the one you are currently on",
                    "Change your default Cloud to deprecate this one",
                    "error"
                  );
                } else {
                  const res = await agent.Organizations.depracateWorkspaceGroup(
                    props.value
                  );
                  if (res && res.success) {
                    orgContext.refreshWorkspaceGroups(true);
                    notificaitonContext.showNotification(
                      "Successfully deprecated cloud",
                      "You will need to reconnect it to use it again",
                      "success"
                    );
                  }
                }
              }}
            >
              Deprecate
            </button>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};
