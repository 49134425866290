import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { FlatCard } from "components/UI-lib";
import { Spinner } from "@kui-react/spinner";
import { useLocation } from "react-router";
import { UserContext } from "contexts/UserContext";
import { Button } from "components/Graphs/Button";
import { api, AxiosRes, BaseRes } from "server/config";
import { OrgContext } from "contexts/OrgContext";

const LaunchableMigrateNotice: React.FC = () => {
  const { me, isLoading: userLoading } = useContext(UserContext) || {};
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect") || "";
  const [countdown, setCountdown] = useState(10);
  const timerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [migrationToken, setMigrationToken] = useState("");
  const orgContext = useContext(OrgContext);
  // Check if user has external identities
  const hasExternalIdentities = Boolean(
    me && me?.externalIdentities && me?.externalIdentities?.length >= 2
  );

  // Create redirect URL
  const redirectUrl = redirect.startsWith("https://brev.nvidia.com")
    ? redirect
    : `https://brev.nvidia.com${redirect}`;

  const initiateMigration = async () => {
    setIsLoading(true);
    try {
      const response = (await api.post(
        "/api/users/initiate-migration"
      )) as AxiosRes & BaseRes;

      // Based on your axios interceptor, response will have this structure:
      // { success: boolean, data?: any, message?: string }
      if (!response.success) {
        throw new Error(response.message || "Migration initiation failed");
      }

      // If successful, the migration token will be in response.data
      const migrationToken = (response as any).data.migrationToken;
      if (!migrationToken) {
        throw new Error("No migration token received");
      }
      setMigrationToken(migrationToken);
    } catch (error) {
      console.error("Migration error:", error);
      // You might want to add error handling UI here
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userLoading) return;
    if (!hasExternalIdentities && me) {
      initiateMigration();
    }
  }, [hasExternalIdentities, me]);

  // Handle redirect
  const handleRedirect = useCallback(() => {
    if (hasExternalIdentities) {
      window.location.href = redirectUrl;
    } else {
      window.location.href = `https://login.brev.nvidia.com/signin?migrationToken=${migrationToken}&redirect_uri=${encodeURIComponent(
        redirectUrl
      )}&activeOrgId=${orgContext.activeOrgId}`;
    }
  }, [
    redirect,
    redirectUrl,
    migrationToken,
    hasExternalIdentities,
    orgContext.activeOrgId,
  ]);

  // Set up countdown timer
  useEffect(() => {
    if (!hasExternalIdentities) {
      return; // Don't start countdown if no external identities
    }

    const endTime = Date.now() + 8000;

    const updateCountdown = () => {
      const remaining = Math.ceil((endTime - Date.now()) / 1000);

      if (remaining <= 0) {
        handleRedirect();
        return;
      }

      setCountdown(remaining);
      timerRef.current = setTimeout(updateCountdown, 200);
    };

    updateCountdown();

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [handleRedirect, hasExternalIdentities]);

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-3">
      <div className="px-4 sm:px-6 lg:px-8 text-white">
        <div className="flex flex-col items-center justify-center h-screen">
          <FlatCard className="p-8 mx-auto text-center">
            {hasExternalIdentities ? (
              <>
                <div className="flex flex-col items-center gap-4">
                  <span className="text-2xl font-bold">
                    brev.nvidia.com Account Found
                  </span>
                  <div>
                    You have a brev.nvidia.com account, redirecting you in{" "}
                    {countdown} seconds...
                  </div>
                </div>
                <div className="mt-8">
                  <Spinner />
                </div>
                <div className="mt-8 flex flex-row items-center justify-center">
                  <Button onClick={handleRedirect}>Redirect Now</Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col items-center gap-4">
                  <span className="text-2xl font-bold">
                    Console.brev.dev has moved to brev.nvidia.com
                  </span>
                  <span>
                    We notice you have not yet migrated your account. In order
                    to do that click the button below. You will associate or
                    create a new NVIDIA account. All your instances,
                    organizations, and credits will be maintained through the
                    transition.
                  </span>
                </div>
                <div className="mt-8">
                  <Spinner />
                </div>
                <div className="mt-8 flex flex-row items-center justify-center">
                  {!isLoading && migrationToken && (
                    <Button onClick={handleRedirect}>Redirect Now</Button>
                  )}
                </div>
              </>
            )}
          </FlatCard>
        </div>
      </div>
    </div>
  );
};

export default LaunchableMigrateNotice;
