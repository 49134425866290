/*
  NOTE: this context should've been made a long time ago

  In the components around the WorkspaceCreate page, 
  once we passed hooks thru components via props, that seems like
  an indicator of needing a context to wrap the data.

  In hindsight, that comment^ is a DUH. 

  It would be a good idea to move any access of those components up here. Introducing... New Tech Debt ™️

*/

import { exec } from "child_process";
import {
  NamesCPUTypes,
  arrayToPathMap,
  useQuery,
  yamlRawDump,
} from "components/utils";
import { CPU_INSTANCE_TYPES, GPU_INSTANCE_TYPES } from "constants/index";
import PathExec from "../entities/PathExec.entity";
import StringExec from "../entities/StringExec.entity";
import { IRepo } from "models/Repo.model";
import { VerbBuildStatus, WorkspaceClassId } from "models/Workspace.model";
import React, { useContext, useEffect, useState } from "react";
// import { workspaceCreateContextDefaultValues } from "tests/defaultValues";
import { string } from "yup";
import agent, { ReadFileRes } from "server";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import Workspace from "../entities/Workspace.entity";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { getVerbBuildStatusFromTasks } from "components/Environment/utils/environtmentUtils";

export interface VerbConfigType {
  build: {
    system_packages: string[];
    python_version: string;
    cuda: string;
    python_packages?: string[];
    run?: string[];
  };
  services?: {
    name: string;
    entrypoint: string;
    ports: string[];
  }[];
  user?: {
    shell?: string;
    authorized_keys_path?: string;
  };
  ports?: string[];
}

export const DEFAULT_VERB_CONFIG: VerbConfigType = {
  build: {
    system_packages: [],
    python_version: "3.10",
    cuda: "12.0.1",
    python_packages: ["jupyterlab"],
    run: [
      'sh -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)" "" --unattended',
    ],
  },
  user: {
    shell: "zsh",
  },
};

export const getClassIDDefaultFromQuery = (instance: string) => {
  if (CPU_INSTANCE_TYPES.includes(instance)) {
    return instance as WorkspaceClassId;
  }
  return WorkspaceClassId.TwoByEight;
};

export const getInstanceTypeDefaultFromQuery = (instance: string) => {
  if (getIsCPUDefaultFromQuery(instance)) {
    if (NamesCPUTypes[instance]) {
      return NamesCPUTypes[instance];
    }
    return "";
  }
  return instance;
};

export const getIsCPUDefaultFromQuery = (instance: string) => {
  if (instance.length > 0) {
    const firstLetter = instance[0];
    // if first char is number then it's a CPU instance
    if (isNaN(parseInt(firstLetter))) {
      return false;
    }
    // GPU instance type
    return true;
  }
  // Default to GPU instance types
  return false;
};

export const getImageDefaultFromQuery = (image: string) => image || "";
export const getRegionDefaultFromQuery = (region: string) => region || "";
export const getArchitectureDefaultFromQuery = (architecture: string) =>
  architecture || "";

interface IWorkspaceCreateContext {
  hitBuildButton: boolean;
  setHitBuildButton: (b: boolean) => void;

  buildButtonDisabled: boolean;
  setBuildButtonDisabled: (b: boolean) => void;

  isVerbBuilding: boolean;
  setIsVerbBuilding: (b: boolean) => void;

  verbYaml: string;
  setVerbYaml: (instanceType: string) => void;

  instanceType: string;
  setInstanceType: (instanceType: string) => void;

  storage: string;
  setStorage: (storage: string) => void;

  workspaceClassId: WorkspaceClassId;
  setWorkspaceClassId: (classID: WorkspaceClassId) => void;

  repos: IRepo[];
  setRepos: (r: IRepo[]) => void;

  execs: (StringExec | PathExec)[];
  setExecs: (e: (StringExec | PathExec)[]) => void;

  isCPU: boolean;
  setisCPU: (isCPU: boolean) => void;

  savingsEnabled: boolean;
  setSavingsEnabled: (isCPU: boolean) => void;

  image: string;
  setImage: (image: string) => void;

  region: string;
  setRegion: (region: string) => void;

  architecture: string;
  setArchitecture: (architecture: string) => void;

  resetValues: () => void;

  spot: boolean;
  setSpot: (spot: boolean) => void;

  openProvisioner: boolean;
  setOpenProvisioner: (openProvisioner: boolean) => void;

  queryParams: { [key: string]: string };
  setQueryParams: (queryParams: { [key: string]: string }) => void;

  notebookNotFound: boolean;
  setNotebookNotFound: (notebookNotFound: boolean) => void;

  // Fields related to BuildVerbContainerOnWorkspace and ReadLogFileFromMachine
  verbBuildLogFilePath: string;
  setVerbBuildLogFilePath: (path: string) => void;
  logFileContent: string;
  setLogFileContent: (content: string) => void;
  isBuilding: boolean;
  setIsBuilding: (isBuilding: boolean) => void;
  BuildVerbContainerOnWorkspace: (
    workspaceID: string,
    verbYaml: string
  ) => Promise<any>;
  ReadLogFileFromMachine: (workspaceID: string) => Promise<ReadFileRes>;
  inlineAlertMessage: string;
  inlineAlertAction: any;
  inlineAlertSeverity: "error" | "warning" | "info" | "success";
  setInlineAlertMessage: (message: string) => void;
}

interface RouteParams {
  workspaceId: string;
}

export const WorkspaceCreateContext =
  React.createContext<IWorkspaceCreateContext>({
    hitBuildButton: false,
    setHitBuildButton: () => {},

    buildButtonDisabled: false,
    setBuildButtonDisabled: () => {},

    isVerbBuilding: false,
    setIsVerbBuilding: () => {},

    verbYaml: "",
    setVerbYaml: (s: string) => {},

    instanceType: "",
    setInstanceType: (s: string) => {},

    storage: "",
    setStorage: (s: string) => {},

    workspaceClassId: WorkspaceClassId.TwoByFour,
    setWorkspaceClassId: (s: string) => {},

    repos: [],
    setRepos: (r: IRepo[]) => {},

    isCPU: true,
    setisCPU: () => {},

    savingsEnabled: false,
    setSavingsEnabled: () => {},

    image: "",
    setImage: () => {},

    region: "",
    setRegion: () => {},

    architecture: "",
    setArchitecture: () => {},

    execs: [],
    setExecs: () => {},

    resetValues: () => {},

    spot: false,
    setSpot: () => {},

    openProvisioner: false,
    setOpenProvisioner: () => {},

    queryParams: {},
    setQueryParams: (queryParams: { [key: string]: string }) => {},

    notebookNotFound: false,
    setNotebookNotFound: () => {},

    // Default values related to BuildVerbContainerOnWorkspace and ReadLogFileFromMachine
    verbBuildLogFilePath: "",
    setVerbBuildLogFilePath: () => {},
    logFileContent: "",
    setLogFileContent: () => {},
    isBuilding: false,
    setIsBuilding: () => {},
    BuildVerbContainerOnWorkspace: async () => {},
    ReadLogFileFromMachine: async (workspaceID: string) => {
      return {
        data: { content: "some content" },
        success: true,
        message: "message here",
      };
    },
    inlineAlertMessage: "",
    inlineAlertAction: <></>,
    inlineAlertSeverity: "error",
    setInlineAlertMessage: () => {},
  });

interface Props {
  children: React.ReactNode;
}
const WorkspaceCreateContextProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const query = useQuery();

  const [hitBuildButton, setHitBuildButton] = React.useState(false);
  const [buildButtonDisabled, setBuildButtonDisabled] = React.useState(false);

  const [isVerbBuilding, setIsVerbBuilding] = React.useState(false);
  const [openProvisioner, setOpenProvisioner] = React.useState(false);
  // const [verbYaml, setVerbYaml] = React.useState(query.get("verbYaml") || "");
  const [verbYaml, setVerbYaml] = React.useState(
    query.get("verbYaml") || yamlRawDump(DEFAULT_VERB_CONFIG)
  );

  const [savingsEnabled, setSavingsEnabled] = React.useState(false);

  const [storage, setStorage] = React.useState(
    query.get("diskStorage") || "45"
  );
  const [instanceType, setInstanceType] = React.useState(
    getInstanceTypeDefaultFromQuery(query.get("instance") || "")
  );
  const [workspaceClassId, setWorkspaceClassId] =
    React.useState<WorkspaceClassId>(
      getClassIDDefaultFromQuery(query.get("instance") || "")
    );

  const [repos, setRepos] = useState<IRepo[]>([]);
  const [execs, setExecs] = useState<(PathExec | StringExec)[]>([]);
  const [isCPU, setisCPU] = React.useState(
    getIsCPUDefaultFromQuery(query.get("instance") || "")
  );
  const [image, setImage] = React.useState(
    getImageDefaultFromQuery(query.get("image") || "")
  );
  const [region, setRegion] = React.useState(
    getRegionDefaultFromQuery(query.get("region") || "us-west-2")
  );
  const [architecture, setArchitecture] = React.useState(
    getArchitectureDefaultFromQuery(query.get("architecture") || "x86_64")
  );

  const [queryParams, setQueryParams] = React.useState<{
    [key: string]: string;
  }>({});

  const [notebookNotFound, setNotebookNotFound] = React.useState(false);

  const [spot, setSpot] = React.useState(false);
  const [loadingWs, setLoadingWs] = React.useState("");

  // States related to BuildVerbContainerOnWorkspace and ReadLogFileFromMachine
  const [verbBuildLogFilePath, setVerbBuildLogFilePath] = useState(
    "$HOME/.verb-setup.log"
  );
  const [logFileContent, setLogFileContent] = useState("");
  const [isBuilding, setIsBuilding] = useState(false); // to track if a build is in progress
  const [inlineAlertMessage, setInlineAlertMessage] = useState("");
  const [inlineAlertAction, setInlineAlertAction] = useState<any>(<></>);
  const [inlineAlertSeverity, setInlineAlertSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const [idempotencyKey, setIdempotencyKey] = useState(uuid());
  const workspaceContext = useContext(WorkspaceContext);

  // Move the logic of BuildVerbContainerOnWorkspace and ReadLogFileFromMachine into the context
  const BuildVerbContainerOnWorkspace = async (
    workspaceID: string,
    verbYaml: string
  ) => {
    setLoadingWs(workspaceID);
    setIsBuilding(true);
    setIsVerbBuilding(true);
    setLogFileContent("");
    const res = await agent.Workspaces.buildVerbContainerOnWorkspace(
      workspaceID,
      {
        verbYaml,
        idempotencyKey,
      }
    );
    console.log(res);
    if (res.success && res.data) {
      setVerbBuildLogFilePath(res.data.logFilePath);
    } else if (res.message === "") {
      setVerbBuildLogFilePath("$HOME/.verb-setup.log");
    } else {
      console.log(res);
      // DOING a hack because this error message makes no sense lol
      if (
        res.message &&
        res.message.includes(
          "error from makeRequest: No route for that URI (7000)"
        )
      ) {
        setIsVerbBuilding(false);
      } else {
        setInlineAlertMessage(`Error building verb container: ${res.message}`);
        setInlineAlertSeverity("error");
        setInlineAlertAction(<></>);
        setIsVerbBuilding(false);
      }
    }
    setIdempotencyKey(uuid());
    setIsBuilding(false);
    return res;
  };

  const ReadLogFileFromMachine = async (workspaceID: string) => {
    setLoadingWs(workspaceID);

    const res = await agent.Workspaces.readFileFromWorkspace(workspaceID, {
      filePath: verbBuildLogFilePath,
    });
    if (res.success && res.data && res.data.content !== "\n") {
      if (!logFileContent.includes(res.data.content)) {
        setLogFileContent(logFileContent + res.data.content);
      }
      // const joinedLogFileContent = logFileContent + res.data.content;
      // console.log("joined log file content: ", joinedLogFileContent);
      // setLogFileContent(logFileContent + res.data.content);
    } else {
      console.log(res);
    }
    return res;
  };

  const getPathParams = () => {
    // Assuming your URL is something like /org/ejmrvoj8m/environments/ba2p55abp
    const pathParts = window.location.pathname.split("/");
    return {
      orgId: pathParts[2], // ejmrvoj8m
      workspaceId: pathParts[4], // ba2p55abp
    };
  };

  // Reset the values as we hit a new page
  useEffect(() => {
    resetValues();
  }, [location.pathname]);

  useEffect(() => {
    const { orgId, workspaceId } = getPathParams();

    // Find the workspace by ID from the URL
    const environment = workspaceContext.environments.find(
      (w) => w?.workspaceId === workspaceId
    );

    // If found and verbYaml exists, log it
    if (
      environment?.build?.verb?.config &&
      environment?.build?.verb?.config !==
        "# Verb Instance Provisioned With Brev Deploy" &&
      getVerbBuildStatusFromTasks(environment?.tasks || []) !==
        VerbBuildStatus.Unset
    ) {
      setVerbYaml(environment?.build?.verb?.config);
    }
  }, [workspaceContext.environments, location.pathname]);

  useEffect(() => {
    setStorage(query.get("diskStorage") || "45");
    setInstanceType(
      getInstanceTypeDefaultFromQuery(query.get("instance") || "")
    );
    setWorkspaceClassId(
      getClassIDDefaultFromQuery(query.get("instance") || "")
    );
    setisCPU(getIsCPUDefaultFromQuery(query.get("instance") || ""));
    setImage(getImageDefaultFromQuery(query.get("image") || ""));
    setRegion(getRegionDefaultFromQuery(query.get("region") || "us-west-2"));
    setArchitecture(
      getArchitectureDefaultFromQuery(query.get("architecture") || "x86_64")
    );
  }, []);

  useEffect(() => {
    setOpenProvisioner(false);
  }, [location.pathname]);

  const resetValues = () => {
    setHitBuildButton(false);
    setBuildButtonDisabled(false);
    setIsVerbBuilding(false);
    setVerbYaml(yamlRawDump(DEFAULT_VERB_CONFIG));
    setSavingsEnabled(false);
    setStorage("45");
    setInstanceType(getInstanceTypeDefaultFromQuery(""));
    setWorkspaceClassId(getClassIDDefaultFromQuery(""));
    setRepos([]);
    setExecs([]);
    setisCPU(getIsCPUDefaultFromQuery(""));
    setImage(getImageDefaultFromQuery(""));
    setRegion(getRegionDefaultFromQuery("us-west-2"));
    setArchitecture(getArchitectureDefaultFromQuery("x86_64"));
    setQueryParams({});
    setSpot(false);
    setVerbBuildLogFilePath("$HOME/.verb-setup.log");
    setLogFileContent("");
    setIsBuilding(false);
    setInlineAlertMessage("");
    setInlineAlertAction(<></>);
    setInlineAlertSeverity("error");
    // If there are more state variables that need to be reset, add them here.
  };

  return (
    <WorkspaceCreateContext.Provider
      value={{
        hitBuildButton,
        setHitBuildButton,
        buildButtonDisabled,
        setBuildButtonDisabled,
        isVerbBuilding,
        setIsVerbBuilding,
        verbYaml,
        setVerbYaml,
        instanceType,
        setInstanceType,
        storage,
        setStorage,
        workspaceClassId,
        setWorkspaceClassId,
        repos,
        setRepos,
        isCPU,
        setisCPU,
        savingsEnabled,
        setSavingsEnabled,
        image,
        setImage,
        region,
        setRegion,
        architecture,
        setArchitecture,
        execs,
        setExecs,
        resetValues,
        spot,
        setSpot,
        verbBuildLogFilePath,
        setVerbBuildLogFilePath,
        logFileContent,
        setLogFileContent,
        isBuilding,
        setIsBuilding,
        BuildVerbContainerOnWorkspace,
        ReadLogFileFromMachine,
        inlineAlertMessage,
        inlineAlertAction,
        inlineAlertSeverity,
        setInlineAlertMessage,
        openProvisioner,
        setOpenProvisioner,
        queryParams,
        setQueryParams,
        notebookNotFound,
        setNotebookNotFound,
      }}
    >
      {children}
    </WorkspaceCreateContext.Provider>
  );
};

export default WorkspaceCreateContextProvider;
