import { useQuery } from "@tanstack/react-query";
import { prependKasUrl, KAS_HOST, redirectUserToLogin } from "../utils";
import { useHistory, useLocation } from "react-router-dom";
import { isPublicRoute } from "SecuredRoute/isPublicRoute";

type UserContextResponse = {
  name: string;
  orgName: string;
  userId: string;
};

export const useGetContext = ({
  isKasAuthFlow,
}: {
  isKasAuthFlow: boolean;
}) => {
  const location = useLocation();
  const history = useHistory();
  const queryKey = "/user-context";
  return useQuery({
    queryFn: async (): Promise<UserContextResponse> =>
      fetch(prependKasUrl(queryKey), {
        headers: { host: KAS_HOST },
        credentials: "include",
        method: "GET",
      })
        .then(async (response) => {
          if (response.ok) {
            return await response.json();
          } else if (location.pathname.includes("/launchable/deploy/now")) {
            const searchParams = new URLSearchParams(location.search);
            //forceLogin=true is used to force the user to login before redirecting to the launchable deploy page
            if (!!searchParams.get("forceLogin")) {
              redirectUserToLogin();
            } else {
              window.location.href = `${location.pathname.replace(
                "/launchable/deploy/now",
                "/launchable/deploy"
              )}${location.search}`;
            }
          } else {
            if (!isPublicRoute(location.pathname)) {
              redirectUserToLogin();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          redirectUserToLogin();
        }),
    queryKey: [queryKey],
    refetchInterval: 10_000,
    enabled: isKasAuthFlow,
  });
};
