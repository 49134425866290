import { VerbBuildStatus, WorkspaceStatus } from "models/Workspace.model";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Chip from "../Create/chips";
import { DarkModeContext } from "contexts/DarkModeContext";
import {
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
} from "@heroicons/react/24/solid";
import { getWorkspaceGroupObject } from "../Settings/utils";
import InstanceStatusIndicator from "./InstanceStatusIndicator";
interface ConstanterStatusIndicatorProps {
  instanceStatus: WorkspaceStatus;
  containerStatus: VerbBuildStatus;
}
const ConstanterStatusIndicator: React.FC<ConstanterStatusIndicatorProps> = (
  props
) => {
  return (
    <>
      {props.instanceStatus !== WorkspaceStatus.Stopped &&
        props.instanceStatus !== WorkspaceStatus.Stopping &&
        props.instanceStatus !== WorkspaceStatus.Deleting &&
        props.instanceStatus !== WorkspaceStatus.Failure && (
          <InstanceStatusIndicator
            workspaceStatus={
              props.instanceStatus === WorkspaceStatus.Starting ||
              props.instanceStatus === WorkspaceStatus.Deploying
                ? VerbBuildStatus.Pending
                : props.containerStatus || VerbBuildStatus.Pending
            }
          />
        )}
    </>
  );
};

export default ConstanterStatusIndicator;
