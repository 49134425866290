import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ComputeSelector from "./ComputeSelector";
import ContainerSelector from "./ContainerSelector";
import "./style.scss";
import { classNames, validateName } from "components/utils";
import {
  ArrowUpRightIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";

import InlineNotification from "contexts/Notifications/InlineNotifications";

import { Badge } from "components/Graphs/Badge";
import FilterBar from "./ComputeSelector/Advanced/FilterBar";
import { ContainerSelected, parseDockerComposeUrl } from "../shared/BuildTypes";
import { GPUInstanceType } from "../Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { allPublicCloudsName } from "./utils";
import { NameEnvironment } from "./EnvironmentCreate";
import { Button } from "components/Graphs/Button";
import agent from "server";
import { useQuery } from "@tanstack/react-query";
import UnauthenticatedDash from "components/DashboardContainer/UnauthenticatedDash";
import { useHistory } from "react-router";

export interface EnvironmentCreateProps {}

const filterForGPUInstances = (instances: GPUInstanceType[]) => {
  return instances
    .filter((gpu) => gpu.supported_gpus)
    .filter((gpu) => gpu.supported_gpus[0].manufacturer !== "Habana")
    .filter((gpu) => gpu.supported_gpus[0].manufacturer !== "AMD");
};

const EnvironmentCreatePublic: React.FC = () => {
  const history = useHistory();

  const [container, setContainer] = useState<ContainerSelected>({
    vmBuild: {
      forceJupyterInstall: false,
    },
  });
  const [pythonVersion, setPythonVersion] = useState<string>("3.10");
  const [cudaVersion, setCudaVersion] = useState<string>("12.0.1");

  const [environmentName, setEnvironmentName] =
    useState<string>("awesome-gpu-name");
  const [nameFieldErrorMessage, setNameFieldErrorMessage] =
    useState<string>("");
  const [selectedInstanceType, setSelectedInstanceType] =
    useState<GPUInstanceType>();
  const [storage, setStorage] = useState<string>("256");
  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);
  const [displayGPUInstances, setDisplayGPUInstances] = useState<
    GPUInstanceType[]
  >([]);
  const [allGPUInstances, setAllGPUInstances] = useState<GPUInstanceType[]>([]);

  const getAllPublicInstanceTypes = async () => {
    const res = await agent.Instances.getAllPublicInstanceTypes();
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch workspaces");
    }
    return res.data;
  };

  const { data: instancesWithErrors, isLoading: isInstancesLoading } = useQuery(
    {
      queryKey: ["instances-with-errors"],
      queryFn: () => getAllPublicInstanceTypes(),
    }
  );

  useEffect(() => {
    if (instancesWithErrors) {
      const gpuInstances = filterForGPUInstances(
        instancesWithErrors.allInstanceTypes
      );
      setAllGPUInstances(gpuInstances);
      setDisplayGPUInstances(gpuInstances);
    }
  }, [instancesWithErrors]);

  const handleNameChange = (val: string) => {
    setEnvironmentName(val);

    // Set validation error messaging
    if (val === "") {
      setNameFieldErrorMessage("Name required!");
      return;
    }
    const isValid = validateName(val);
    if (!isValid) {
      setNameFieldErrorMessage(
        "Name can only have letters, numbers and dashes."
      );
      return;
    }
    setNameFieldErrorMessage("");
  };

  return (
    <UnauthenticatedDash>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-10 pt-3">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between mt-8">
            <div className="flex">
              <h1 className="text-xl  sm:text-2xl font-bold text-gray-900 dark:text-slate-100">
                {!container && "Select your Container"}
                {container && !selectedInstanceType && "Select your Compute"}
                {container && !!selectedInstanceType && "Configure and Deploy"}
              </h1>
            </div>
            <div className="flex-row items-end hidden sm:flex">
              {container && !selectedInstanceType && (
                <FilterBar
                  allGPUInstances={allGPUInstances}
                  setDisplayGPUInstances={setDisplayGPUInstances}
                  selectedCloud={selectedCloudName}
                  setSelectedCloud={setSelectedCloudName}
                  containerSelected={container}
                  displayGPUInstances={displayGPUInstances}
                />
              )}
            </div>
          </div>
          <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
          <div className="flex flex-col">
            <AnimatePresence initial={false}>
              {!container && (
                <motion.section
                  key="container"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{
                    duration: 0.8,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <motion.div
                    variants={{
                      collapsed: { scale: 0.8 },
                      open: { scale: 1 },
                    }}
                    transition={{ duration: 0.8 }}
                    className="content-placeholder"
                  >
                    <ContainerSelector
                      hideTitle={true}
                      containerSelected={container}
                      onSelectContainer={setContainer}
                      pythonVersion={pythonVersion}
                      setPythonVersion={setPythonVersion}
                      cudaVersion={cudaVersion}
                      setCudaVersion={setCudaVersion}
                    />
                  </motion.div>
                </motion.section>
              )}
              {container && (
                <>
                  <ComputeSelector
                    allGPUInstances={filterForGPUInstances(
                      instancesWithErrors?.allInstanceTypes || []
                    )}
                    isLoadingInstances={isInstancesLoading}
                    displayGPUInstances={displayGPUInstances}
                    setDisplayGPUInstances={setDisplayGPUInstances}
                    selectedInstance={selectedInstanceType}
                    setSelectedInstance={setSelectedInstanceType}
                    setStorage={setStorage}
                    storage={storage}
                    containerSelected={container}
                    selectedCloudName={selectedCloudName}
                    setSelectedCloudName={setSelectedCloudName}
                  />
                  {!!selectedInstanceType && (
                    <div className="flex flex-col mt-10">
                      <div>
                        <Button
                          onClick={() => {
                            window.open(
                              `${
                                window.location.origin
                              }/environment/new?instanceType=${
                                selectedInstanceType.type
                              }&storage=${
                                selectedInstanceType.elastic_root_volume
                                  ? storage
                                  : ""
                              }`
                            );
                          }}
                        >
                          <span>Sign In to Deploy</span>
                          <ArrowUpRightIcon className="ml-1 w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </UnauthenticatedDash>
  );
};

export default EnvironmentCreatePublic;
