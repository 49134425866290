import React, { useState, useEffect } from "react";
import { marked, Tokens } from "marked";
import { FlatCard } from "components/UI-lib";

// Configure marked options
marked.setOptions({
  gfm: true,
  breaks: true,
  mangle: false,
  headerIds: true,
});

const processImageInLink = (text: string) => {
  // Match image markdown syntax
  const imageRegex = /!\[([^\]]*)\]\(([^)]*)\)/;
  const match = text.match(imageRegex);

  if (match) {
    const [_, altText, imageSrc] = match;
    return `<img src="${imageSrc}" alt="${altText}" class="max-w-full p-2 h-auto rounded-lg cursor-pointer" loading="lazy" />`;
  }

  return text;
};

const renderer = {
  // Block-level renderers remain the same...
  blockquote(token: Tokens.Blockquote) {
    return `<blockquote class="border-l-4 border-gray-300 pl-4 italic my-4 text-gray-200">${token.text}</blockquote>`;
  },

  code(token: Tokens.Code) {
    const className = token.lang ? `language-${token.lang}` : "";
    return `<pre class="bg-gray-800 p-4 rounded-lg overflow-x-auto my-4">
        <code class="${className} text-gray-200">${token.text}</code>
      </pre>`;
  },

  heading(token: Tokens.Heading) {
    const slug = token.text.toLowerCase().replace(/[^\w]+/g, "-");
    return `<h${
      token.depth
    } id="${slug}" class="text-2xl font-bold my-4 text-white">
        ${marked.parseInline(token.text)}
      </h${token.depth}>`;
  },

  hr(token: Tokens.Hr) {
    return '<hr class="my-8 border-gray-600" />';
  },

  list(token: Tokens.List) {
    const type = token.ordered ? "ol" : "ul";
    const className = token.ordered ? "list-decimal" : "list-disc";
    const start =
      token.start && token.start !== 1 ? ` start="${token.start}"` : "";
    const items = token.items
      .map((item) => {
        const checkbox = item.task
          ? `<input type="checkbox" ${
              item.checked ? "checked" : ""
            } disabled class="mr-2" />`
          : "";
        const parsedText = marked.parseInline(item.text);
        return `<li class="mb-2 text-gray-200">${checkbox}${parsedText}</li>`;
      })
      .join("");

    return `<${type}${start} class="${className} pl-6 my-4">${items}</${type}>`;
  },

  paragraph(token: Tokens.Paragraph) {
    if (token.pre) {
      return `<pre class="my-4">${token.text}</pre>`;
    }
    return `<p class="my-4 text-gray-200">${marked.parseInline(
      token.text
    )}</p>`;
  },

  table(token: Tokens.Table) {
    const createRow = (cells: Tokens.TableCell[], isHeader: boolean) => {
      const cellTag = isHeader ? "th" : "td";
      return `<tr class="border-b border-white">
          ${cells
            .map((cell, i) => {
              const align = token.align[i];
              const alignClass = align ? `text-${align}` : "";
              return `<${cellTag} class="px-4 py-2 text-gray-200 ${alignClass}">${marked.parseInline(
                cell.text
              )}</${cellTag}>`;
            })
            .join("")}
        </tr>`;
    };

    return `
      <div class="overflow-x-auto my-4">
        <table class="min-w-full border-collapse border border-white rounded-md">
          <thead class="bg-zinc-900">${createRow(token.header, true)}</thead>
          <tbody class="bg-secondaryBg">
            ${token.rows.map((row) => createRow(row, false)).join("")}
          </tbody>
        </table>
      </div>
    `;
  },

  // Inline-level renderers
  strong(token: Tokens.Strong) {
    return `<strong class="font-bold text-gray-200">${token.text}</strong>`;
  },

  em(token: Tokens.Em) {
    return `<em class="italic text-gray-200">${token.text}</em>`;
  },

  codespan(token: Tokens.Codespan) {
    return `<code class="bg-gray-700 px-1 rounded text-gray-200">${token.text}</code>`;
  },

  br() {
    return "<br>";
  },

  del(token: Tokens.Del) {
    return `<del class="line-through text-gray-200">${token.text}</del>`;
  },

  link(token: Tokens.Link) {
    const title = token.title ? ` title="${token.title}"` : "";

    // Process the text content for image markdown syntax
    const processedContent = processImageInLink(token.text);

    return `<a href="${token.href}"${title} class="inline-block hover:opacity-80 transition-opacity underline text-highlight" 
        target="_blank" rel="noopener noreferrer">${processedContent}</a>`;
  },

  image(token: Tokens.Image) {
    const title = token.title ? ` title="${token.title}"` : "";
    return `<img src="${token.href}" alt="${token.text}"${title} 
        class="max-w-full h-auto rounded-lg my-4" loading="lazy" />`;
  },

  text(token: Tokens.Text) {
    return token.text;
  },
};

// Use the custom renderer
marked.use({ renderer });

interface MarkdownRenderer {
  markdownUrl: string;
}

const MarkdownRenderer: React.FC<MarkdownRenderer> = ({ markdownUrl }) => {
  const [markdown, setMarkdown] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(
          "https://us-central1-brev-testing-1.cloudfunctions.net/cors-proxy",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: markdownUrl,
            }),
          }
        );

        const text = await response.text();
        setMarkdown(text);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMarkdown();
  }, [markdownUrl]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">Loading...</div>
    );
  }

  if (error) {
    return <div>Error loading markdown: {error}</div>;
  }

  return (
    <FlatCard>
      <div
        className="max-w-none"
        dangerouslySetInnerHTML={{ __html: marked(markdown) }}
      />
    </FlatCard>
  );
};

export default MarkdownRenderer;
